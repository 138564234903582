import "core-js/modules/es.function.name";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.string.iterator";
import "core-js/modules/web.dom-collections.iterator";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { required, type } from "@/commons/utils/Rules";
import { getDepartamentos, getEstadoCivil, getPaises, getSucursales } from "@/routes/api/resources";
import baseform2 from '../baseForm2';
import PzFormFlujo from "@/components/Flujo/Templates/PzFormFlujoTemplate";
import PzDirection from "@/commons/forms/Fields/PzDirection";
import PzIdentityDocument from "@/commons/forms/Fields/PzIdentityDocument";
export default {
  name: 'PzPersonFormExtBnf',
  components: {
    PzFormFlujo: PzFormFlujo,
    PzDirection: PzDirection,
    PzIdentityDocument: PzIdentityDocument
  },
  mixins: [baseform2],
  data: function data() {
    return {
      marital_status: [],
      sucursales: [],
      paises: [],
      genero: [{
        id: 'f',
        name: this.$t('forms_flujo.form1_persona.SP.femenino')
      }, {
        id: 'm',
        name: this.$t('forms_flujo.form1_persona.SP.masculino')
      }],
      provincias: [],
      formulario2: {
        direccion: {},
        provincia: '',
        telefono_fijo: '',
        email: '',
        ref_empresa: process.env.COMPANY_KEY,
        tipo_documento: process.env.IDE_DEFAULT,
        genero: '',
        estado_civil_id: '',
        sucursal_id: '',
        nombre_conyuge: '',
        cedula_conyuge: {
          document: 1
        }
      },
      disable: true,
      rules_formulario2: {
        tipo_vivienda: [required('')],
        direccion: [required('')],
        provincia: [required('')],
        telefono_fijo: [required('')],
        email: [required(''), type({
          field: '',
          type: 'email'
        })],
        genero: [required('')],
        estado_civil_id: [required('')],
        sucursal_id: [required('')]
      },
      pickerOptions1: {
        disabledDate: function disabledDate(time) {
          return time.getTime() > Date.now();
        }
      }
    };
  },
  created: function created() {},
  beforeMount: function beforeMount() {
    var _this = this;

    var estado = getEstadoCivil().then(function (res) {
      _this.marital_status = res.data;
    }).catch(function () {
      _this.marital_status = [];
    });
    var paises = getPaises().then(function (res) {
      _this.paises = res.data;
    }).catch(function () {
      _this.paises = [];
    });
    var dep = getDepartamentos().then(function (res) {
      _this.provincias = res.data;
    }).catch(function () {
      _this.provincias = [];
    });
    var getsucursales = getSucursales(this.$route.params.id_custom).then(function (res) {
      _this.sucursales = res.data;
    }).catch(function () {
      _this.sucursales = [];
    });
    Promise.all([estado, dep, paises, getsucursales]).then(function () {}).finally(function () {
      _this.$store.dispatch('loader/down', {
        trigger: _this.$options.name
      });
    });
  },
  methods: {}
};
import "core-js/modules/es.array.filter";
import "core-js/modules/es.array.index-of";
import "core-js/modules/es.array.map";
import "core-js/modules/es.function.name";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.object.values";
import "core-js/modules/es.regexp.to-string";
import "core-js/modules/es.string.iterator";
import "core-js/modules/web.dom-collections.iterator";
import _objectSpread from "C:/xampp/htdocs/simulador_new/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//import {required, type} from "@/commons/utils/Rules";
import { isEmpty } from "@/commons/utils/functions";
import { getEstudios, getFormasDeEnterarse, getFormasPago, getPais, getRelacionesLaborales, getTipoViviendas, validateContact } from "@/routes/api/resources";
import baseform2 from '../baseForm2';
import PzFormFlujo from "@/components/Flujo/Templates/PzFormFlujoTemplate";
import lowercase from "@/commons/filters/string/lowercase";
import { mapGetters } from "vuex";
import PzIdentityDocument from "@/commons/forms/Fields/PzIdentityDocument";
import { required, type } from "@/commons/utils/Rules";
export default {
  name: 'PzPersonFormExtEs',
  components: {
    PzIdentityDocument: PzIdentityDocument,
    PzFormFlujo: PzFormFlujo
  },
  mixins: [baseform2],
  data: function data() {
    var _this = this;

    var isNumberValidator = function isNumberValidator(rule, value, callback) {
      if (/\D/.test(value)) {
        callback(new Error('Solo se permiten caracteres numericos'));
      } else {
        callback();
      }
    };

    var check_email = function check_email(rule, value, callback) {
      if (_this.formulario2.email_contacto === _this.applicant.mail) {
        return callback(new Error(_this.$t('rules.email_equal')));
      } else {
        validateContact(_this.formulario2.email_contacto, null, _this.applicant.identity.number).then(function (res) {
          if (res.data.email_exists) {
            return callback(new Error(_this.$t('rules.email_exists')));
          } else {
            return callback();
          }
        }).catch(function () {
          return callback(new Error(_this.$t('rules.email_exists')));
        });
      }
    };

    var check_phone = function check_phone(rule, value, callback) {
      if (value === _this.applicant.telefono) {
        return callback(new Error(_this.$t('rules.phone_equal')));
      } else {
        _this.$store.dispatch('loader/up', {
          trigger: _this.$options.name
        });

        validateContact(null, _this.formulario2.telefono_persona_contacto, _this.applicant.identity.number).then(function (res) {
          if (res.data.phone_exists) {
            return callback(new Error(_this.$t('rules.phone_exists')));
          } else {
            return callback();
          }
        }).catch(function () {
          return callback(new Error(_this.$t('rules.phone_exists')));
        }).finally(function () {
          _this.$store.dispatch('loader/down', {
            trigger: _this.$options.name
          });
        });
      }
    };

    return {
      employment_relations: [],
      studies: [],
      suggest: [],
      income_source: [{
        id: 'Ama de casa',
        name: 'Ama de Casa'
      }, {
        id: 'Estudiante ',
        name: 'Estudiante'
      }, {
        id: 'Empleado de la ONCE ',
        name: 'Empleado de la ONCE'
      }, {
        id: 'Venta ambulante',
        name: 'Venta ambulante'
      }, {
        id: 'Empleado sector agrario',
        name: 'Empleado sector agrario'
      }, {
        id: 'Contrato fijo (público)',
        name: 'Contrato fijo (público)'
      }, {
        id: 'Contrato temporal (público)',
        name: 'Contrato temporal (público)'
      }, {
        id: 'Contrato fijo (privado)',
        name: 'Contrato fijo (privado)'
      }, {
        id: 'Contrato temporal (privado)',
        name: 'Contrato temporal (privado)'
      }, {
        id: 'Autónomo',
        name: 'Autónomo'
      }, {
        id: 'Prejubilado',
        name: 'Prejubilado'
      }, {
        id: 'Jubilado',
        name: 'Jubilado'
      }, {
        id: 'Pensionista',
        name: 'Pensionista'
      }, {
        id: 'Desempleado con prestación',
        name: 'Desempleado con prestación'
      }, {
        id: 'Desempleado sin prestación',
        name: 'Desempleado sin prestación'
      }],
      tiempo_viviendo: [{
        id: '0 - 1 Años',
        name: '0 - 1 Años'
      }, {
        id: '1 - 3 Años',
        name: '1 - 3 Años'
      }, {
        id: '3 - 5 Años',
        name: '3 - 5 Años'
      }, {
        id: '5 - 10 Años',
        name: '5 - 10 Años'
      }, {
        id: '10+ Años',
        name: '10+ Años'
      }],
      cahs_in_list: [],
      vnz: false,
      properties: [],
      formas_de_enterarse: [],
      formulario2: {
        tipo_vivienda: '',
        relacion_laboral_id: '',
        estudios: '',
        cash_in_id: '',
        comoseentero: '',
        persona_contacto: '',
        telefono_persona_contacto: '',
        identity: {
          document: 4
        },
        info_extra: {
          institucion_de_estudio: '',
          fuente_ingreso_principal: '',
          ultima_direccion_venezuela: '',
          ultimo_trabajo_venezuela: '',
          has_others_credits: false,
          accepted_before: false,
          referenciado_por: '',
          tiempo_viviendo_espana: ''
        },
        email_contacto: '',
        formato_documento: ''
      },
      disable: true,
      rules_formulario2: {
        tipo_vivienda: [required('')],
        relacion_laboral_id: [required('')],
        comoseentero: [required('')],
        persona_contacto: [required('')],
        telefono_persona_contacto: [required(''), {
          validator: check_phone,
          trigger: 'blur'
        }, {
          validator: isNumberValidator,
          trigger: 'blur'
        }],
        info_extra: {
          referenciado_por: [required('')]
        },
        email_contacto: [required(''), type({
          field: '',
          type: 'email'
        }), {
          validator: check_email,
          trigger: 'blur'
        }],
        formato_documento: [required('')]
      }
    };
  },
  computed: _objectSpread({}, mapGetters(['applicant'])),
  created: function created() {
    var _this2 = this;

    this.$store.dispatch('loader/up', {
      trigger: this.$options.name
    });
    this.suggest = Object.values(this.$t('vnz_universities')).map(function (value) {
      return {
        value: value
      };
    }); // this.income_source = Object.values(this.$t('income_source')).map((value) => {
    //     return {value: value}
    // })

    this.fechtData().finally(function () {
      _this2.$store.dispatch('loader/down', {
        trigger: _this2.$options.name
      });
    });
  },
  methods: {
    getIncomen: function getIncomen(queryString, cb) {
      var suggest = this.income_source;
      var self = this;
      var results = !isEmpty(queryString) && !isEmpty(suggest) ? suggest.filter(function (value) {
        return self._.deburr(value.value).toLowerCase().indexOf(self._.deburr(queryString).toLowerCase()) >= 0;
      }) : suggest;
      cb(results);
    },
    getSuggest: function getSuggest(queryString, cb) {
      var suggest = this.suggest;
      var self = this;
      var results = !isEmpty(queryString) && !isEmpty(suggest) ? suggest.filter(function (value) {
        return self._.deburr(value.value).toLowerCase().indexOf(self._.deburr(queryString).toLowerCase()) >= 0;
      }) : suggest;
      cb(results);
    },
    fechtData: function fechtData() {
      var _this3 = this;

      this.$store.dispatch('loader/up', {
        trigger: this.$options.name
      });
      var viv = getTipoViviendas().then(function (res) {
        _this3.properties = res.data;
      }).catch(function () {
        _this3.properties = [];
      });
      var est = getEstudios().then(function (res) {
        _this3.studies = res.data;
      }).catch(function () {
        _this3.studies = [];
      });
      var re = getRelacionesLaborales().then(function (res) {
        _this3.employment_relations = res.data;
      }).catch(function () {
        _this3.employment_relations = [];
      });
      var form = getFormasDeEnterarse().then(function (res) {
        _this3.formas_de_enterarse = res.data;
      }).catch(function () {
        _this3.formas_de_enterarse = [];
      });
      var cahs_in_list = getFormasPago().then(function (res) {
        _this3.cahs_in_list = res.data;
      }).catch(function () {
        _this3.cahs_in_list = [];
      });
      var country = getPais(this.applicant.pais_nacimiento).then(function (res) {
        if (lowercase(res.data.iso).toString() === 've'.toString()) {
          _this3.vnz = true;
          _this3.formulario2.identity['document'] = 4;
        }
      }).catch(function (error) {
        _this3.vnz = false;
        console.log(error);
      });
      return Promise.all([viv, re, form, est, country, cahs_in_list]).then(function () {}).finally(function () {
        _this3.$store.dispatch('loader/down', {
          trigger: _this3.$options.name
        });
      });
    }
  }
};
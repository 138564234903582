var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "pz-form-flujo",
    {
      attrs: {
        "available-captcha": _vm.extraData.withCaptcha,
        forward: _vm.handleSave,
        backwards: _vm.showBack ? _vm.handleBack : null
      },
      model: {
        value: _vm.formulario2["captcha"],
        callback: function($$v) {
          _vm.$set(_vm.formulario2, "captcha", $$v)
        },
        expression: "formulario2['captcha']"
      }
    },
    [
      _c("span", { attrs: { slot: "form_title" }, slot: "form_title" }, [
        _vm._v(" " + _vm._s(_vm.$t("forms_flujo.form2_persona.UY.title")) + " ")
      ]),
      _c(
        "el-form",
        {
          ref: _vm.reference,
          attrs: {
            slot: "formulario",
            "label-position": "top",
            model: _vm.formulario2,
            "status-icon": "",
            rules: _vm.rules_formulario2
          },
          slot: "formulario"
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                {
                  attrs: {
                    xl: { span: 12 },
                    lg: { span: 12 },
                    md: { span: 12 },
                    sm: { span: 24 },
                    xs: { span: 24 }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "info_extra.ingreso_tienda",
                        label: _vm.$t(
                          "forms_flujo.form2_persona.SP.ingreso_tienda"
                        ),
                        error: _vm.errors.get("ingreso_tienda")
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "text",
                          "auto-complete": "no",
                          placeholder: _vm.$t(
                            "forms_flujo.form2_persona.SP.ingreso_tienda"
                          )
                        },
                        model: {
                          value: _vm.formulario2.info_extra.ingreso_tienda,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.formulario2.info_extra,
                              "ingreso_tienda",
                              $$v
                            )
                          },
                          expression: "formulario2.info_extra.ingreso_tienda"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                {
                  attrs: {
                    xl: { span: 12 },
                    lg: { span: 12 },
                    md: { span: 12 },
                    sm: { span: 24 },
                    xs: { span: 24 }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "info_extra.gasto_tienda",
                        label: _vm.$t(
                          "forms_flujo.form2_persona.SP.gasto_tienda"
                        ),
                        error: _vm.errors.get("gasto_tienda")
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "text",
                          "auto-complete": "no",
                          placeholder: _vm.$t(
                            "forms_flujo.form2_persona.SP.gasto_tienda"
                          )
                        },
                        model: {
                          value: _vm.formulario2.info_extra.gasto_tienda,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.formulario2.info_extra,
                              "gasto_tienda",
                              $$v
                            )
                          },
                          expression: "formulario2.info_extra.gasto_tienda"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                {
                  attrs: {
                    xl: { span: 12 },
                    lg: { span: 12 },
                    md: { span: 12 },
                    sm: { span: 24 },
                    xs: { span: 24 }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "info_extra.tipo_tienda",
                        label: _vm.$t(
                          "forms_flujo.form2_persona.SP.tipo_tienda"
                        ),
                        error: _vm.errors.get("tipo_tienda")
                      }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          ref: "info_extra.tipo_tienda",
                          attrs: {
                            clearable: "",
                            placeholder: _vm.$t(
                              "forms_flujo.form2_persona.SP.tipo_tienda"
                            )
                          },
                          model: {
                            value: _vm.formulario2.info_extra.tipo_tienda,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.formulario2.info_extra,
                                "tipo_tienda",
                                $$v
                              )
                            },
                            expression: "formulario2.info_extra.tipo_tienda"
                          }
                        },
                        _vm._l(_vm.data_tipo_tienda, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: {
                              label: item.name,
                              value: item.name,
                              placeholder: _vm.$t(
                                "forms_flujo.form2_persona.SP.tipo_tienda"
                              )
                            }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                {
                  attrs: {
                    xl: { span: 12 },
                    lg: { span: 12 },
                    md: { span: 12 },
                    sm: { span: 24 },
                    xs: { span: 24 }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "tiempo_tienda",
                        label: _vm.$t(
                          "forms_flujo.form2_persona.SP.tiempo_tienda"
                        ),
                        error: _vm.errors.get("tiempo_tienda")
                      }
                    },
                    [
                      _c(
                        "el-input",
                        {
                          staticClass: "input-with-select",
                          attrs: {
                            type: "text",
                            "auto-complete": "no",
                            placeholder: _vm.$t(
                              "forms_flujo.form2_persona.SP.tiempo_tienda"
                            )
                          },
                          model: {
                            value: _vm.formulario2.nro_tiempo_tienda,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.formulario2,
                                "nro_tiempo_tienda",
                                $$v
                              )
                            },
                            expression: "formulario2.nro_tiempo_tienda"
                          }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              ref: "tiempo_tienda",
                              staticStyle: { width: "110px" },
                              attrs: { slot: "prepend" },
                              slot: "prepend",
                              model: {
                                value: _vm.formulario2.tiempo_tienda,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.formulario2,
                                    "tiempo_tienda",
                                    $$v
                                  )
                                },
                                expression: "formulario2.tiempo_tienda"
                              }
                            },
                            _vm._l(_vm.data_tiempo_tienda, function(item) {
                              return _c("el-option", {
                                key: item.name,
                                attrs: {
                                  label: item.name,
                                  value: item.name,
                                  placeholder: _vm.$t(
                                    "forms_flujo.form2_persona.SP.tiempo_tienda"
                                  )
                                }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                {
                  attrs: {
                    xl: { span: 12 },
                    lg: { span: 12 },
                    md: { span: 12 },
                    sm: { span: 24 },
                    xs: { span: 24 }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "info_extra.nombre_tienda",
                        label: _vm.$t(
                          "forms_flujo.form2_persona.SP.nombre_tienda"
                        ),
                        error: _vm.errors.get("nombre_tienda")
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "text",
                          "auto-complete": "no",
                          placeholder: _vm.$t(
                            "forms_flujo.form2_persona.SP.nombre_tienda"
                          )
                        },
                        model: {
                          value: _vm.formulario2.info_extra.nombre_tienda,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.formulario2.info_extra,
                              "nombre_tienda",
                              $$v
                            )
                          },
                          expression: "formulario2.info_extra.nombre_tienda"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("pz-direction", {
            attrs: { "name-direction": "" },
            model: {
              value: _vm.formulario2.direccion,
              callback: function($$v) {
                _vm.$set(_vm.formulario2, "direccion", $$v)
              },
              expression: "formulario2.direccion"
            }
          }),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                {
                  attrs: {
                    xl: { span: 12 },
                    lg: { span: 12 },
                    md: { span: 12 },
                    sm: { span: 24 },
                    xs: { span: 24 }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "info_extra.producto_nestle",
                        label: _vm.$t(
                          "forms_flujo.form2_persona.SP.producto_nestle"
                        ),
                        error: _vm.errors.get("producto_nestle")
                      }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          ref: "info_extra.producto_nestle",
                          attrs: {
                            clearable: "",
                            placeholder: _vm.$t(
                              "forms_flujo.form2_persona.SP.producto_nestle"
                            )
                          },
                          model: {
                            value: _vm.formulario2.info_extra.producto_nestle,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.formulario2.info_extra,
                                "producto_nestle",
                                $$v
                              )
                            },
                            expression: "formulario2.info_extra.producto_nestle"
                          }
                        },
                        _vm._l(_vm.data_producto, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: {
                              label: item.id,
                              value: item.id,
                              placeholder: _vm.$t(
                                "forms_flujo.form2_persona.SP.producto_nestle"
                              )
                            }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                {
                  attrs: {
                    xl: { span: 12 },
                    lg: { span: 12 },
                    md: { span: 12 },
                    sm: { span: 24 },
                    xs: { span: 24 }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "info_extra.patente",
                        label: _vm.$t("forms_flujo.form2_persona.SP.patente"),
                        error: _vm.errors.get("patente"),
                        hidden: _vm.isnestle
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "text",
                          "auto-complete": "no",
                          placeholder: _vm.$t(
                            "forms_flujo.form2_persona.SP.patente"
                          )
                        },
                        model: {
                          value: _vm.formulario2.info_extra.patente,
                          callback: function($$v) {
                            _vm.$set(_vm.formulario2.info_extra, "patente", $$v)
                          },
                          expression: "formulario2.info_extra.patente"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                {
                  attrs: {
                    xl: { span: 12 },
                    lg: { span: 12 },
                    md: { span: 12 },
                    sm: { span: 24 },
                    xs: { span: 24 }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "info_extra.energia_electrica",
                        label: _vm.$t(
                          "forms_flujo.form1_persona.UY.energia_electrica"
                        ),
                        error: _vm.errors.get("energia_electrica"),
                        hidden: _vm.isnestle
                      }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          ref: "info_extra.energia_electrica",
                          attrs: {
                            clearable: "",
                            placeholder: _vm.$t(
                              "forms_flujo.form1_persona.UY.energia_electrica"
                            )
                          },
                          model: {
                            value: _vm.formulario2.info_extra.energia_electrica,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.formulario2.info_extra,
                                "energia_electrica",
                                $$v
                              )
                            },
                            expression:
                              "formulario2.info_extra.energia_electrica"
                          }
                        },
                        _vm._l(_vm.data_energia_electrica, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: {
                              label: item.name,
                              value: item.id,
                              placeholder: _vm.$t(
                                "forms_flujo.form1_persona.UY.energia_electrica"
                              )
                            }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                {
                  attrs: {
                    xl: { span: 12 },
                    lg: { span: 12 },
                    md: { span: 12 },
                    sm: { span: 24 },
                    xs: { span: 24 }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "info_extra.correlativo",
                        label: _vm.$t(
                          "forms_flujo.form2_persona.SP.correlativo"
                        ),
                        error: _vm.errors.get("correlativo"),
                        hidden: _vm.isnestle
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "text",
                          "auto-complete": "no",
                          placeholder: _vm.$t(
                            "forms_flujo.form2_persona.SP.correlativo"
                          )
                        },
                        model: {
                          value: _vm.formulario2.info_extra.correlativo,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.formulario2.info_extra,
                              "correlativo",
                              $$v
                            )
                          },
                          expression: "formulario2.info_extra.correlativo"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                {
                  attrs: {
                    xl: { span: 12 },
                    lg: { span: 12 },
                    md: { span: 12 },
                    sm: { span: 24 },
                    xs: { span: 24 }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "info_extra.contador",
                        label: _vm.$t("forms_flujo.form2_persona.SP.contador"),
                        error: _vm.errors.get("contador"),
                        hidden: _vm.isnestle
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "text",
                          "auto-complete": "no",
                          placeholder: _vm.$t(
                            "forms_flujo.form2_persona.SP.contador"
                          )
                        },
                        model: {
                          value: _vm.formulario2.info_extra.contador,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.formulario2.info_extra,
                              "contador",
                              $$v
                            )
                          },
                          expression: "formulario2.info_extra.contador"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
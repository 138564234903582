var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "pz-form-flujo",
    {
      attrs: {
        "available-captcha": _vm.extraData.withCaptcha,
        forward: _vm.handleSave,
        backwards: _vm.showBack ? _vm.handleBack : null
      },
      model: {
        value: _vm.formulario2["captcha"],
        callback: function($$v) {
          _vm.$set(_vm.formulario2, "captcha", $$v)
        },
        expression: "formulario2['captcha']"
      }
    },
    [
      _c("span", { attrs: { slot: "form_title" }, slot: "form_title" }, [
        _vm._v(" " + _vm._s(_vm.$t("forms_flujo.form2_persona.UY.title")) + " ")
      ]),
      _c(
        "el-form",
        {
          ref: _vm.reference,
          attrs: {
            slot: "formulario",
            "label-position": "top",
            model: _vm.formulario2,
            "status-icon": "",
            rules: _vm.rules_formulario2
          },
          slot: "formulario"
        },
        [
          _c("label", [
            _vm._v(
              _vm._s(_vm.$t("forms_flujo.form2_persona.BNF.datos_laborales"))
            )
          ]),
          _c("hr", {
            staticClass: "pz-dotted",
            staticStyle: { "margin-top": "1px" }
          }),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                {
                  attrs: {
                    xl: { span: 12 },
                    lg: { span: 12 },
                    md: { span: 12 },
                    sm: { span: 24 },
                    xs: { span: 24 }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "info_extra.empresa_trabajo",
                        label: _vm.$t(
                          "forms_flujo.form2_persona.BNF.empresa_trabajo"
                        ),
                        error: _vm.errors.get("info_extra.empresa_trabajo")
                      }
                    },
                    [
                      _c("el-input", {
                        ref: "info_extra.empresa_trabajo",
                        attrs: {
                          type: "text",
                          placeholder: _vm.$t(
                            "forms_flujo.form2_persona.BNF.empresa_trabajo"
                          )
                        },
                        model: {
                          value: _vm.formulario2.info_extra.empresa_trabajo,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.formulario2.info_extra,
                              "empresa_trabajo",
                              $$v
                            )
                          },
                          expression: "formulario2.info_extra.empresa_trabajo"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                {
                  attrs: {
                    xl: { span: 12 },
                    lg: { span: 12 },
                    md: { span: 12 },
                    sm: { span: 24 },
                    xs: { span: 24 }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "info_extra.cargo_ocupa",
                        label: _vm.$t(
                          "forms_flujo.form2_persona.BNF.cargo_ocupa"
                        ),
                        error: _vm.errors.get("info_extra.cargo_ocupa")
                      }
                    },
                    [
                      _c("el-input", {
                        ref: "info_extra.cargo_ocupa",
                        attrs: {
                          type: "text",
                          placeholder: _vm.$t(
                            "forms_flujo.form2_persona.BNF.cargo_ocupa"
                          )
                        },
                        model: {
                          value: _vm.formulario2.info_extra.cargo_ocupa,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.formulario2.info_extra,
                              "cargo_ocupa",
                              $$v
                            )
                          },
                          expression: "formulario2.info_extra.cargo_ocupa"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                {
                  attrs: {
                    xl: { span: 12 },
                    lg: { span: 12 },
                    md: { span: 12 },
                    sm: { span: 24 },
                    xs: { span: 24 }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "info_extra.profesion",
                        label: _vm.$t(
                          "forms_flujo.form2_persona.BNF.profesion"
                        ),
                        error: _vm.errors.get("info_extra.profesion")
                      }
                    },
                    [
                      _c("el-input", {
                        ref: "info_extra.profesion",
                        attrs: {
                          type: "text",
                          placeholder: _vm.$t(
                            "forms_flujo.form2_persona.BNF.profesion"
                          )
                        },
                        model: {
                          value: _vm.formulario2.info_extra.profesion,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.formulario2.info_extra,
                              "profesion",
                              $$v
                            )
                          },
                          expression: "formulario2.info_extra.profesion"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                {
                  attrs: {
                    xl: { span: 12 },
                    lg: { span: 12 },
                    md: { span: 12 },
                    sm: { span: 24 },
                    xs: { span: 24 }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "info_extra.direccion_trabajo",
                        label: _vm.$t(
                          "forms_flujo.form2_persona.BNF.direccion"
                        ),
                        error: _vm.errors.get("info_extra.direccion_trabajo")
                      }
                    },
                    [
                      _c("el-input", {
                        ref: "info_extra.direccion_trabajo",
                        attrs: {
                          type: "text",
                          placeholder: _vm.$t(
                            "forms_flujo.form2_persona.BNF.direccion"
                          )
                        },
                        model: {
                          value: _vm.formulario2.info_extra.direccion_trabajo,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.formulario2.info_extra,
                              "direccion_trabajo",
                              $$v
                            )
                          },
                          expression: "formulario2.info_extra.direccion_trabajo"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("label", [
            _vm._v(_vm._s(_vm.$t("forms_flujo.form2_persona.BNF.ingresos")))
          ]),
          _c("hr", {
            staticClass: "pz-dotted",
            staticStyle: { "margin-top": "1px" }
          }),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                {
                  attrs: {
                    xl: { span: 12 },
                    lg: { span: 12 },
                    md: { span: 12 },
                    sm: { span: 24 },
                    xs: { span: 24 }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "ingreso_mensual",
                        label: _vm.$t("forms_flujo.form2_persona.BNF.sueldo"),
                        error: _vm.errors.get("ingreso_mensual")
                      }
                    },
                    [
                      _c("money", {
                        directives: [
                          {
                            name: "money",
                            rawName: "v-money",
                            value: _vm.money,
                            expression: "money"
                          }
                        ],
                        ref: "ingreso_mensual",
                        staticClass: "el-input__inner",
                        attrs: {
                          placeholder: _vm.$t(
                            "forms_flujo.form2_persona.BNF.sueldo"
                          )
                        },
                        model: {
                          value: _vm.formulario2.ingreso_mensual,
                          callback: function($$v) {
                            _vm.$set(_vm.formulario2, "ingreso_mensual", $$v)
                          },
                          expression: "formulario2.ingreso_mensual"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                {
                  attrs: {
                    xl: { span: 12 },
                    lg: { span: 12 },
                    md: { span: 12 },
                    sm: { span: 24 },
                    xs: { span: 24 }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "info_extra.honorarios",
                        label: _vm.$t(
                          "forms_flujo.form2_persona.BNF.honorarios"
                        ),
                        error: _vm.errors.get("info_extra.honorarios")
                      }
                    },
                    [
                      _c("money", {
                        directives: [
                          {
                            name: "money",
                            rawName: "v-money",
                            value: _vm.money,
                            expression: "money"
                          }
                        ],
                        ref: "info_extra.honorarios",
                        staticClass: "el-input__inner",
                        attrs: {
                          placeholder: _vm.$t(
                            "forms_flujo.form2_persona.BNF.honorarios"
                          )
                        },
                        model: {
                          value: _vm.formulario2.info_extra.honorarios,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.formulario2.info_extra,
                              "honorarios",
                              $$v
                            )
                          },
                          expression: "formulario2.info_extra.honorarios"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                {
                  attrs: {
                    xl: { span: 12 },
                    lg: { span: 12 },
                    md: { span: 12 },
                    sm: { span: 24 },
                    xs: { span: 24 }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "ingresos_conyuge",
                        label: _vm.$t(
                          "forms_flujo.form2_persona.BNF.ingreso_conyugue"
                        ),
                        error: _vm.errors.get("ingresos_conyuge")
                      }
                    },
                    [
                      _c("money", {
                        directives: [
                          {
                            name: "money",
                            rawName: "v-money",
                            value: _vm.money,
                            expression: "money"
                          }
                        ],
                        staticClass: "el-input__inner",
                        attrs: {
                          placeholder: _vm.$t(
                            "forms_flujo.form2_persona.BNF.ingreso_conyugue"
                          )
                        },
                        model: {
                          value: _vm.formulario2.ingresos_conyuge,
                          callback: function($$v) {
                            _vm.$set(_vm.formulario2, "ingresos_conyuge", $$v)
                          },
                          expression: "formulario2.ingresos_conyuge"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                {
                  attrs: {
                    xl: { span: 12 },
                    lg: { span: 12 },
                    md: { span: 12 },
                    sm: { span: 24 },
                    xs: { span: 24 }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "info_extra.ingresos_otros",
                        label: _vm.$t(
                          "forms_flujo.form2_persona.BNF.ingresos_otros"
                        ),
                        error: _vm.errors.get("info_extra.ingresos_otros")
                      }
                    },
                    [
                      _c("money", {
                        directives: [
                          {
                            name: "money",
                            rawName: "v-money",
                            value: _vm.money,
                            expression: "money"
                          }
                        ],
                        ref: "info_extra.ingresos_otros",
                        staticClass: "el-input__inner",
                        attrs: {
                          placeholder: _vm.$t(
                            "forms_flujo.form2_persona.BNF.ingresos_otros"
                          )
                        },
                        model: {
                          value: _vm.formulario2.info_extra.ingresos_otros,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.formulario2.info_extra,
                              "ingresos_otros",
                              $$v
                            )
                          },
                          expression: "formulario2.info_extra.ingresos_otros"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("label", [
            _vm._v(_vm._s(_vm.$t("forms_flujo.form2_persona.BNF.gastos")))
          ]),
          _c("hr", {
            staticClass: "pz-dotted",
            staticStyle: { "margin-top": "1px" }
          }),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                {
                  attrs: {
                    xl: { span: 12 },
                    lg: { span: 12 },
                    md: { span: 12 },
                    sm: { span: 24 },
                    xs: { span: 24 }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "info_extra.gastos_familiares",
                        label: _vm.$t(
                          "forms_flujo.form2_persona.BNF.gastos_familiares"
                        ),
                        error: _vm.errors.get("info_extra.gastos_familiares")
                      }
                    },
                    [
                      _c("money", {
                        directives: [
                          {
                            name: "money",
                            rawName: "v-money",
                            value: _vm.money,
                            expression: "money"
                          }
                        ],
                        ref: "info_extra.gastos_familiares",
                        staticClass: "el-input__inner",
                        attrs: {
                          placeholder: _vm.$t(
                            "forms_flujo.form2_persona.BNF.gastos_familiares"
                          )
                        },
                        model: {
                          value: _vm.formulario2.info_extra.gastos_familiares,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.formulario2.info_extra,
                              "gastos_familiares",
                              $$v
                            )
                          },
                          expression: "formulario2.info_extra.gastos_familiares"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                {
                  attrs: {
                    xl: { span: 12 },
                    lg: { span: 12 },
                    md: { span: 12 },
                    sm: { span: 24 },
                    xs: { span: 24 }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "info_extra.gastos_tarjeta_credito",
                        label: _vm.$t(
                          "forms_flujo.form2_persona.BNF.gastos_tarjeta_credito"
                        ),
                        error: _vm.errors.get(
                          "info_extra.gastos_tarjeta_credito"
                        )
                      }
                    },
                    [
                      _c("money", {
                        directives: [
                          {
                            name: "money",
                            rawName: "v-money",
                            value: _vm.money,
                            expression: "money"
                          }
                        ],
                        ref: "info_extra.gastos_tarjeta_credito",
                        staticClass: "el-input__inner",
                        attrs: {
                          placeholder: _vm.$t(
                            "forms_flujo.form2_persona.BNF.gastos_tarjeta_credito"
                          )
                        },
                        model: {
                          value:
                            _vm.formulario2.info_extra.gastos_tarjeta_credito,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.formulario2.info_extra,
                              "gastos_tarjeta_credito",
                              $$v
                            )
                          },
                          expression:
                            "formulario2.info_extra.gastos_tarjeta_credito"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                {
                  attrs: {
                    xl: { span: 12 },
                    lg: { span: 12 },
                    md: { span: 12 },
                    sm: { span: 24 },
                    xs: { span: 24 }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "info_extra.gastos_cuota_prestamo",
                        label: _vm.$t(
                          "forms_flujo.form2_persona.BNF.gastos_cuota_prestamo"
                        ),
                        error: _vm.errors.get(
                          "info_extra.gastos_cuota_prestamo"
                        )
                      }
                    },
                    [
                      _c("money", {
                        directives: [
                          {
                            name: "money",
                            rawName: "v-money",
                            value: _vm.money,
                            expression: "money"
                          }
                        ],
                        staticClass: "el-input__inner",
                        model: {
                          value:
                            _vm.formulario2.info_extra.gastos_cuota_prestamo,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.formulario2.info_extra,
                              "gastos_cuota_prestamo",
                              $$v
                            )
                          },
                          expression:
                            "formulario2.info_extra.gastos_cuota_prestamo"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                {
                  attrs: {
                    xl: { span: 12 },
                    lg: { span: 12 },
                    md: { span: 12 },
                    sm: { span: 24 },
                    xs: { span: 24 }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "info_extra.gastos_otros",
                        label: _vm.$t(
                          "forms_flujo.form2_persona.BNF.gastos_otros"
                        ),
                        error: _vm.errors.get("info_extra.gastos_otros")
                      }
                    },
                    [
                      _c("money", {
                        directives: [
                          {
                            name: "money",
                            rawName: "v-money",
                            value: _vm.money,
                            expression: "money"
                          }
                        ],
                        staticClass: "el-input__inner",
                        model: {
                          value: _vm.formulario2.info_extra.gastos_otros,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.formulario2.info_extra,
                              "gastos_otros",
                              $$v
                            )
                          },
                          expression: "formulario2.info_extra.gastos_otros"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import _classCallCheck from "C:/xampp/htdocs/simulador_new/node_modules/@babel/runtime/helpers/esm/classCallCheck";
import _defineProperty from "C:/xampp/htdocs/simulador_new/node_modules/@babel/runtime/helpers/esm/defineProperty";

var ResponseEvent = function ResponseEvent(event, data) {
  _classCallCheck(this, ResponseEvent);

  _defineProperty(this, "event", void 0);

  _defineProperty(this, "data", void 0);

  this.event = event;
  this.data = data;
};

export function Objeto(event, data) {
  return new ResponseEvent(event, data);
}
import "core-js/modules/es.object.to-string";
export function lazyLoad(view) {
  return function () {
    return import("@/views/pagos/".concat(view, ".vue"));
  };
}
export default [{
  path: "finalizado/:loan",
  component: lazyLoad('FinalPago'),
  name: "finalPago",
  hidden: true,
  props: true,
  meta: {
    title: 'views.pagos.banner',
    hidden: true
  }
}, {
  path: "prestamo/:loan",
  component: lazyLoad('Pago'),
  name: "ConfirmarPago",
  hidden: true,
  props: true,
  meta: {
    title: 'views.pagos.banner',
    hidden: true
  }
}];
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "pz-form-flujo",
    {
      attrs: {
        "available-captcha": _vm.extraData.withCaptcha,
        forward: _vm.handleSave,
        backwards: _vm.showBack ? _vm.handleBack : null
      },
      model: {
        value: _vm.formulario2["captcha"],
        callback: function($$v) {
          _vm.$set(_vm.formulario2, "captcha", $$v)
        },
        expression: "formulario2['captcha']"
      }
    },
    [
      _c("span", { attrs: { slot: "form_title" }, slot: "form_title" }, [
        _vm._v(" " + _vm._s(_vm.$t("forms_flujo.form2_persona.UY.title")) + " ")
      ]),
      _c(
        "el-form",
        {
          ref: _vm.reference,
          attrs: {
            slot: "formulario",
            "label-position": "top",
            model: _vm.formulario2,
            "status-icon": "",
            rules: _vm.rules_formulario2
          },
          slot: "formulario"
        },
        [
          _c("pz-direction", {
            attrs: { viewline: "" },
            model: {
              value: _vm.formulario2.direccion,
              callback: function($$v) {
                _vm.$set(_vm.formulario2, "direccion", $$v)
              },
              expression: "formulario2.direccion"
            }
          }),
          _c("label", [
            _vm._v(
              _vm._s(_vm.$t("forms_flujo.form2_persona.BNF.datos_particulares"))
            )
          ]),
          _c("hr", {
            staticClass: "pz-dotted",
            staticStyle: { "margin-top": "1px" }
          }),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                {
                  attrs: {
                    xl: { span: 12 },
                    lg: { span: 12 },
                    md: { span: 12 },
                    sm: { span: 24 },
                    xs: { span: 24 }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "tipo_vivienda",
                        label: _vm.$t(
                          "forms_flujo.form2_persona.UY.property_type"
                        ),
                        error: _vm.errors.get("tipo_vivienda")
                      }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            clearable: "",
                            placeholder: _vm.$t(
                              "forms_flujo.form2_persona.UY.property_type"
                            )
                          },
                          model: {
                            value: _vm.formulario2.tipo_vivienda,
                            callback: function($$v) {
                              _vm.$set(_vm.formulario2, "tipo_vivienda", $$v)
                            },
                            expression: "formulario2.tipo_vivienda"
                          }
                        },
                        _vm._l(_vm.properties, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: {
                              label: item.tipo,
                              value: item.id,
                              placeholder: _vm.$t(
                                "forms_flujo.form2_persona.UY.property_type"
                              )
                            }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                {
                  attrs: {
                    xl: { span: 12 },
                    lg: { span: 12 },
                    md: { span: 12 },
                    sm: { span: 24 },
                    xs: { span: 24 }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "email",
                        label: _vm.$t("forms_flujo.form2_persona.UY.email"),
                        error: _vm.errors.get("email")
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "text",
                          "auto-complete": "no",
                          placeholder: _vm.$t(
                            "forms_flujo.form2_persona.UY.email"
                          )
                        },
                        model: {
                          value: _vm.formulario2.email,
                          callback: function($$v) {
                            _vm.$set(_vm.formulario2, "email", $$v)
                          },
                          expression: "formulario2.email"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                {
                  attrs: {
                    xl: { span: 12 },
                    lg: { span: 12 },
                    md: { span: 12 },
                    sm: { span: 24 },
                    xs: { span: 24 }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "comoseentero",
                        label: _vm.$t(
                          "forms_flujo.form2_persona.UY.how_find_out"
                        ),
                        error: _vm.errors.get("comoseentero")
                      }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            clearable: "",
                            placeholder: _vm.$t(
                              "forms_flujo.form2_persona.UY.how_find_out"
                            )
                          },
                          model: {
                            value: _vm.formulario2.comoseentero,
                            callback: function($$v) {
                              _vm.$set(_vm.formulario2, "comoseentero", $$v)
                            },
                            expression: "formulario2.comoseentero"
                          }
                        },
                        _vm._l(_vm.formas_de_enterarse, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.forma, value: item.id }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import _objectSpread from "C:/xampp/htdocs/simulador_new/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapGetters } from "vuex";
export default {
  name: "PzTitle",
  computed: _objectSpread({}, mapGetters(['header']))
};
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "pz-form-flujo",
    {
      attrs: {
        "available-captcha": _vm.extraData.withCaptcha,
        forward: _vm.handleSave,
        backwards: _vm.showBack ? _vm.handleBack : null
      },
      model: {
        value: _vm.formulario_validarTelefono["captcha"],
        callback: function($$v) {
          _vm.$set(_vm.formulario_validarTelefono, "captcha", $$v)
        },
        expression: "formulario_validarTelefono['captcha']"
      }
    },
    [
      _c("span", { attrs: { slot: "form_title" }, slot: "form_title" }, [
        _vm._v(
          " " + _vm._s(_vm.$t("forms_flujo.validacion_sms_default.title")) + " "
        )
      ]),
      _c("span", { attrs: { slot: "form_subtitle" }, slot: "form_subtitle" }, [
        _vm._v(
          " " +
            _vm._s(_vm.$t("forms_flujo.validacion_sms_default.sub_title")) +
            " "
        )
      ]),
      _c(
        "el-form",
        {
          ref: _vm.reference,
          attrs: {
            slot: "formulario",
            model: _vm.formulario_validarTelefono,
            "status-icon": "",
            rules: _vm.rules_formulario_validarTelefono
          },
          slot: "formulario"
        },
        [
          _c(
            "el-col",
            {
              attrs: {
                xl: { span: 6, offset: 9 },
                lg: { span: 6, offset: 9 },
                md: { span: 6, offset: 9 },
                sm: { span: 24 },
                xs: { span: 24 }
              }
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "codigo_verificacion",
                    label: _vm.$t(
                      "forms_flujo.validacion_sms_default.verification_code"
                    ),
                    error: _vm.errors.get("codigo_verificacion")
                  }
                },
                [
                  _c("el-input", {
                    attrs: {
                      type: "text",
                      placeholder: _vm.$t(
                        "forms_flujo.validacion_sms_default.verification_code"
                      )
                    },
                    model: {
                      value: _vm.formulario_validarTelefono.codigo_verificacion,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.formulario_validarTelefono,
                          "codigo_verificacion",
                          $$v
                        )
                      },
                      expression:
                        "formulario_validarTelefono.codigo_verificacion"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-col",
        {
          staticClass: "center-block text-center",
          attrs: { slot: "buttons", span: 24 },
          slot: "buttons"
        },
        [
          _vm.showBack
            ? _c("pz-back-button", { attrs: { back: _vm.handleBack } })
            : _vm._e(),
          _c("pz-button", {
            attrs: {
              "button-name": _vm.$t(
                "forms_flujo.validacion_sms_default.resend_btn"
              )
            },
            on: { click: _vm.handleResend }
          }),
          _vm.extraData.withCaptcha
            ? _c("pz-captcha-button", {
                attrs: { forward: _vm.handleSave },
                model: {
                  value: _vm.formulario_validarTelefono["captcha"],
                  callback: function($$v) {
                    _vm.$set(_vm.formulario_validarTelefono, "captcha", $$v)
                  },
                  expression: "formulario_validarTelefono['captcha']"
                }
              })
            : _c("pz-forward-button", {
                attrs: { forward: _vm.handleSave },
                model: {
                  value: _vm.formulario_validarTelefono["captcha"],
                  callback: function($$v) {
                    _vm.$set(_vm.formulario_validarTelefono, "captcha", $$v)
                  },
                  expression: "formulario_validarTelefono['captcha']"
                }
              })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
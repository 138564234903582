var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-col",
    { staticClass: "text-center pz-large-margin", attrs: { span: 24 } },
    [
      _c("div", { staticClass: "pz-title" }, [_vm._t("title")], 2),
      _c("div", { staticClass: "pz-subtitle" }, [_vm._t("sub_title")], 2)
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "pz-form-flujo",
    { attrs: { forward: _vm.handleSend } },
    [
      _c("span", { attrs: { slot: "form_title" }, slot: "form_title" }, [
        _vm._v(" " + _vm._s(_vm.$t("commons.youSing.title")) + " ")
      ]),
      _c("span", { attrs: { slot: "form_subtitle" }, slot: "form_subtitle" }, [
        _vm._v(" " + _vm._s(_vm.$t("commons.youSing.sub_title")) + " ")
      ]),
      _c(
        "el-form",
        {
          ref: _vm.reference,
          attrs: {
            slot: "formulario",
            model: _vm.formulario,
            "status-icon": "",
            rules: _vm.rules_formulario_validarTelefono
          },
          on: { validate: _vm.handleValidate },
          slot: "formulario"
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20, type: "flex", justify: "center" } },
            [
              _c(
                "el-col",
                {
                  attrs: {
                    xl: { span: 12 },
                    lg: { span: 12 },
                    md: { span: 12 },
                    sm: { span: 24 },
                    xs: { span: 24 }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "identity",
                        error: _vm.errors.get("numero_documento")
                      }
                    },
                    [
                      _c("pz-identity-document", {
                        attrs: { prop: "identity" },
                        model: {
                          value: _vm.formulario.identity,
                          callback: function($$v) {
                            _vm.$set(_vm.formulario, "identity", $$v)
                          },
                          expression: "formulario.identity"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 20, type: "flex", justify: "center" } },
            [
              _c("el-col", {
                attrs: {
                  xl: { span: 12 },
                  identity: "",
                  lg: { span: 12 },
                  md: { span: 12 },
                  sm: { span: 24 },
                  xs: { span: 24 }
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-col",
        {
          staticClass: "center-block text-center",
          attrs: { slot: "buttons", span: 24 },
          slot: "buttons"
        },
        [
          _c("pz-captcha-button", {
            attrs: { forward: _vm.handleSend },
            model: {
              value: _vm.formulario["captcha"],
              callback: function($$v) {
                _vm.$set(_vm.formulario, "captcha", $$v)
              },
              expression: "formulario['captcha']"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-col",
        { staticStyle: { "text-align": "center" } },
        [
          _c(
            "el-form-item",
            { attrs: { prop: _vm.prop, rules: _vm.rules, error: _vm.errors } },
            [
              _c(
                "el-checkbox",
                {
                  attrs: { disabled: _vm.unnax_checkbox_disabled },
                  on: {
                    change: function($event) {
                      return _vm.setUnnaxDialogVisible(true)
                    }
                  },
                  model: {
                    value: _vm.unnax.open_bank_integration,
                    callback: function($$v) {
                      _vm.$set(_vm.unnax, "open_bank_integration", $$v)
                    },
                    expression: "unnax.open_bank_integration"
                  }
                },
                [
                  _c("div", { staticStyle: { "white-space": "initial" } }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.label
                            ? _vm.label
                            : _vm.$t("commons.open_banking.label")
                        ) +
                        " "
                    )
                  ])
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c("el-col", { staticClass: "pz-large-margin" }, [
        _c("div", [
          _vm._v(
            " " + _vm._s(_vm.$t("commons.open_banking.label_adicional")) + " "
          )
        ])
      ]),
      _c(
        "el-col",
        { attrs: { span: 24 } },
        [
          _c(
            "el-dialog",
            {
              staticClass: "pz-dialog-banking",
              attrs: {
                title: _vm._f("capitalize")(
                  _vm.$t("commons.bank_aggregation.title_header"),
                  { onlyFirstLetter: true }
                ),
                visible: _vm.show_unnax_dialog,
                width: "50%",
                center: ""
              },
              on: {
                "update:visible": function($event) {
                  _vm.show_unnax_dialog = $event
                }
              }
            },
            [
              _c(
                "el-row",
                { staticClass: "row justify-content-center" },
                [
                  _vm.unnax.widget_url
                    ? _c("pz-unnax-iframe", {
                        attrs: { url: _vm.unnax.widget_url }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "pz-form-flujo",
    {
      attrs: {
        "available-captcha": _vm.extraData.withCaptcha,
        forward: _vm.handleSave,
        backwards: _vm.showBack ? _vm.handleBack : null
      },
      model: {
        value: _vm.formulario2["captcha"],
        callback: function($$v) {
          _vm.$set(_vm.formulario2, "captcha", $$v)
        },
        expression: "formulario2['captcha']"
      }
    },
    [
      _c("span", { attrs: { slot: "form_title" }, slot: "form_title" }, [
        _vm._v(" " + _vm._s(_vm.$t("forms_flujo.form2_persona.UY.title")) + " ")
      ]),
      _c(
        "el-form",
        {
          ref: _vm.reference,
          attrs: {
            slot: "formulario",
            "label-position": "top",
            model: _vm.formulario2,
            "status-icon": "",
            rules: _vm.rules_formulario2
          },
          slot: "formulario"
        },
        [
          _c("pz-direction", {
            attrs: { "ask-postal-code": "" },
            model: {
              value: _vm.formulario2.direccion,
              callback: function($$v) {
                _vm.$set(_vm.formulario2, "direccion", $$v)
              },
              expression: "formulario2.direccion"
            }
          }),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                {
                  attrs: {
                    xl: { span: 12 },
                    lg: { span: 12 },
                    md: { span: 12 },
                    sm: { span: 24 },
                    xs: { span: 24 }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "tipo_vivienda",
                        label: _vm.$t(
                          "forms_flujo.form2_persona.UY.property_type"
                        ),
                        error: _vm.errors.get("tipo_vivienda")
                      }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            clearable: "",
                            placeholder: _vm.$t(
                              "forms_flujo.form2_persona.UY.property_type"
                            )
                          },
                          model: {
                            value: _vm.formulario2.tipo_vivienda,
                            callback: function($$v) {
                              _vm.$set(_vm.formulario2, "tipo_vivienda", $$v)
                            },
                            expression: "formulario2.tipo_vivienda"
                          }
                        },
                        _vm._l(_vm.properties, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: {
                              label: item.tipo,
                              value: item.id,
                              placeholder: _vm.$t(
                                "forms_flujo.form2_persona.UY.property_type"
                              )
                            }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                {
                  attrs: {
                    xl: { span: 12 },
                    lg: { span: 12 },
                    md: { span: 12 },
                    sm: { span: 24 },
                    xs: { span: 24 }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "email",
                        label: _vm.$t("forms_flujo.form2_persona.UY.email"),
                        error: _vm.errors.get("email")
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "text",
                          "auto-complete": "no",
                          placeholder: _vm.$t(
                            "forms_flujo.form2_persona.UY.email"
                          )
                        },
                        model: {
                          value: _vm.formulario2.email,
                          callback: function($$v) {
                            _vm.$set(_vm.formulario2, "email", $$v)
                          },
                          expression: "formulario2.email"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                {
                  attrs: {
                    xl: { span: 12 },
                    lg: { span: 12 },
                    md: { span: 12 },
                    sm: { span: 24 },
                    xs: { span: 24 }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "telefono_fijo",
                        label: _vm.$t("forms_flujo.form2_persona.UY.phone"),
                        error: _vm.errors.get("telefono_fijo")
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "text",
                          "auto-complete": "no",
                          placeholder: _vm.$t(
                            "forms_flujo.form2_persona.UY.phone"
                          )
                        },
                        model: {
                          value: _vm.formulario2.telefono_fijo,
                          callback: function($$v) {
                            _vm.$set(_vm.formulario2, "telefono_fijo", $$v)
                          },
                          expression: "formulario2.telefono_fijo"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                {
                  attrs: {
                    xl: { span: 12 },
                    lg: { span: 12 },
                    md: { span: 12 },
                    sm: { span: 24 },
                    xs: { span: 24 }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "estudio",
                        label: _vm.$t("forms_flujo.form2_persona.UY.studies"),
                        error: _vm.errors.get("estudio")
                      }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            clearable: "",
                            placeholder: _vm.$t(
                              "forms_flujo.form2_persona.UY.studies"
                            )
                          },
                          model: {
                            value: _vm.formulario2.estudio,
                            callback: function($$v) {
                              _vm.$set(_vm.formulario2, "estudio", $$v)
                            },
                            expression: "formulario2.estudio"
                          }
                        },
                        _vm._l(_vm.studies, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.estudio, value: item.id }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                {
                  attrs: {
                    xl: { span: 12 },
                    lg: { span: 12 },
                    md: { span: 12 },
                    sm: { span: 24 },
                    xs: { span: 24 }
                  }
                },
                [
                  !(_vm.jubilado == 1)
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "relacion_laboral",
                            label: _vm.$t(
                              "forms_flujo.form2_persona.UY.employee_type"
                            ),
                            error: _vm.errors.get("relacion_laboral")
                          }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                clearable: "",
                                placeholder: _vm.$t(
                                  "forms_flujo.form2_persona.UY.employee_type"
                                )
                              },
                              model: {
                                value: _vm.formulario2.relacion_laboral,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.formulario2,
                                    "relacion_laboral",
                                    $$v
                                  )
                                },
                                expression: "formulario2.relacion_laboral"
                              }
                            },
                            _vm._l(_vm.employment_relations, function(item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.relacion, value: item.id }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _c(
                "el-col",
                {
                  attrs: {
                    xl: { span: 12 },
                    lg: { span: 12 },
                    md: { span: 12 },
                    sm: { span: 24 },
                    xs: { span: 24 }
                  }
                },
                [
                  !(_vm.jubilado == 1)
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "telefono_empresa",
                            label: _vm.$t(
                              "forms_flujo.form2_persona.UY.company_phone"
                            ),
                            error: _vm.errors.get("telefono_empresa")
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "text",
                              "auto-complete": "no",
                              placeholder: _vm.$t(
                                "forms_flujo.form2_persona.UY.company_phone"
                              )
                            },
                            model: {
                              value: _vm.formulario2.telefono_empresa,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.formulario2,
                                  "telefono_empresa",
                                  $$v
                                )
                              },
                              expression: "formulario2.telefono_empresa"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                {
                  attrs: {
                    xl: { span: 12 },
                    lg: { span: 12 },
                    md: { span: 12 },
                    sm: { span: 24 },
                    xs: { span: 24 }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "comoseentero",
                        label: _vm.$t(
                          "forms_flujo.form2_persona.UY.how_find_out"
                        ),
                        error: _vm.errors.get("comoseentero")
                      }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            clearable: "",
                            placeholder: _vm.$t(
                              "forms_flujo.form2_persona.UY.how_find_out"
                            )
                          },
                          model: {
                            value: _vm.formulario2.comoseentero,
                            callback: function($$v) {
                              _vm.$set(_vm.formulario2, "comoseentero", $$v)
                            },
                            expression: "formulario2.comoseentero"
                          }
                        },
                        _vm._l(_vm.formas_de_enterarse, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.forma, value: item.id }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                {
                  attrs: {
                    xl: { span: 12 },
                    lg: { span: 12 },
                    md: { span: 12 },
                    sm: { span: 24 },
                    xs: { span: 24 }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "info_extra.link_facebook",
                        label: _vm.$t(
                          "forms_flujo.form2_persona.UY.link_facebook"
                        ),
                        error: _vm.errors.get("info_extra.link_facebook")
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "text",
                          "auto-complete": "no",
                          placeholder: _vm.$t(
                            "forms_flujo.form2_persona.UY.link_facebook"
                          )
                        },
                        model: {
                          value: _vm.formulario2.info_extra.link_facebook,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.formulario2.info_extra,
                              "link_facebook",
                              $$v
                            )
                          },
                          expression: "formulario2.info_extra.link_facebook"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                {
                  attrs: {
                    xl: { span: 12 },
                    lg: { span: 12 },
                    md: { span: 12 },
                    sm: { span: 24 },
                    xs: { span: 24 }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "info_extra.telefono_sponsor",
                        label: _vm.$t(
                          "forms_flujo.form2_persona.UY.telefono_sponsor"
                        ),
                        error: _vm.errors.get("info_extra.telefono_sponsor")
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "text",
                          "auto-complete": "no",
                          placeholder: _vm.$t(
                            "forms_flujo.form2_persona.UY.telefono_sponsor"
                          )
                        },
                        model: {
                          value: _vm.formulario2.info_extra.telefono_sponsor,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.formulario2.info_extra,
                              "telefono_sponsor",
                              $$v
                            )
                          },
                          expression: "formulario2.info_extra.telefono_sponsor"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("el-col", { attrs: { span: 24 } }, [
            _c("hr", { staticClass: "pz-dotted" })
          ]),
          _c(
            "el-row",
            { staticClass: "pz-large-margin", attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                {
                  staticClass: "row justify-content-center",
                  attrs: { span: 24 }
                },
                [
                  _c("b", { staticClass: "pz-font-size-lg" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("forms_flujo.form2_persona.UY.contact.title")
                        ) +
                        " "
                    )
                  ])
                ]
              ),
              _c(
                "el-col",
                {
                  staticClass: "row justify-content-center",
                  attrs: { span: 24 }
                },
                [
                  _c("label", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "forms_flujo.form2_persona.UY.contact.sub_title"
                          )
                        ) +
                        " "
                    )
                  ])
                ]
              )
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                {
                  attrs: {
                    xl: { span: 12 },
                    lg: { span: 12 },
                    md: { span: 12 },
                    sm: { span: 24 },
                    xs: { span: 24 }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "persona_contacto",
                        label: _vm.$t(
                          "forms_flujo.form2_persona.UY.contact.full_name"
                        ),
                        error: _vm.errors.get("persona_contacto")
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "text",
                          "auto-complete": "no",
                          placeholder: _vm.$t(
                            "forms_flujo.form2_persona.UY.contact.full_name"
                          )
                        },
                        model: {
                          value: _vm.formulario2.persona_contacto,
                          callback: function($$v) {
                            _vm.$set(_vm.formulario2, "persona_contacto", $$v)
                          },
                          expression: "formulario2.persona_contacto"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                {
                  attrs: {
                    xl: { span: 12 },
                    lg: { span: 12 },
                    md: { span: 12 },
                    sm: { span: 24 },
                    xs: { span: 24 }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "telefono_persona_contacto",
                        label: _vm.$t(
                          "forms_flujo.form2_persona.UY.contact.phone"
                        ),
                        error: _vm.errors.get("telefono_persona_contacto")
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "text",
                          "auto-complete": "no",
                          placeholder: _vm.$t(
                            "forms_flujo.form2_persona.UY.contact.phone"
                          )
                        },
                        model: {
                          value: _vm.formulario2.telefono_persona_contacto,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.formulario2,
                              "telefono_persona_contacto",
                              $$v
                            )
                          },
                          expression: "formulario2.telefono_persona_contacto"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
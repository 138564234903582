//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { required } from "@/commons/utils/Rules";
import baseform2 from '../baseForm2';
import PzFormFlujo from "@/components/Flujo/Templates/PzFormFlujoTemplate";
import currency from "@/commons/filters/other/currency";
import { Money } from 'v-money';
import d_profesion from "@/assets/data/profesion";
export default {
  name: 'PzPersonFormExt2Bnf',
  components: {
    PzFormFlujo: PzFormFlujo,
    Money: Money
  },
  mixins: [baseform2],
  data: function data() {
    var _this = this;

    var check_Jubilado = function check_Jubilado(rule, value, callback) {
      if (!_this.isjubilado && !value) {
        return callback(new Error(_this.$t('rules.incomplete')));
      }

      return callback();
    };

    return {
      data_profesion: d_profesion,
      isjubilado: false,
      formulario2: {
        tipo_trabajo: '',
        ingreso_mensual: 0,
        ingresos_conyuge: 0,
        info_extra: {
          empresa_trabajo: '',
          tipo_trabajo: '',
          direccion_trabajo: '',
          honorarios: 0,
          gastos_familiares: 0,
          gastos_tarjeta_credito: 0,
          gastos_cuota_prestamo: 0,
          gastos_otros: 0,
          profesion: '',
          cargo_ocupa: '',
          ingresos_otros: 0
        }
      },
      money: {
        decimal: '.',
        thousands: ',',
        prefix: '',
        suffix: '',
        precision: 0,
        masked: false
      },
      disable: true,
      rules_formulario2: {
        tipo_trabajo: [required('')],
        ingreso_mensual: [required('')],
        info_extra: {
          direccion: [required('')],
          empresa_trabajo: [{
            validator: check_Jubilado,
            trigger: 'blur'
          }] // honorarios: [type({field: '', type: 'number'})],
          // gastos_familiares: [type({field: '', type: 'number'})],
          // gastos_tarjeta_credito: [type({field: '', type: 'number'})],
          // gastos_cuota_prestamo: [type({field: '', type: 'number'})],
          // gastos_otros: [type({field: '', type: 'number'})],

        }
      },
      pickerOptions1: {
        disabledDate: function disabledDate(time) {
          return time.getTime() > Date.now();
        }
      }
    };
  },
  created: function created() {
    var data = localStorage.getItem('isjubilado');
    if (data && data == 'true') this.isjubilado = true;else this.isjubilado = false;
  },
  beforeMount: function beforeMount() {},
  methods: {
    formatearNumero: function formatearNumero(eve) {
      eve.target.value = currency(eve.target.value, '', 2);
    }
  }
};
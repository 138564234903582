import { render, staticRenderFns } from "./PzFad.vue?vue&type=template&id=1cc1c494&"
import script from "./PzFad.vue?vue&type=script&lang=js&"
export * from "./PzFad.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\xampp\\htdocs\\simulador_new\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1cc1c494')) {
      api.createRecord('1cc1c494', component.options)
    } else {
      api.reload('1cc1c494', component.options)
    }
    module.hot.accept("./PzFad.vue?vue&type=template&id=1cc1c494&", function () {
      api.rerender('1cc1c494', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/commons/forms/DigitalSings/Fad/PzFad.vue"
export default component.exports
import "core-js/modules/es.array.map";
import "core-js/modules/es.function.name";
import "core-js/modules/es.object.values";
import _objectSpread from "C:/xampp/htdocs/simulador_new/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapGetters } from "vuex";
import { checkFinal } from "@/routes/api/views/flujo";
export default {
  name: 'PzMessageUy',
  data: function data() {
    return {};
  },
  computed: _objectSpread({}, mapGetters(['product', 'form_product', 'current_step'])),
  created: function created() {
    this.activities2 = Object.values(this.$t('views.final.data_received_2')).map(function (value) {
      return value;
    });
    this.getRevisionFinal();
    this.$store.dispatch('loader/down', {
      trigger: 'route_enter'
    });
  },
  methods: {
    goFirstStep: function goFirstStep() {
      this.$emit('save');
    },
    getRevisionFinal: function getRevisionFinal() {
      this.$store.dispatch('loader/up', {
        trigger: this.$options.name
      });
      var params = {
        'loan': this.form_product.loan
      };
      checkFinal(params);
    }
  }
};
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-row",
        { staticClass: "text-center pz-font-size-bs" },
        [
          _c(
            "el-col",
            {
              attrs: {
                xl: { span: 12, offset: 6 },
                lg: { span: 12, offset: 6 },
                md: { span: 12, offset: 6 },
                sm: { span: 12, offset: 6 },
                xs: { span: 24 }
              }
            },
            [
              _c(
                "el-col",
                {
                  class: ["pz-clearfix", "border-bottom"],
                  attrs: { span: 24 }
                },
                [
                  _c(
                    "el-col",
                    { staticClass: "text-left", attrs: { span: 12 } },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "forms_flujo.oferta_solicitud_table.amount_requested"
                            )
                          ) +
                          " "
                      )
                    ]
                  ),
                  _c(
                    "el-col",
                    { staticClass: "text-right", attrs: { span: 12 } },
                    [
                      _c("transition", { attrs: { name: "breadcrumb" } }, [
                        _c("b", { key: _vm.myForm.form.monto_oferta }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("currency")(
                                  _vm.myForm.form.monto_oferta,
                                  _vm.myForm.producto.moneda_prestamo.symbol,
                                  0
                                )
                              )
                          )
                        ])
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                {
                  class: ["pz-clearfix", "border-bottom"],
                  attrs: { span: 24 }
                },
                [
                  _c(
                    "el-col",
                    { staticClass: "text-left", attrs: { span: 12 } },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("forms_flujo.oferta_solicitud_table.plazo")
                          ) +
                          " "
                      )
                    ]
                  ),
                  _c(
                    "el-col",
                    { staticClass: "text-right", attrs: { span: 12 } },
                    [
                      _c("transition", { attrs: { name: "breadcrumb" } }, [
                        _c("b", { key: _vm.myForm.form.plazo }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$tc(
                                  "forms_flujo.oferta_solicitud_table.plazo_cuotas",
                                  _vm.myForm.form.plazo,
                                  {
                                    cuotas: _vm.myForm.form.plazo,
                                    type: _vm.myForm.producto.term_type.name
                                  }
                                )
                              ) +
                              " "
                          )
                        ])
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                {
                  class: ["pz-clearfix", "border-bottom"],
                  attrs: { span: 24 }
                },
                [
                  _c(
                    "el-col",
                    { staticClass: "text-left", attrs: { span: 12 } },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("forms_flujo.oferta_solicitud_table.amount")
                          ) +
                          " "
                      )
                    ]
                  ),
                  _c(
                    "el-col",
                    { staticClass: "text-right", attrs: { span: 12 } },
                    [
                      _c("transition", { attrs: { name: "breadcrumb" } }, [
                        _vm.myForm.form.cuota_sugerida !== 0
                          ? _c("b", { key: _vm.myForm.form.cuota_sugerida }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("currency")(
                                      _vm.myForm.form.cuota_sugerida,
                                      _vm.myForm.producto.moneda_prestamo
                                        .symbol,
                                      0
                                    )
                                  ) +
                                  " "
                              )
                            ])
                          : _c(
                              "b",
                              {
                                key: _vm.myForm.form.cuota_sugerida,
                                staticClass: "font-weight-bold"
                              },
                              [_vm._v(" ... ")]
                            )
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                {
                  class: ["pz-clearfix", "border-bottom"],
                  attrs: { span: 24 }
                },
                [
                  _c(
                    "el-col",
                    { staticClass: "text-left", attrs: { span: 12 } },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "forms_flujo.oferta_solicitud_table.fecha_pago"
                            )
                          ) +
                          " "
                      )
                    ]
                  ),
                  _c(
                    "el-col",
                    { staticClass: "text-right", attrs: { span: 12 } },
                    [
                      _c("transition", { attrs: { name: "breadcrumb" } }, [
                        _c("b", { key: _vm.date }, [
                          _vm._v(" " + _vm._s(_vm.date) + " ")
                        ])
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "pz-large-margin" },
        [
          _c(
            "el-row",
            {
              staticClass: "pz-large-margin",
              attrs: { type: "flex", justify: "center" }
            },
            [
              _vm.myForm.producto.americana
                ? _c(
                    "span",
                    { staticClass: "pz-font-size-sm tasa-class text-center" },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "forms_flujo.pz_simulators_template.americana"
                            )
                          ) +
                          " "
                      )
                    ]
                  )
                : _vm._e()
            ]
          ),
          _c(
            "el-row",
            { staticClass: "text-center", attrs: { type: "flex" } },
            [
              _c("span", { staticClass: " pz-font-size-sm" }, [
                _vm._v(
                  _vm._s(
                    _vm.$t("forms_flujo.oferta_solicitud_table.text_adicional")
                  ) + " "
                )
              ])
            ]
          ),
          _c(
            "transition",
            { attrs: { name: "breadcrumb" } },
            [
              _c(
                "el-row",
                {
                  key: _vm.myForm.form.tasa,
                  attrs: { type: "flex", justify: "center" }
                },
                [
                  _vm.myForm.form.tasa &&
                  _vm.$t("forms_flujo.oferta_solicitud_table.tasa") != ""
                    ? _c(
                        "span",
                        { staticClass: "tasa-class pz-font-size-sm" },
                        [
                          _vm._v(_vm._s(_vm.text_tasa) + ": "),
                          _c("b", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("currency")(
                                    _vm.myForm.form.tasa,
                                    " ",
                                    _vm.myForm.form.cant_decimales
                                  )
                                ) +
                                "% "
                            )
                          ])
                        ]
                      )
                    : _c(
                        "span",
                        { staticClass: "tasa-class pz-font-size-sm" },
                        [_c("b", [_vm._v(" ​")])]
                      )
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import "core-js/modules/es.function.name";
import "core-js/modules/es.object.to-string";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import PzUnnaxIframe from "@/commons/forms/Fields/OpenBanking/PzUnnaxIframe";
import { getBankIntegrationWidgetUrl } from "@/routes/api/views/integracion";
import { isEmpty } from "@/commons/utils/functions";
export default {
  name: "UnnaxBankAggregation",
  components: {
    PzUnnaxIframe: PzUnnaxIframe
  },
  props: {
    errors: {
      type: String,
      default: null
    },
    rules: {
      type: Object,
      default: null
    },
    prop: {
      type: String,
      default: null
    },
    value: {
      type: Object,
      default: null
    },
    label: {
      type: String,
      default: null
    }
  },
  data: function data() {
    var _this = this;

    // valida que el proceso UNNAX ha sido concluido satisfactoriamente
    var checkUnnaxComplete = function checkUnnaxComplete(rule, value, callback) {
      if (_this.formulario1.is_unnax_completed === '') {
        return callback(new Error('El proceso UNNAX no ha concluido'));
      }

      return callback();
    };

    return {
      unnax: {
        open_bank_integration: false,
        widget_url: ''
      },
      formulario1: {
        is_unnax_completed: '',
        not_route_obtained: false,
        unnax_timestamp: Date.now()
      },
      rules_formulario1: {
        is_unnax_completed: [{
          validator: checkUnnaxComplete,
          trigger: 'blur'
        }]
      },
      show_unnax_dialog: false
    };
  },
  computed: {
    // deshabilita el checkbox de UNNAX cuando el proceso ha sido culminado satisfactoriamente.
    unnax_checkbox_disabled: function unnax_checkbox_disabled() {
      return this.formulario1.is_unnax_completed === 'completed' || this.formulario1.not_route_obtained;
    }
  },
  mounted: function mounted() {
    var _this2 = this;

    this.initialize(); // activa la escucha de mensajes provenientes del iframe

    window.addEventListener("message", function (evt) {
      if (evt.data === 'unnax:aggregation:done') {
        _this2.formulario1.is_unnax_completed = 'completed';
        setTimeout(function () {
          return _this2.closeDialog();
        }, 1000);
      }

      if (evt.data === 'unnax:aggregation:bank_selection_view:done') {
        _this2.$store.dispatch('loader/down', {
          trigger: _this2.$options.name
        });
      }
    });
  },
  methods: {
    // inicializa valores por defecto
    initialize: function initialize() {
      this.formulario1.is_unnax_completed = '';
      this.formulario1.not_route_obtained = false;
    },
    // cierra el modal de UNNAX
    closeDialog: function closeDialog() {
      this.setUnnaxDialogVisible(false);
    },
    // cambia la visibilidad del modal de UNNAX
    setUnnaxDialogVisible: function setUnnaxDialogVisible(val) {
      this.show_unnax_dialog = val;

      if (val) {
        this.getUnnaxWidgetUrl();
      }
    },
    // obtiene y establece la URL que se llama desde el iframe
    getUnnaxWidgetUrl: function getUnnaxWidgetUrl() {
      var _this3 = this;

      this.$store.dispatch('loader/up', {
        trigger: this.$options.name
      });
      getBankIntegrationWidgetUrl({
        unnax_timestamp: this.formulario1.unnax_timestamp
      }).then(function (res) {
        if (res.data.valid && !isEmpty(res.data.ruta)) {
          var ruta = res.data.ruta;
          _this3.unnax.widget_url = ruta;
          if (ruta === true) _this3.formulario1.is_unnax_completed = 'completed';

          _this3.$root.$emit('set_unnax_timestamp', _this3.formulario1.unnax_timestamp);
        } else {
          var message = isEmpty(res.data.ruta) && res.data.valid ? _this3.$t('views.documentacion.vale.already_signed') : _this3.$t('views.documentacion.vale.not_route_obtain');

          if (!res.data.valid) {
            _this3.formulario1.not_route_obtained = true;
            _this3.unnax.open_bank_integration = false;

            _this3.closeDialog();
          }

          _this3.$store.dispatch('app/setMessage', message);
        }
      }).catch(function (error) {
        _this3.$store.dispatch('app/catchErrors', {
          _this: _this3,
          error: error
        });
      }).finally(function () {
        _this3.$store.dispatch('loader/down', {
          trigger: _this3.$options.name
        });
      });
    }
  }
};
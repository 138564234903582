var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-row",
    [
      _c(
        "el-col",
        {
          attrs: {
            xl: { span: 12, offset: 6 },
            lg: { span: 12, offset: 6 },
            md: { span: 12, offset: 6 },
            sm: { span: 18, offset: 3 },
            xs: { span: 18, offset: 3 }
          }
        },
        [
          _c("el-card", { staticClass: "center-block" }, [
            _c(
              "div",
              { staticClass: " pz-large-margin" },
              [
                _c(
                  "h2",
                  {
                    staticClass:
                      "text-center  pz-font-size-xl pz-color-secondary"
                  },
                  [
                    _c("span", { staticClass: "pz-color-primary" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("currency")(
                              _vm.myForm.best.monto,
                              _vm.myForm.producto.moneda_prestamo.symbol,
                              0
                            )
                          ) +
                          " "
                      )
                    ]),
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "forms_flujo.oferta_up_solicitud_default.label_1"
                          )
                        ) +
                        " "
                    ),
                    _c("span", { staticClass: "pz-color-primary" }, [
                      _vm._v(" " + _vm._s(_vm.myForm.best.cantidad_cuota) + " ")
                    ]),
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "forms_flujo.oferta_up_solicitud_default.label_2"
                          )
                        ) +
                        " "
                    )
                  ]
                ),
                _c(
                  "h2",
                  {
                    staticClass:
                      "text-center pz-font-size-xl pz-color-secondary"
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "forms_flujo.oferta_up_solicitud_default.label_3"
                          )
                        ) +
                        " "
                    ),
                    _c("span", { staticClass: "pz-color-primary" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("currency")(
                              _vm.myForm.best.monto_cuota,
                              _vm.myForm.producto.moneda_prestamo.symbol,
                              0,
                              { spaceBetweenAmountAndSymbol: true }
                            )
                          ) +
                          " "
                      )
                    ]),
                    _vm._v(
                      " " + _vm._s(_vm.myForm.producto.term_type.name) + " "
                    )
                  ]
                ),
                _c(
                  "el-row",
                  { staticClass: "center-block text-center" },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-button",
                          {
                            on: {
                              click: function($event) {
                                return _vm.myForm.setOferta(true)
                              }
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "forms_flujo.oferta_up_solicitud_default.btn_accept"
                                  )
                                ) +
                                " "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        ],
        1
      ),
      _vm.myForm.viewDetalle
        ? _c(
            "el-col",
            {
              attrs: {
                xl: { span: 12, offset: 6 },
                lg: { span: 12, offset: 6 },
                md: { span: 12, offset: 6 },
                sm: { span: 18, offset: 3 },
                xs: { span: 18, offset: 3 }
              }
            },
            [
              _c("el-col", { staticClass: "text-center" }, [
                _c(
                  "label",
                  { staticClass: "pz-color-primary pz-font-size-lg" },
                  [
                    _c("p", [
                      _c(
                        "span",
                        {
                          staticClass:
                            "pz-font-size-md pz-color-primary text-center"
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "forms_flujo.oferta_extra_info_default.label_1"
                              )
                            )
                          )
                        ]
                      )
                    ])
                  ]
                )
              ]),
              _c(
                "el-col",
                {
                  attrs: {
                    xl: { span: 24, offset: 2 },
                    lg: { span: 24, offset: 2 },
                    md: { span: 24, offset: 2 },
                    sm: { span: 24, offset: 2 },
                    xs: { span: 24, offset: 2 }
                  }
                },
                [
                  _c(
                    "label",
                    {
                      staticClass:
                        "pz-color-primary pz-font-size-lg el-col el-col-20"
                    },
                    [
                      _c("p", [
                        _c(
                          "span",
                          {
                            staticClass:
                              "pz-font-size-md pz-color-primary text-left el-col el-col-16"
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "forms_flujo.oferta_extra_info_default.label_2"
                                )
                              )
                            )
                          ]
                        ),
                        _c(
                          "span",
                          {
                            staticClass:
                              "pz-font-size-md pz-color-secondary text-right el-col el-col-8"
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm._f("currency")(
                                  _vm.myForm.detalle.capital,
                                  _vm.myForm.producto.moneda_prestamo.symbol,
                                  0,
                                  { spaceBetweenAmountAndSymbol: true }
                                )
                              )
                            )
                          ]
                        )
                      ]),
                      _c("p", [
                        _c(
                          "span",
                          {
                            staticClass:
                              "pz-font-size-md pz-color-primary text-left el-col el-col-16"
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "forms_flujo.oferta_extra_info_default.label_3"
                                )
                              )
                            )
                          ]
                        ),
                        _c(
                          "span",
                          {
                            staticClass:
                              "pz-font-size-md pz-color-secondary text-right  el-col el-col-8"
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm._f("currency")(
                                  _vm.myForm.detalle.interes,
                                  _vm.myForm.producto.moneda_prestamo.symbol,
                                  0,
                                  { spaceBetweenAmountAndSymbol: true }
                                )
                              )
                            )
                          ]
                        )
                      ]),
                      _c("p", [
                        _c(
                          "span",
                          {
                            staticClass:
                              "pz-font-size-md pz-color-primary text-left el-col el-col-16"
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "forms_flujo.oferta_extra_info_default.label_4"
                                )
                              )
                            )
                          ]
                        ),
                        _c(
                          "span",
                          {
                            staticClass:
                              "pz-font-size-md pz-color-secondary text-right  el-col el-col-8"
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("currency")(
                                    _vm.myForm.detalle.iva,
                                    _vm.myForm.producto.moneda_prestamo.symbol,
                                    0,
                                    { spaceBetweenAmountAndSymbol: true }
                                  )
                                )
                            )
                          ]
                        )
                      ]),
                      _c("p", [
                        _c(
                          "span",
                          {
                            staticClass:
                              "pz-font-size-md pz-color-primary text-left el-col el-col-16"
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "forms_flujo.oferta_extra_info_default.label_5"
                                )
                              )
                            )
                          ]
                        ),
                        _c(
                          "span",
                          {
                            staticClass:
                              "pz-font-size-md pz-color-secondary text-right el-col el-col-8"
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm._f("currency")(
                                  _vm.myForm.detalle.total,
                                  _vm.myForm.producto.moneda_prestamo.symbol,
                                  0,
                                  { spaceBetweenAmountAndSymbol: true }
                                )
                              )
                            )
                          ]
                        )
                      ]),
                      _c("p", [
                        _c(
                          "span",
                          {
                            staticClass:
                              "pz-font-size-md pz-color-primary text-left el-col el-col-16"
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "forms_flujo.oferta_extra_info_default.label_6"
                                )
                              )
                            )
                          ]
                        ),
                        _c(
                          "span",
                          {
                            staticClass:
                              "pz-font-size-md pz-color-secondary text-right el-col el-col-8"
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm._f("currency")(
                                  _vm.myForm.detalle.monto_nuevo,
                                  _vm.myForm.producto.moneda_prestamo.symbol,
                                  0,
                                  { spaceBetweenAmountAndSymbol: true }
                                )
                              )
                            )
                          ]
                        )
                      ]),
                      _c("p", [
                        _c(
                          "span",
                          {
                            staticClass:
                              "pz-font-size-md pz-color-primary text-left el-col el-col-16"
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "forms_flujo.oferta_extra_info_default.label_7"
                                )
                              )
                            )
                          ]
                        ),
                        _c(
                          "span",
                          {
                            staticClass:
                              "pz-font-size-md pz-color-secondary text-right el-col el-col-8"
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm._f("currency")(
                                  _vm.myForm.detalle.monto_dispersar,
                                  _vm.myForm.producto.moneda_prestamo.symbol,
                                  0,
                                  { spaceBetweenAmountAndSymbol: true }
                                )
                              )
                            )
                          ]
                        )
                      ])
                    ]
                  )
                ]
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
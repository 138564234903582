var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-col",
    { attrs: { span: 24 } },
    [
      _c("el-row", [
        _c("h2", { staticClass: "title-2 text-center text-uppercase" }, [
          _vm._v(" Datos de la empresa ")
        ])
      ]),
      _c(
        "el-row",
        { staticClass: "center-block" },
        [
          _c(
            "el-col",
            { attrs: { offset: 1, span: 22 } },
            [
              _c(
                "el-form",
                {
                  ref: _vm.reference,
                  attrs: {
                    model: _vm.formulario2,
                    "status-icon": "",
                    rules: _vm.rules_formulario2
                  }
                },
                [
                  _c(
                    "el-col",
                    {
                      attrs: {
                        xl: { span: 12 },
                        lg: { span: 12 },
                        md: { span: 12 },
                        sm: { span: 24 },
                        xs: { span: 24 }
                      }
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { prop: "rut", error: _vm.errors.get("rut") }
                        },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "text",
                                  "auto-complete": "no",
                                  placeholder: "RUT"
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.errors.clear("rut")
                                  }
                                },
                                model: {
                                  value: _vm.formulario2.rut,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formulario2, "rut", $$v)
                                  },
                                  expression: "formulario2.rut"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "company",
                            error: _vm.errors.get("company")
                          }
                        },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "text",
                                  "auto-complete": "no",
                                  placeholder: "Nombre Fantasia"
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.errors.clear("company")
                                  }
                                },
                                model: {
                                  value: _vm.formulario2.company,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formulario2, "company", $$v)
                                  },
                                  expression: "formulario2.company"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "razonsocial",
                            error: _vm.errors.get("razonsocial")
                          }
                        },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "text",
                                  "auto-complete": "no",
                                  placeholder: "Razon Social"
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.errors.clear("razonsocial")
                                  }
                                },
                                model: {
                                  value: _vm.formulario2.razonsocial,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.formulario2,
                                      "razonsocial",
                                      $$v
                                    )
                                  },
                                  expression: "formulario2.razonsocial"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    {
                      attrs: {
                        xl: { span: 12 },
                        lg: { span: 12 },
                        md: { span: 12 },
                        sm: { span: 24 },
                        xs: { span: 24 }
                      }
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "comienzoactividad",
                            error: _vm.errors.get("comienzoactividad")
                          }
                        },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c("el-date-picker", {
                                attrs: {
                                  type: "date",
                                  placeholder: "Comienzo de Actividades",
                                  format: "dd-MM-yyyy",
                                  "value-format": "dd-MM-yyyy"
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.errors.clear("comienzoactividad")
                                  }
                                },
                                model: {
                                  value: _vm.formulario2.comienzoactividad,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.formulario2,
                                      "comienzoactividad",
                                      $$v
                                    )
                                  },
                                  expression: "formulario2.comienzoactividad"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "bordered",
                          attrs: {
                            prop: "rubro_id",
                            error: _vm.errors.get("rubro_id")
                          }
                        },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "Rubro" },
                                  model: {
                                    value: _vm.formulario2.rubro_id,
                                    callback: function($$v) {
                                      _vm.$set(_vm.formulario2, "rubro_id", $$v)
                                    },
                                    expression: "formulario2.rubro_id"
                                  }
                                },
                                _vm._l(_vm.rubros, function(item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: { label: item.rubro, value: item.id }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "direccion",
                            error: _vm.errors.get("direccion")
                          }
                        },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "text",
                                  "auto-complete": "no",
                                  placeholder: "Direccion"
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.errors.clear("direccion")
                                  }
                                },
                                model: {
                                  value: _vm.formulario2.direccion,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formulario2, "direccion", $$v)
                                  },
                                  expression: "formulario2.direccion"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    {
                      staticClass: "center-block text-center",
                      attrs: { span: 24 }
                    },
                    [
                      _c("pz-back-button"),
                      _c(
                        "el-button",
                        {
                          staticClass: "green-dark",
                          attrs: { round: "" },
                          on: { click: _vm.addSocios }
                        },
                        [_vm._v(" AGREGAR SOCIOS ")]
                      ),
                      _c("pz-forward-button", {
                        attrs: { forward: _vm.handleSave }
                      })
                    ],
                    1
                  ),
                  _vm.formulario2.socios.length > 0
                    ? _c(
                        "el-col",
                        {
                          staticClass: "el-row",
                          staticStyle: {
                            "text-align": "center",
                            width: "100%",
                            color: "white",
                            "font-size": "medium"
                          },
                          attrs: { span: 24 }
                        },
                        [
                          _c("table", { staticClass: "table" }, [
                            _c("thead", [
                              _c("tr", [
                                _c("td", { staticClass: "s-small" }, [
                                  _c("strong", [
                                    _vm._v("Documento de Identidad")
                                  ])
                                ]),
                                _c("td", { staticClass: "s-small" }, [
                                  _c("strong", [_vm._v("Nombre")])
                                ]),
                                _c("td", { staticClass: "s-small" }, [
                                  _c("strong", [_vm._v("Apellido")])
                                ]),
                                _c("td")
                              ])
                            ]),
                            _c(
                              "tbody",
                              _vm._l(_vm.formulario2.socios, function(
                                socio,
                                index
                              ) {
                                return _c("tr", { key: index }, [
                                  _c(
                                    "td",
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          key: socio.key,
                                          attrs: {
                                            prop:
                                              "socios." + index + ".documento",
                                            rules: _vm.rules_socios.documento
                                          }
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              type: "text",
                                              "auto-complete": "no",
                                              placeholder: "Documento"
                                            },
                                            model: {
                                              value: socio.documento,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  socio,
                                                  "documento",
                                                  $$v
                                                )
                                              },
                                              expression: "socio.documento"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "td",
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          key: socio.key,
                                          attrs: {
                                            prop: "socios." + index + ".nombre",
                                            rules: _vm.rules_socios.nombre
                                          }
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              type: "text",
                                              "auto-complete": "no",
                                              placeholder: "Nombre"
                                            },
                                            model: {
                                              value: socio.nombre,
                                              callback: function($$v) {
                                                _vm.$set(socio, "nombre", $$v)
                                              },
                                              expression: "socio.nombre"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "td",
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          key: socio.key,
                                          attrs: {
                                            prop:
                                              "socios." + index + ".apellido",
                                            rules: _vm.rules_socios.apellido
                                          }
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              type: "text",
                                              "auto-complete": "no",
                                              placeholder: "Apellido"
                                            },
                                            model: {
                                              value: socio.apellido,
                                              callback: function($$v) {
                                                _vm.$set(socio, "apellido", $$v)
                                              },
                                              expression: "socio.apellido"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "td",
                                    [
                                      _c("el-button", {
                                        staticClass: "el-dele-socios",
                                        attrs: {
                                          type: "danger",
                                          icon: "el-icon-delete",
                                          circle: ""
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.removeSocios(index)
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ])
                              }),
                              0
                            )
                          ])
                        ]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "pz-form-flujo",
    {
      attrs: {
        "available-captcha": _vm.extraData.withCaptcha,
        forward: _vm.handleSave,
        backwards: _vm.showBack ? _vm.handleBack : null
      },
      model: {
        value: _vm.formulario_garante["captcha"],
        callback: function($$v) {
          _vm.$set(_vm.formulario_garante, "captcha", $$v)
        },
        expression: "formulario_garante['captcha']"
      }
    },
    [
      _c("span", { attrs: { slot: "form_title" }, slot: "form_title" }, [
        _vm._v(" " + _vm._s(_vm.$t("forms_flujo.form_garante.UY.title")) + " ")
      ]),
      _c(
        "el-form",
        {
          ref: _vm.reference,
          attrs: {
            slot: "formulario",
            "label-position": "top",
            model: _vm.formulario_garante,
            "status-icon": "",
            rules: _vm.rules_formulario_garante
          },
          slot: "formulario"
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                {
                  attrs: {
                    xl: { span: 12 },
                    lg: { span: 12 },
                    md: { span: 12 },
                    sm: { span: 24 },
                    xs: { span: 24 }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "nombres",
                        label: _vm.$t("forms_flujo.form1_persona.SP.nombre"),
                        error: _vm.errors.get("nombres")
                      }
                    },
                    [
                      _c("el-input", {
                        ref: "nombres",
                        attrs: {
                          type: "text",
                          placeholder: _vm.$t(
                            "forms_flujo.form1_persona.SP.nombre"
                          )
                        },
                        model: {
                          value: _vm.formulario_garante.nombres,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.formulario_garante,
                              "nombres",
                              _vm._n($$v)
                            )
                          },
                          expression: "formulario_garante.nombres"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                {
                  attrs: {
                    xl: { span: 12 },
                    lg: { span: 12 },
                    md: { span: 12 },
                    sm: { span: 24 },
                    xs: { span: 24 }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "apellidos",
                        label: _vm.$t("forms_flujo.form1_persona.SP.apellidos"),
                        error: _vm.errors.get("apellido_paterno")
                      }
                    },
                    [
                      _c("el-input", {
                        ref: "apellidos",
                        attrs: {
                          type: "text",
                          placeholder: _vm.$t(
                            "forms_flujo.form1_persona.SP.apellidos"
                          )
                        },
                        model: {
                          value: _vm.formulario_garante.apellidos,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.formulario_garante,
                              "apellidos",
                              _vm._n($$v)
                            )
                          },
                          expression: "formulario_garante.apellidos"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                {
                  attrs: {
                    xl: { span: 12 },
                    lg: { span: 12 },
                    md: { span: 12 },
                    sm: { span: 24 },
                    xs: { span: 24 }
                  }
                },
                [
                  _c("pz-identity-document", {
                    ref: "identity",
                    attrs: {
                      prop: "identity",
                      type: "person",
                      "type-label": _vm.$t(
                        "forms_flujo.form_garante.UY.identity_doc"
                      ),
                      "number-label": _vm.$t(
                        "forms_flujo.form_garante.UY.identity_doc"
                      ),
                      "type-hidden": ""
                    },
                    model: {
                      value: _vm.formulario_garante.identity,
                      callback: function($$v) {
                        _vm.$set(_vm.formulario_garante, "identity", $$v)
                      },
                      expression: "formulario_garante.identity"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-col",
                {
                  attrs: {
                    xl: { span: 12 },
                    lg: { span: 12 },
                    md: { span: 12 },
                    sm: { span: 24 },
                    xs: { span: 24 }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "direccion",
                        label: _vm.$t("forms_flujo.form_garante.UY.address"),
                        error: _vm.errors.get("direccion")
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "text",
                          "auto-complete": "no",
                          placeholder: _vm.$t(
                            "forms_flujo.form_garante.UY.address"
                          )
                        },
                        model: {
                          value: _vm.formulario_garante.direccion,
                          callback: function($$v) {
                            _vm.$set(_vm.formulario_garante, "direccion", $$v)
                          },
                          expression: "formulario_garante.direccion"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                {
                  attrs: {
                    xl: { span: 12 },
                    lg: { span: 12 },
                    md: { span: 12 },
                    sm: { span: 24 },
                    xs: { span: 24 }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "fecha_nacimiento",
                        label: _vm.$t("forms_flujo.form_garante.UY.born_date"),
                        error: _vm.errors.get("fecha_nacimiento")
                      }
                    },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "date",
                          format: "dd/MM/yyyy",
                          "value-format": "dd/MM/yyyy",
                          "picker-options": _vm.pickerOptions1,
                          placeholder: _vm.$t(
                            "forms_flujo.form_garante.UY.born_date"
                          )
                        },
                        model: {
                          value: _vm.formulario_garante.fecha_nacimiento,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.formulario_garante,
                              "fecha_nacimiento",
                              $$v
                            )
                          },
                          expression: "formulario_garante.fecha_nacimiento"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                {
                  attrs: {
                    xl: { span: 12 },
                    lg: { span: 12 },
                    md: { span: 12 },
                    sm: { span: 24 },
                    xs: { span: 24 }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "fecha_ingreso",
                        label: _vm.$t(
                          "forms_flujo.form_garante.UY.fecha_ingreso"
                        ),
                        error: _vm.errors.get("fecha_ingreso")
                      }
                    },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "date",
                          "picker-options": _vm.pickerOptions1,
                          format: "dd/MM/yyyy",
                          "value-format": "dd/MM/yyyy",
                          placeholder: _vm.$t(
                            "forms_flujo.form_garante.UY.fecha_ingreso"
                          )
                        },
                        model: {
                          value: _vm.formulario_garante.fecha_ingreso,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.formulario_garante,
                              "fecha_ingreso",
                              $$v
                            )
                          },
                          expression: "formulario_garante.fecha_ingreso"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                {
                  attrs: {
                    xl: { span: 12 },
                    lg: { span: 12 },
                    md: { span: 12 },
                    sm: { span: 24 },
                    xs: { span: 24 }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "ingreso_mensual",
                        label: _vm.$t(
                          "forms_flujo.form_garante.UY.ingreso_mensual"
                        ),
                        error: _vm.errors.get("ingreso_mensual")
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "text",
                          "auto-complete": "no",
                          placeholder: _vm.$t(
                            "forms_flujo.form_garante.UY.ingreso_mensual"
                          )
                        },
                        model: {
                          value: _vm.formulario_garante.ingreso_mensual,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.formulario_garante,
                              "ingreso_mensual",
                              _vm._n($$v)
                            )
                          },
                          expression: "formulario_garante.ingreso_mensual"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                {
                  staticClass: "margin",
                  attrs: {
                    xl: { span: 12 },
                    lg: { span: 12 },
                    md: { span: 12 },
                    sm: { span: 24 },
                    xs: { span: 24 }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "telefono",
                        label: _vm.$t("forms_flujo.form_garante.UY.phone"),
                        error: _vm.errors.get("telefono")
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "text",
                          "auto-complete": "no",
                          placeholder: _vm.$t(
                            "forms_flujo.form_garante.UY.phone"
                          )
                        },
                        model: {
                          value: _vm.formulario_garante.telefono,
                          callback: function($$v) {
                            _vm.$set(_vm.formulario_garante, "telefono", $$v)
                          },
                          expression: "formulario_garante.telefono"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                {
                  attrs: {
                    xl: { span: 12 },
                    lg: { span: 12 },
                    md: { span: 12 },
                    sm: { span: 24 },
                    xs: { span: 24 }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "provincia",
                        label: _vm.$t("forms_flujo.form_garante.UY.state"),
                        error: _vm.errors.get("provincia")
                      }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            clearable: "",
                            placeholder: _vm.$t(
                              "forms_flujo.form_garante.UY.state"
                            )
                          },
                          model: {
                            value: _vm.formulario_garante.provincia,
                            callback: function($$v) {
                              _vm.$set(_vm.formulario_garante, "provincia", $$v)
                            },
                            expression: "formulario_garante.provincia"
                          }
                        },
                        _vm._l(_vm.departamentos, function(item) {
                          return _c("el-option", {
                            key: item.departamento,
                            attrs: {
                              label: item.departamento,
                              value: item.departamento
                            }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                {
                  attrs: {
                    xl: { span: 12 },
                    lg: { span: 12 },
                    md: { span: 12 },
                    sm: { span: 24 },
                    xs: { span: 24 }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "relacion_laboral",
                        label: _vm.$t(
                          "forms_flujo.form_garante.UY.relacion_laboral"
                        ),
                        error: _vm.errors.get("relacion_laboral")
                      }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            clearable: "",
                            placeholder: _vm.$t(
                              "forms_flujo.form_garante.UY.relacion_laboral"
                            )
                          },
                          model: {
                            value: _vm.formulario_garante.relacion_laboral,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.formulario_garante,
                                "relacion_laboral",
                                $$v
                              )
                            },
                            expression: "formulario_garante.relacion_laboral"
                          }
                        },
                        _vm._l(_vm.employment_relations, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.relacion, value: item.id }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                {
                  attrs: {
                    xl: { span: 12 },
                    lg: { span: 12 },
                    md: { span: 12 },
                    sm: { span: 24 },
                    xs: { span: 24 }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "telefono_empresa",
                        label: _vm.$t(
                          "forms_flujo.form_garante.UY.company_phone"
                        ),
                        error: _vm.errors.get("telefono_empresa")
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "text",
                          "auto-complete": "no",
                          placeholder: _vm.$t(
                            "forms_flujo.form_garante.UY.company_phone"
                          )
                        },
                        model: {
                          value: _vm.formulario_garante.telefono_empresa,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.formulario_garante,
                              "telefono_empresa",
                              $$v
                            )
                          },
                          expression: "formulario_garante.telefono_empresa"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
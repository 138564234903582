import "core-js/modules/es.array.find";
import "core-js/modules/es.function.name";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.regexp.constructor";
import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.regexp.to-string";
import "core-js/modules/es.string.iterator";
import "core-js/modules/web.dom-collections.iterator";
import _objectSpread from "C:/xampp/htdocs/simulador_new/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { getBancos, getEstadoCivil, getPaises } from "@/routes/api/resources";
import baseFormFlujo from "@/components/Flujo/Templates/baseFormFlujo";
import PzFormFlujo from "@/components/Flujo/Templates/PzFormFlujoTemplate";
import PzDirection from "@/commons/forms/Fields/PzDirection";
import { mapGetters } from "vuex";
import { required } from "@/commons/utils/Rules";
import PzUnnaxBankAggregation from "@/commons/forms/Fields/OpenBanking/PzUnnaxBankAggregation";
export default {
  name: 'PzPersonFormEs2',
  components: {
    PzDirection: PzDirection,
    PzFormFlujo: PzFormFlujo,
    PzUnnaxBankAggregation: PzUnnaxBankAggregation
  },
  mixins: [baseFormFlujo],
  data: function data() {
    var _this = this;

    // var check_data = (rule, value, callback) => {
    //   if (!(this.formulario1.instantor.open_banking)) {
    //     return callback(new Error(this.$t('rules.should_validate_bank_data')));
    //   } else {
    //     return callback();
    //   }
    // };
    var check_hijos = function check_hijos(rule, value, callback) {
      if (!_this.formulario1.tiene_hijos) {
        _this.formulario1.cant_hijos = '';
        return callback();
      } else {
        return callback();
      }
    };

    var check_doc = function check_doc(rule, value, callback) {
      if (!_this.regla) return callback();
      var pattern = new RegExp(_this.regla.toString());

      if (pattern.test(value)) {
        return callback();
      }

      return callback(new Error(_this.$t('rules.bank_rule')));
    };

    return {
      reference: 'formulario1',
      genero: [{
        id: 'f',
        name: this.$t('forms_flujo.form1_persona.SP.femenino')
      }, {
        id: 'm',
        name: this.$t('forms_flujo.form1_persona.SP.masculino')
      }, {
        id: 'o',
        name: this.$t('forms_flujo.form1_persona.SP.otro')
      }],
      studies: [],
      bancos: [],
      marital_status: [],
      arreglo_hijos: [{
        id: 1,
        name: 'SI'
      }, {
        id: 0,
        name: 'NO'
      }],
      vnz: false,
      regla: '',
      paises: [],
      formulario1: {
        direccion: {},
        estado_civil_id: '',
        genero: '',
        ciudad_nacimiento: '',
        banco_deposito: '',
        cuenta_deposito: '',
        instantor: {},
        info_extra: {
          carnet_conducir: ''
        },
        numero_documento: '',
        tiene_hijos: '',
        cant_hijos: '',
        pais_nacimiento: '',
        unnax_timestamp: null
      },
      rules_formulario1: {
        genero: [required('')],
        tiene_hijos: [required(''), {
          validator: check_hijos,
          trigger: 'change'
        }],
        cuenta_deposito: [{
          validator: check_doc,
          trigger: 'blur'
        }],
        pais_nacimiento: [required('')] //  bank_data:[{validator: check_data, trigger: 'change'}],
        //  'instantor.cuenta': [required('')],

      }
    };
  },
  computed: _objectSpread({}, mapGetters(['applicant'])),
  created: function created() {
    var _this2 = this;

    this.fetchData();
    this.$root.$on('set_unnax_timestamp', function (data) {
      return _this2.updateUnnaxTimestamp(data);
    });
  },
  mounted: function mounted() {
    this.formulario1.unnax_timestamp = null;
    this.formulario1.numero_documento = this.applicant.numero_documento;
  },
  methods: {
    handleChange: function handleChange() {
      var key = this.formulario1.banco_deposito;

      if (this.bancos.length > 0 && key) {
        this.regla = this.bancos.find(function (datos) {
          return datos.id == key;
        });
        this.regla = this.regla.rules;
      }
    },
    fetchData: function fetchData() {
      var _this3 = this;

      this.$store.dispatch('loader/up', {
        trigger: this.$options.name
      });
      var estado = getEstadoCivil().then(function (res) {
        _this3.marital_status = res.data;
      }).catch(function () {
        _this3.marital_status = [];
      });
      var bancos = getBancos().then(function (res) {
        _this3.bancos = res.data;
      }).catch(function () {
        _this3.bancos = [];
      });
      var paises = getPaises().then(function (res) {
        _this3.paises = res.data;
      }).catch(function () {
        _this3.paises = [];
      });
      return Promise.all([estado, bancos, paises]).then(function () {}).finally(function () {
        _this3.$store.dispatch('loader/down', {
          trigger: _this3.$options.name
        });
      });
    },
    updateUnnaxTimestamp: function updateUnnaxTimestamp(timestamp) {
      this.formulario1.unnax_timestamp = timestamp;
    }
  }
};
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import PzForwardButton from "@/commons/forms/Buttons/PzForwardButton";
import PzBackButton from "@/commons/forms/Buttons/PzBackButton";
import baseVerificacionCliente from "./baseVerificacionCliente";
import { required, EspacioBlanco } from '@/commons/utils/Rules';
import PzFormFlujo from "@/components/Flujo/Templates/PzFormFlujoTemplate";
import PzIdentityDocument from "@/commons/forms/Fields/PzIdentityDocument";
import { isEmpty } from '@/commons/utils/functions';
export default {
  name: 'PzVerificacionCliente',
  components: {
    PzFormFlujo: PzFormFlujo,
    PzBackButton: PzBackButton,
    PzForwardButton: PzForwardButton,
    PzIdentityDocument: PzIdentityDocument
  },
  mixins: [baseVerificacionCliente],
  data: function data() {
    return {
      formulario_verificar_cliente: {
        identity: {}
      },
      defaultData: {},
      rules: {
        'identity.document': [required('')],
        'identity.number': [required(''), EspacioBlanco()]
      },
      showForm: false
    };
  },
  mounted: function mounted() {
    this.checkDocAvailability();
  },
  methods: {
    checkDocAvailability: function checkDocAvailability() {
      if (this.defaultData !== null) {
        if (this.defaultData.hasOwnProperty('tipoDoc') && this.defaultData.hasOwnProperty('nroDoc') && !isEmpty(this.$route.params.tipodoc) && !isEmpty(this.$route.params.numerodoc)) {
          this.test = {
            document: this.$route.params.tipodoc,
            number: this.$route.params.numerodoc
          };
          this.formulario_verificar_cliente.identity = {
            document: this.$route.params.tipodoc,
            number: this.$route.params.numerodoc
          };
          this.$store.dispatch('product/setFormProduct', {
            verify_route: true
          });
          this.handleSave();
        } else {
          this.showForm = true;
        }
      }
    }
  }
};
import "core-js/modules/es.function.name";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.string.iterator";
import "core-js/modules/web.dom-collections.iterator";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { required, type } from "@/commons/utils/Rules";
import { getEstadoCivil } from "@/routes/api/resources";
import 'element-ui/lib/theme-chalk/display.css';
import PzBaseForm1 from "@/components/Flujo/Formularios/Formularios1/baseForm1";
import PzFormFlujo from "@/components/Flujo/Templates/PzFormFlujoTemplate";
import PzCheckForm from "@/components/Flujo/Formularios/Formularios1/CheckFields/ChecksForm";
import PzTermsAndConditions from "@/commons/forms/Fields/PzTermsAndConditions";
import PzIdentityDocument from "@/commons/forms/Fields/PzIdentityDocument";
export default {
  name: 'PzPersonForm1Uy',
  components: {
    PzTermsAndConditions: PzTermsAndConditions,
    PzCheckForm: PzCheckForm,
    PzFormFlujo: PzFormFlujo,
    PzIdentityDocument: PzIdentityDocument
  },
  mixins: [PzBaseForm1],
  data: function data() {
    var _this = this;

    var check_Fecha = function check_Fecha(rule, value, callback) {
      if (_this.formulario1.jubilado) {
        return callback();
      }

      if (!_this.formulario1.jubilado && !value) {
        return callback(new Error(_this.$t('rules.incomplete')));
      }

      return callback();
    };

    return {
      reference: 'formulario1',
      marital_status: [],
      formulario1: {
        telefono: '',
        estado_civil_id: '',
        fecha_nacimiento: '',
        fecha_ingreso: '',
        cuota_maxima: '',
        ingreso_mensual: '',
        destino_prestamo: '',
        jubilado: null,
        identity: {
          document: 1
        },
        comprobante_domicilio: null,
        comprobante_ingresos: null,
        clearing: null
      },
      rules_formulario1: {
        'identity.document': [required('')],
        'identity.number': [required('')],
        telefono: [required('')],
        estado_civil_id: [required('')],
        fecha_nacimiento: [required('')],
        fecha_ingreso: [{
          validator: check_Fecha,
          trigger: 'blur'
        }],
        jubilado: [required('')],
        cuota_maxima: [required(''), type({
          field: '',
          type: 'number'
        })],
        ingreso_mensual: [required(''), type({
          field: '',
          type: 'number'
        })],
        destino_prestamo: [required('')]
      },
      pickerOptions1: {
        disabledDate: function disabledDate(time) {
          return Date.now() < time.getTime();
        }
      }
    };
  },
  created: function created() {
    this.fetchData();
  },
  methods: {
    fetchData: function fetchData() {
      var _this2 = this;

      this.$store.dispatch('loader/up', {
        trigger: this.$options.name
      });
      var estado = getEstadoCivil().then(function (res) {
        _this2.marital_status = res.data;
      }).catch(function () {
        _this2.marital_status = [];
      });
      return Promise.all([estado]).then(function () {}).finally(function () {
        _this2.$store.dispatch('loader/down', {
          trigger: _this2.$options.name
        });
      });
    }
  }
};
import "core-js/modules/es.function.name";
import "core-js/modules/es.object.to-string";
import { getDocumentacion } from "@/routes/api/views/flujo";
import baseFormFlujo from "@/components/Flujo/Templates/baseFormFlujo";
import { required } from "@/commons/utils/Rules";
export default {
  name: 'PzBaseFormDocumentacion',
  comments: [],
  mixins: [baseFormFlujo],
  data: function data() {
    return {
      reference: 'formulariodocumentacion',
      settings: {},
      formulariodocumentacion: {},
      rules_formulariodocumentacion: {
        formulariodocumentacion: [required('')]
      }
    };
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    getRule: function getRule(name) {
      return null;
    },
    getDocs: function getDocs(type) {
      var _this = this;

      this.$store.dispatch('loader/up', {
        trigger: this.$options.name
      });
      getDocumentacion({
        id: this.producto.id,
        type: type
      }).then(function (res) {
        _this.docs = res.data.documentaciones;
        _this.formulariodocumentacion = res.data.documentaciones.form;
        _this.settings = res.data.documentaciones.settings;
      }).catch(function () {
        _this.docs = [];
      }).finally(function () {
        _this.$store.dispatch('loader/down', {
          trigger: _this.$options.name
        });
      });
    }
  }
};
import "core-js/modules/es.function.name";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { required } from '@/commons/utils/Rules';
import baseOfertas from "../baseOfertas";
import PzForwardButton from "@/commons/forms/Buttons/PzForwardButton";
import PzTitle from "@/commons/titles/PzTitle";
export default {
  name: 'PzOffersUy',
  components: {
    PzTitle: PzTitle,
    PzForwardButton: PzForwardButton
  },
  mixins: [baseOfertas],
  data: function data() {
    return {
      otras_ofertas: {
        final: ''
      },
      rules_otrasOfertas: {
        final: [required('')]
      }
    };
  },
  methods: {
    setOferta: function setOferta() {
      var original = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      this.$store.dispatch('loader/up', {
        trigger: this.$options.name
      });

      if (original) {
        this.$emit('input', {
          monto: this.best.monto,
          cuota: this.best.cantidad_cuota,
          original: 1
        });
        this.$emit('save');
      } else {
        var validation = this.handleSave(false);

        if (validation) {
          var acepted = '';

          if (this.otras_ofertas.final > 1) {
            var index = this.otras_ofertas.final - 2;
            acepted = this.cuotas_array[index];
          } else {
            acepted = this.montos_array[this.otras_ofertas.final];
          }

          this.$emit('input', {
            monto: acepted.monto,
            cuota: acepted.cuota,
            original: 0
          });
          this.$emit('save');
        }
      }

      this.$store.dispatch('loader/down', {
        trigger: this.$options.name
      });
    },
    handleBack: function handleBack() {
      return this.$router.go(-1);
    }
  }
};
import request from "@/routes/api/api_conextion";
export var getBankIntegrationWidgetUrl = function getBankIntegrationWidgetUrl(params) {
  return request({
    url: "/integracion/bancaria",
    method: "post",
    data: params
  });
};
export var getUnnaxFaceIdUrl = function getUnnaxFaceIdUrl(params) {
  return request({
    url: "/faceid",
    method: "post",
    data: params
  });
};
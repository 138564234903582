import _objectSpread from "C:/xampp/htdocs/simulador_new/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapGetters } from "vuex";
export default {
  name: 'PzBanner',
  data: function data() {
    return {};
  },
  computed: _objectSpread({}, mapGetters(['header'])),
  mounted: function mounted() {},
  methods: {}
};
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import PzOfertaSolicitudDefault from "@/components/Flujo/Simuladores/Ofertas/baseOferta";
export default {
  name: "PzOfertaDescuentosDefault",
  mixins: [PzOfertaSolicitudDefault]
};
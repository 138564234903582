import "core-js/modules/es.array.map";
import "core-js/modules/es.object.values";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import PzOfertaSolicitudDefault from "@/components/Flujo/Simuladores/Ofertas/baseOferta";
export default {
  name: "PzOfertaSolicitudDefault",
  mixins: [PzOfertaSolicitudDefault],
  created: function created() {
    this.activities2 = Object.values(this.$t('forms_flujo.pz_simulators_template.info_bnf')).map(function (value) {
      return value;
    });
  }
};
import baseFormFlujo from "@/components/Flujo/Templates/baseFormFlujo";
export default {
  name: 'PzBaseGarante',
  mixins: [baseFormFlujo],
  props: {
    persona: {
      type: Boolean,
      default: true
    }
  },
  data: function data() {
    return {
      reference: 'formulariogarante'
    };
  },
  created: function created() {},
  methods: {}
};
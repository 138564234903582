var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "pz-form-flujo",
    {
      attrs: {
        "available-captcha": _vm.extraData.withCaptcha,
        forward: _vm.handleSave,
        backwards: _vm.showBack ? _vm.handleBack : null
      },
      model: {
        value: _vm.descuentoTerceros["captcha"],
        callback: function($$v) {
          _vm.$set(_vm.descuentoTerceros, "captcha", $$v)
        },
        expression: "descuentoTerceros['captcha']"
      }
    },
    [
      _c("span", { attrs: { slot: "form_title" }, slot: "form_title" }, [
        _vm._v(
          " " +
            _vm._s(_vm.$t("forms_flujo.descuento_terceros_cheque.title")) +
            " "
        )
      ]),
      _c(
        "el-form",
        {
          ref: _vm.reference,
          attrs: {
            slot: "formulario",
            model: _vm.descuentoTerceros,
            "status-icon": "",
            rules: _vm.rules_descuentoTerceros
          },
          slot: "formulario"
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { staticClass: "text-center", attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "personaFisica",
                        error: _vm.errors.get("personaFisica")
                      }
                    },
                    [
                      _c("label", { staticClass: "pz-margin-right-10" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "forms_flujo.descuento_terceros_cheque.librador_type"
                              )
                            ) +
                            " "
                        )
                      ]),
                      _c("el-switch", {
                        attrs: {
                          "active-text": "Persona",
                          "inactive-text": "Empresa",
                          "active-color": _vm.primary,
                          "inactive-color": _vm.secondary
                        },
                        on: {
                          change: function($event) {
                            return _vm.handleLibradorType()
                          }
                        },
                        model: {
                          value: _vm.descuentoTerceros.personaFisica,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.descuentoTerceros,
                              "personaFisica",
                              $$v
                            )
                          },
                          expression: "descuentoTerceros.personaFisica"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { staticClass: "text-center", attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: { prop: "cheque", error: _vm.errors.get("cheque") }
                    },
                    [
                      _c("label", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "forms_flujo.descuento_terceros_cheque.doc_label"
                            )
                          ) +
                            " - " +
                            _vm._s(
                              _vm.$t(
                                "forms_flujo.descuento_terceros_cheque.document"
                              )
                            )
                        )
                      ]),
                      _c("pz-upload", {
                        attrs: {
                          tip: _vm.$t(
                            "forms_flujo.descuento_terceros_cheque.document_warning"
                          ),
                          "tip-icon": "warning"
                        },
                        model: {
                          value: _vm.descuentoTerceros.cheque,
                          callback: function($$v) {
                            _vm.$set(_vm.descuentoTerceros, "cheque", $$v)
                          },
                          expression: "descuentoTerceros.cheque"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                {
                  attrs: {
                    xl: { span: 12 },
                    lg: { span: 12 },
                    md: { span: 12 },
                    sm: { span: 24 },
                    xs: { span: 24 }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "documento_librador",
                        label: _vm.$t(
                          "forms_flujo.descuento_terceros_cheque.librador_doc_number"
                        ),
                        error: _vm.errors.get("documento_librador"),
                        rules: _vm.descuentoTerceros.personaFisica
                          ? [_vm.Cedula("")]
                          : [_vm.Rut("")]
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "text",
                          "auto-complete": "no",
                          placeholder: _vm.$t(
                            "forms_flujo.descuento_terceros_cheque.librador_doc_number"
                          )
                        },
                        on: {
                          change: function($event) {
                            return _vm.errors.clear("documento_librador")
                          }
                        },
                        model: {
                          value: _vm.descuentoTerceros.documento_librador,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.descuentoTerceros,
                              "documento_librador",
                              $$v
                            )
                          },
                          expression: "descuentoTerceros.documento_librador"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                {
                  attrs: {
                    xl: { span: 12 },
                    lg: { span: 12 },
                    md: { span: 12 },
                    sm: { span: 24 },
                    xs: { span: 24 }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "nombre_librador",
                        label: _vm.$t(
                          "forms_flujo.descuento_terceros_cheque.librador_name"
                        ),
                        error: _vm.errors.get("nombre_librador")
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "text",
                          placeholder: _vm.$t(
                            "forms_flujo.descuento_terceros_cheque.librador_name"
                          )
                        },
                        on: {
                          change: function($event) {
                            return _vm.errors.clear("nombre_librador")
                          }
                        },
                        model: {
                          value: _vm.descuentoTerceros.nombre_librador,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.descuentoTerceros,
                              "nombre_librador",
                              $$v
                            )
                          },
                          expression: "descuentoTerceros.nombre_librador"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _vm.descuentoTerceros.personaFisica
                ? _c(
                    "el-col",
                    {
                      attrs: {
                        xl: { span: 12 },
                        lg: { span: 12 },
                        md: { span: 12 },
                        sm: { span: 24 },
                        xs: { span: 24 }
                      }
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "apellido_librador",
                            label: _vm.$t(
                              "forms_flujo.descuento_terceros_cheque.librador_surename"
                            ),
                            error: _vm.errors.get("apellido_librador")
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "text",
                              "auto-complete": "no",
                              placeholder: _vm.$t(
                                "forms_flujo.descuento_terceros_cheque.librador_surename"
                              )
                            },
                            on: {
                              change: function($event) {
                                return _vm.errors.clear("apellido_librador")
                              }
                            },
                            model: {
                              value: _vm.descuentoTerceros.apellido_librador,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.descuentoTerceros,
                                  "apellido_librador",
                                  $$v
                                )
                              },
                              expression: "descuentoTerceros.apellido_librador"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.descuentoTerceros.personaFisica
                ? _c(
                    "el-col",
                    {
                      attrs: {
                        xl: { span: 12 },
                        lg: { span: 12 },
                        md: { span: 12 },
                        sm: { span: 24 },
                        xs: { span: 24 }
                      }
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "telefono_contacto",
                            label: _vm.$t(
                              "forms_flujo.descuento_terceros_cheque.librador_phone"
                            ),
                            error: _vm.errors.get("telefono_contacto")
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "text",
                              "auto-complete": "no",
                              placeholder: _vm.$t(
                                "forms_flujo.descuento_terceros_cheque.librador_phone"
                              )
                            },
                            on: {
                              change: function($event) {
                                return _vm.errors.clear("telefono_contacto")
                              }
                            },
                            model: {
                              value: _vm.descuentoTerceros.telefono_contacto,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.descuentoTerceros,
                                  "telefono_contacto",
                                  _vm._n($$v)
                                )
                              },
                              expression: "descuentoTerceros.telefono_contacto"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                {
                  attrs: {
                    xl: { span: 12 },
                    lg: { span: 12 },
                    md: { span: 12 },
                    sm: { span: 24 },
                    xs: { span: 24 }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "bordered",
                      attrs: {
                        prop: "banco_id",
                        label: _vm.$t(
                          "forms_flujo.descuento_terceros_cheque.bank"
                        ),
                        error: _vm.errors.get("banco_id")
                      }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: _vm.$t(
                              "forms_flujo.descuento_terceros_cheque.bank"
                            )
                          },
                          model: {
                            value: _vm.descuentoTerceros.banco_id,
                            callback: function($$v) {
                              _vm.$set(_vm.descuentoTerceros, "banco_id", $$v)
                            },
                            expression: "descuentoTerceros.banco_id"
                          }
                        },
                        _vm._l(_vm.bancos, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.complete_name, value: item.id }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                {
                  attrs: {
                    xl: { span: 12 },
                    lg: { span: 12 },
                    md: { span: 12 },
                    sm: { span: 24 },
                    xs: { span: 24 }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "numero_cuenta",
                        label: _vm.$t(
                          "forms_flujo.descuento_terceros_cheque.account_number"
                        ),
                        error: _vm.errors.get("numero_cuenta")
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "text",
                          "auto-complete": "no",
                          placeholder: _vm.$t(
                            "forms_flujo.descuento_terceros_cheque.account_number"
                          )
                        },
                        on: {
                          change: function($event) {
                            return _vm.errors.clear("numero_cuenta")
                          }
                        },
                        model: {
                          value: _vm.descuentoTerceros.numero_cuenta,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.descuentoTerceros,
                              "numero_cuenta",
                              $$v
                            )
                          },
                          expression: "descuentoTerceros.numero_cuenta"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                {
                  attrs: {
                    xl: { span: 12 },
                    lg: { span: 12 },
                    md: { span: 12 },
                    sm: { span: 24 },
                    xs: { span: 24 }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "numero",
                        label: _vm.$t(
                          "forms_flujo.descuento_terceros_cheque.number"
                        ),
                        error: _vm.errors.get("numero")
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "text",
                          "auto-complete": "no",
                          placeholder: _vm.$t(
                            "forms_flujo.descuento_terceros_cheque.number"
                          )
                        },
                        on: {
                          change: function($event) {
                            return _vm.errors.clear("numero")
                          }
                        },
                        model: {
                          value: _vm.descuentoTerceros.numero,
                          callback: function($$v) {
                            _vm.$set(_vm.descuentoTerceros, "numero", $$v)
                          },
                          expression: "descuentoTerceros.numero"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                {
                  attrs: {
                    xl: { span: 12 },
                    lg: { span: 12 },
                    md: { span: 12 },
                    sm: { span: 24 },
                    xs: { span: 24 }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "serie",
                        label: _vm.$t(
                          "forms_flujo.descuento_terceros_cheque.serie"
                        ),
                        error: _vm.errors.get("serie")
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "text",
                          "auto-complete": "no",
                          placeholder: _vm.$t(
                            "forms_flujo.descuento_terceros_cheque.serie"
                          )
                        },
                        on: {
                          change: function($event) {
                            return _vm.errors.clear("serie")
                          }
                        },
                        model: {
                          value: _vm.descuentoTerceros.serie,
                          callback: function($$v) {
                            _vm.$set(_vm.descuentoTerceros, "serie", $$v)
                          },
                          expression: "descuentoTerceros.serie"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-row",
    [
      _c(
        "el-form-item",
        {
          class: { "is-required": _vm.required },
          attrs: {
            label: _vm.label ? _vm.label : _vm.$t("commons.phone_number.label"),
            prop: "phone_number",
            rules: _vm.required ? _vm.rule.phone : _vm.rule.number,
            error: _vm.error
          }
        },
        [
          _c(
            "el-input",
            {
              staticClass: "input-with-select",
              attrs: {
                disabled: _vm.disabled,
                placeholder: _vm.label
                  ? _vm.label
                  : _vm.$t("commons.phone_number.label")
              },
              model: {
                value: _vm.result.numero,
                callback: function($$v) {
                  _vm.$set(_vm.result, "numero", $$v)
                },
                expression: "result.numero"
              }
            },
            [
              !_vm.noCountrySelector
                ? _c(
                    "el-select",
                    {
                      staticClass: "pz-code-country",
                      attrs: {
                        slot: "prepend",
                        disabled: _vm.disabled || _vm.disabledCountrySelector,
                        filterable: "",
                        "filter-method": _vm.filterMethod,
                        placeholder: ""
                      },
                      on: { change: _vm.handleChange },
                      slot: "prepend",
                      model: {
                        value: _vm.countryIso,
                        callback: function($$v) {
                          _vm.countryIso = $$v
                        },
                        expression: "countryIso"
                      }
                    },
                    [
                      !_vm.noFlags
                        ? _c("flag", {
                            staticClass: "pz-code-country-flag",
                            attrs: { slot: "prefix", iso: _vm.countryIso },
                            slot: "prefix"
                          })
                        : _vm._e(),
                      _vm._l(_vm.filteredOptions, function(item) {
                        return _c(
                          "el-option",
                          {
                            key: item.iso,
                            attrs: {
                              label: "+" + item.calling_code,
                              value: item.iso,
                              placeholder: "+___"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { float: "left" } },
                              [
                                !_vm.noFlags
                                  ? _c("flag", {
                                      attrs: { slot: "prefix", iso: item.iso },
                                      slot: "prefix"
                                    })
                                  : _vm._e(),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      (_vm.showCodeOnList
                                        ? "(+" + item.calling_code + ") "
                                        : "") + item.nombre
                                    ) +
                                    " "
                                )
                              ],
                              1
                            )
                          ]
                        )
                      })
                    ],
                    2
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
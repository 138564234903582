//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import 'element-ui/lib/theme-chalk/display.css';
import baseFormDescuentosTerceros from "./baseFormDescuentosTerceros";
import { required, Rut } from "@/commons/utils/Rules";
import PzFormFlujo from "@/components/Flujo/Templates/PzFormFlujoTemplate";
export default {
  name: 'PzFacturaDescuentoTerceros',
  components: {
    PzFormFlujo: PzFormFlujo
  },
  mixins: [baseFormDescuentosTerceros],
  data: function data() {
    return {
      descuentoTerceros: {
        documento_librador: '',
        nombre_librador: '',
        telefono_contacto: '',
        email: ''
      },
      disable: true,
      rules_descuentoTerceros: {
        documento_librador: [Rut('')],
        nombre_librador: [required('')]
      }
    };
  },
  created: function created() {},
  mounted: function mounted() {},
  methods: {}
};
import "core-js/modules/es.function.name";
import "core-js/modules/es.object.to-string";
import { getAllProfiles } from '@/routes/api/views/flujo';
import { required } from "@/commons/utils/Rules";
import PzBaseForm from "@/commons/forms/PzBaseForm";
export default {
  name: 'PzBaseProductosAll',
  mixins: [PzBaseForm],
  provide: function provide() {
    return {
      myForm: this
    };
  },
  data: function data() {
    return {
      reference: 'formularioProducts',
      type: '',
      productos: [],
      formularioProducts: {
        selected: null
      },
      rules_formularioProducts: {
        selected: [required('')]
      }
    };
  },
  created: function created() {
    this.fechtData();
  },
  methods: {
    fechtData: function fechtData() {
      var _this = this;

      this.$store.dispatch('loader/up', {
        trigger: this.$options.name
      });
      this.type = this.$route.params.type;
      getAllProfiles({
        type: this.type
      }).then(function (res) {
        _this.productos = res.data.productos;
      }).catch(function (error) {
        _this.$store.dispatch('app/catchErrors', {
          _this: _this,
          error: error
        });
      }).finally(function () {
        _this.$store.dispatch('loader/down', {
          trigger: _this.$options.name
        });
      });
    }
  }
};
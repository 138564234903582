var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-row",
    { staticClass: "padding" },
    [
      _c(
        "el-col",
        {
          attrs: {
            xl: { span: 12, offset: 6 },
            lg: { span: 12, offset: 6 },
            md: { span: 12, offset: 6 },
            sm: { span: 18, offset: 3 },
            xs: { span: 18, offset: 3 }
          }
        },
        [
          _c("el-card", { staticClass: "center-block box-green-dark" }, [
            _c("h2", { staticClass: "text-center" }, [
              _c("span", { staticClass: "x-large" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("forms_flujo.oferta_up_descuentos_default.label_1")
                    ) +
                    " " +
                    _vm._s(
                      _vm._f("currency")(
                        _vm.myForm.best.monto,
                        _vm.myForm.producto.moneda_prestamo.symbol,
                        0
                      )
                    ) +
                    " "
                )
              ])
            ]),
            _c("h2", { staticClass: "text-center" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("forms_flujo.oferta_up_descuentos_default.label_2")
                  ) +
                  " "
              ),
              _c("span", { staticClass: "x-large" }, [
                _vm._v(" " + _vm._s(_vm.myForm.best.tea) + "%")
              ])
            ])
          ])
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "center-block text-center" },
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c(
                "el-button",
                {
                  staticClass: "green-ligth",
                  attrs: { round: "" },
                  on: {
                    click: function($event) {
                      return _vm.myForm.setOferta()
                    }
                  }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "forms_flujo.oferta_up_descuentos_default.btn_accept"
                        )
                      ) +
                      " "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
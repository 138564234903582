import "core-js/modules/es.function.name";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.string.iterator";
import "core-js/modules/web.dom-collections.iterator";
import "core-js/modules/web.url";
import _objectSpread from "C:/xampp/htdocs/simulador_new/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapGetters } from "vuex";
import { isMobile } from '@/commons/utils/functions';
import VueQrcode from '@chenfengyuan/vue-qrcode';
import { setNaat } from "@/routes/api/views/vales";
import PzButton from "@/commons/forms/Buttons/PzButton";
export default {
  name: "PzNaat",
  components: {
    VueQrcode: VueQrcode,
    PzButton: PzButton
  },
  props: {
    route: {
      require: true,
      default: null,
      type: [String, URL]
    },
    redirect: {
      require: true,
      default: 'Prestamos',
      type: [String]
    }
  },
  data: function data() {
    return {
      frame_route: this.route,
      url: window.location.href,
      height: '600px',
      watch: {
        route: function route(value) {
          this.frame_route = value;
        },
        frame_route: function frame_route(value) {
          console.log('change form route');
          console.log(value);
        }
      },
      continuar: false
    };
  },
  computed: _objectSpread(_objectSpread({}, mapGetters(['alias'])), {}, {
    esmobil: function esmobil() {
      return isMobile();
    }
  }),
  created: function created() {
    var _this = this;

    window.addEventListener("message", function (event) {
      if (event.data) {
        if (event.data.event === 'PROCESS_STARTED') {
          console.log('proceso iniciado');
        } else if (event.data.event == 'PROCESS_COMPLETED') {
          setNaat(_this.$route.params.alias, _this.$route.params.loan).finally(function () {
            _this.continuar = true;
            _this.loading = false;

            _this.handleSend();
          });
          console.log('proceso terminado');
        } else if (event.data.event == 'PROCESS_ENDED') {
          setNaat(_this.$route.params.alias, _this.$route.params.loan).finally(function () {
            _this.continuar = true;
            _this.loading = false;

            _this.handleSend();
          });
          console.log('proceso terminado');
        } else if (event.data.event == 'SIGNATURE_COMPLETED') {
          setNaat(_this.$route.params.alias, _this.$route.params.loan).finally(function () {
            _this.continuar = true;
            _this.loading = false;

            _this.handleSend();
          });
          console.log('proceso de firma completo');
        } else if (event.data.event === 'RELOAD_PROCESS') {
          console.log('reinicia el proceso');
        }
      } else {
        return;
      }
    }, false);
  },
  methods: {
    handleSend: function handleSend() {
      this.$store.dispatch('loader/up', {
        trigger: this.$options.name
      });
      this.$router.push({
        name: 'FinFirma'
      });
      this.$store.dispatch('loader/down', {
        trigger: this.$options.name
      });
    }
  }
};
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-col",
    { staticClass: "pz-large-margin", attrs: { span: 24 } },
    [
      _c(
        "el-row",
        { attrs: { gutter: 10 } },
        [
          _c(
            "el-col",
            { attrs: { span: 22, offset: 1 } },
            [_vm._t("simulador")],
            2
          )
        ],
        1
      ),
      _vm._t("buttons", [
        _c(
          "el-col",
          { staticClass: "text-center", attrs: { span: 24 } },
          [
            _vm._t("button_back", [
              _vm.myForm.showBack
                ? _c("pz-back-button", { attrs: { back: _vm.handleBack } })
                : _vm._e()
            ]),
            _vm._t("button_forward", [
              _c("pz-forward-button", {
                attrs: {
                  forward: _vm.handleForward,
                  "button-name": _vm.$t(
                    "forms_flujo.pz_simulators_template.btn_accept"
                  )
                }
              })
            ])
          ],
          2
        )
      ]),
      _vm.myForm.persona
        ? _c("el-col", { staticClass: "text-center", attrs: { span: 24 } }, [
            _c(
              "div",
              { staticClass: "pz-font-size-sm" },
              _vm._l(_vm.leyenda, function(value, index) {
                return _c("p", { key: index }, [
                  _vm._v(" " + _vm._s(value) + " ")
                ])
              }),
              0
            )
          ])
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "pz-form-flujo",
    {
      attrs: {
        "available-captcha": _vm.extraData.withCaptcha,
        forward: _vm.handleSave,
        backwards: _vm.showBack ? _vm.handleBack : null
      },
      model: {
        value: _vm.formulario2["captcha"],
        callback: function($$v) {
          _vm.$set(_vm.formulario2, "captcha", $$v)
        },
        expression: "formulario2['captcha']"
      }
    },
    [
      _c(
        "el-form",
        {
          ref: _vm.reference,
          attrs: {
            slot: "formulario",
            "label-position": "top",
            model: _vm.formulario2,
            "status-icon": "",
            rules: _vm.rules_formulario2
          },
          slot: "formulario"
        },
        [
          _c("pz-direction", {
            model: {
              value: _vm.formulario2.direccion,
              callback: function($$v) {
                _vm.$set(_vm.formulario2, "direccion", $$v)
              },
              expression: "formulario2.direccion"
            }
          }),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                {
                  attrs: {
                    xl: { span: 12 },
                    lg: { span: 12 },
                    md: { span: 12 },
                    sm: { span: 24 },
                    xs: { span: 24 }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "telefono_fijo",
                        label: _vm.$t("forms_flujo.form2_persona.MX.phone"),
                        error: _vm.errors.get("telefono_fijo")
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "text",
                          "auto-complete": "no",
                          placeholder: _vm.$t(
                            "forms_flujo.form2_persona.MX.phone"
                          )
                        },
                        model: {
                          value: _vm.formulario2.telefono_fijo,
                          callback: function($$v) {
                            _vm.$set(_vm.formulario2, "telefono_fijo", $$v)
                          },
                          expression: "formulario2.telefono_fijo"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            { staticClass: "pz-large-margin", attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                {
                  staticClass: "row justify-content-end",
                  attrs: {
                    xl: { span: 12 },
                    lg: { span: 12 },
                    md: { span: 12 },
                    sm: { span: 24 },
                    xs: { span: 24 }
                  }
                },
                [
                  _c("b", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "forms_flujo.form2_persona.MX.contact.title_personal"
                          )
                        ) +
                        " "
                    )
                  ])
                ]
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c(
                "el-col",
                {
                  attrs: {
                    xl: { span: 12 },
                    lg: { span: 12 },
                    md: { span: 12 },
                    sm: { span: 24 },
                    xs: { span: 24 }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "personal",
                        error: _vm.errors.get("personal")
                      }
                    },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "text",
                              label: _vm.$t(
                                "forms_flujo.form2_persona.MX.contact.personal_full_name"
                              ),
                              "auto-complete": "no",
                              placeholder: _vm.$t(
                                "forms_flujo.form2_persona.MX.contact.personal_full_name"
                              )
                            },
                            model: {
                              value: _vm.formulario2.personal.nombre_completo,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.formulario2.personal,
                                  "nombre_completo",
                                  $$v
                                )
                              },
                              expression: "formulario2.personal.nombre_completo"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "text",
                              label: _vm.$t(
                                "forms_flujo.form2_persona.MX.contact.personal_address"
                              ),
                              "auto-complete": "no",
                              placeholder: _vm.$t(
                                "forms_flujo.form2_persona.MX.contact.personal_address"
                              )
                            },
                            model: {
                              value: _vm.formulario2.personal.direccion,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.formulario2.personal,
                                  "direccion",
                                  $$v
                                )
                              },
                              expression: "formulario2.personal.direccion"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                {
                  attrs: {
                    xl: { span: 12 },
                    lg: { span: 12 },
                    md: { span: 12 },
                    sm: { span: 24 },
                    xs: { span: 24 }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "personal",
                        error: _vm.errors.get("personal")
                      }
                    },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "text",
                              label: _vm.$t(
                                "forms_flujo.form2_persona.MX.contact.personal_phone"
                              ),
                              "auto-complete": "no",
                              placeholder: _vm.$t(
                                "forms_flujo.form2_persona.MX.contact.personal_phone"
                              )
                            },
                            model: {
                              value: _vm.formulario2.personal.telefono,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.formulario2.personal,
                                  "telefono",
                                  $$v
                                )
                              },
                              expression: "formulario2.personal.telefono"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "text",
                              label: _vm.$t(
                                "forms_flujo.form2_persona.MX.contact.personal_parentship"
                              ),
                              "auto-complete": "no",
                              placeholder: _vm.$t(
                                "forms_flujo.form2_persona.MX.contact.personal_parentship"
                              )
                            },
                            model: {
                              value: _vm.formulario2.personal.parentesco,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.formulario2.personal,
                                  "parentesco",
                                  $$v
                                )
                              },
                              expression: "formulario2.personal.parentesco"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            { staticClass: "pz-large-margin", attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                {
                  staticClass: "row justify-content-end",
                  attrs: {
                    xl: { span: 12 },
                    lg: { span: 12 },
                    md: { span: 12 },
                    sm: { span: 24 },
                    xs: { span: 24 }
                  }
                },
                [
                  _c("b", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "forms_flujo.form2_persona.MX.contact.title_familiar"
                          )
                        ) +
                        " "
                    )
                  ])
                ]
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c(
                "el-col",
                {
                  attrs: {
                    xl: { span: 12 },
                    lg: { span: 12 },
                    md: { span: 12 },
                    sm: { span: 24 },
                    xs: { span: 24 }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "personal",
                        error: _vm.errors.get("personal")
                      }
                    },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "text",
                              label: _vm.$t(
                                "forms_flujo.form2_persona.MX.contact.familiar_full_name"
                              ),
                              "auto-complete": "no",
                              placeholder: "Nombre y apellido"
                            },
                            model: {
                              value: _vm.formulario2.familiar.nombre_completo,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.formulario2.familiar,
                                  "nombre_completo",
                                  $$v
                                )
                              },
                              expression: "formulario2.familiar.nombre_completo"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "text",
                              "auto-complete": "no",
                              placeholder: _vm.$t(
                                "forms_flujo.form2_persona.MX.contact.familiar_full_name"
                              )
                            },
                            model: {
                              value: _vm.formulario2.familiar.direccion,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.formulario2.familiar,
                                  "direccion",
                                  $$v
                                )
                              },
                              expression: "formulario2.familiar.direccion"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                {
                  attrs: {
                    xl: { span: 12 },
                    lg: { span: 12 },
                    md: { span: 12 },
                    sm: { span: 24 },
                    xs: { span: 24 }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "personal",
                        error: _vm.errors.get("personal")
                      }
                    },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "text",
                              label: _vm.$t(
                                "forms_flujo.form2_persona.MX.contact.familiar_phone"
                              ),
                              "auto-complete": "no",
                              placeholder: _vm.$t(
                                "forms_flujo.form2_persona.MX.contact.familiar_phone"
                              )
                            },
                            model: {
                              value: _vm.formulario2.familiar.telefono,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.formulario2.familiar,
                                  "telefono",
                                  $$v
                                )
                              },
                              expression: "formulario2.familiar.telefono"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "text",
                              label: _vm.$t(
                                "forms_flujo.form2_persona.MX.contact.familiar_parentship"
                              ),
                              "auto-complete": "no",
                              placeholder: _vm.$t(
                                "forms_flujo.form2_persona.MX.contact.familiar_parentship"
                              )
                            },
                            model: {
                              value: _vm.formulario2.familiar.parentesco,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.formulario2.familiar,
                                  "parentesco",
                                  $$v
                                )
                              },
                              expression: "formulario2.familiar.parentesco"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "pz-form-flujo",
    {
      attrs: {
        "available-captcha": _vm.extraData.withCaptcha,
        forward: _vm.handleSave,
        backwards: _vm.showBack ? _vm.handleBack : null
      },
      model: {
        value: _vm.formulario_garante["captcha"],
        callback: function($$v) {
          _vm.$set(_vm.formulario_garante, "captcha", $$v)
        },
        expression: "formulario_garante['captcha']"
      }
    },
    [
      _c("span", { attrs: { slot: "form_title" }, slot: "form_title" }, [
        _vm._v(" " + _vm._s(_vm.$t("forms_flujo.form_garante.MX.title")) + " ")
      ]),
      _c(
        "el-form",
        {
          ref: _vm.reference,
          attrs: {
            slot: "formulario",
            "label-position": "top",
            model: _vm.formulario_garante,
            "status-icon": "",
            rules: _vm.rules_formulario_garante
          },
          slot: "formulario"
        },
        [
          _c(
            "el-col",
            {
              staticClass: "margin",
              attrs: {
                xl: { span: 12 },
                lg: { span: 12 },
                md: { span: 12 },
                sm: { span: 24 },
                xs: { span: 24 }
              }
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "numero_documento",
                    label: _vm.$t("forms_flujo.form_garante.UY.identity_doc"),
                    error: _vm.errors.get("numero_documento")
                  }
                },
                [
                  _c("el-input", {
                    attrs: {
                      type: "text",
                      "auto-complete": "no",
                      placeholder: _vm.$t(
                        "forms_flujo.form_garante.MX.identity_doc"
                      )
                    },
                    model: {
                      value: _vm.formulario_garante.numero_documento,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.formulario_garante,
                          "numero_documento",
                          $$v
                        )
                      },
                      expression: "formulario_garante.numero_documento"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "nivel3",
                    label: _vm.$t("forms_flujo.form_garante.MX.level_3"),
                    error: _vm.errors.get("nivel3")
                  }
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        clearable: "",
                        placeholder: _vm.$t(
                          "forms_flujo.form_garante.MX.level_3"
                        )
                      },
                      on: {
                        change: function($event) {
                          return _vm.getLocalidad()
                        }
                      },
                      model: {
                        value: _vm.formulario_garante.nivel3,
                        callback: function($$v) {
                          _vm.$set(_vm.formulario_garante, "nivel3", $$v)
                        },
                        expression: "formulario_garante.nivel3"
                      }
                    },
                    _vm._l(_vm.municipios, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.nombre, value: item.id }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "nivel5",
                    label: _vm.$t("forms_flujo.form_garante.MX.address"),
                    error: _vm.errors.get("nivel5")
                  }
                },
                [
                  _c("el-input", {
                    attrs: {
                      type: "text",
                      "auto-complete": "no",
                      placeholder: _vm.$t("forms_flujo.form_garante.MX.address")
                    },
                    model: {
                      value: _vm.formulario_garante.nivel5,
                      callback: function($$v) {
                        _vm.$set(_vm.formulario_garante, "nivel5", $$v)
                      },
                      expression: "formulario_garante.nivel5"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "fecha_ingreso",
                    label: _vm.$t("forms_flujo.form_garante.MX.fecha_ingreso"),
                    error: _vm.errors.get("fecha_ingreso")
                  }
                },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "date",
                      "picker-options": _vm.pickerOptions1,
                      format: "dd/MM/yyyy",
                      "value-format": "dd/MM/yyyy",
                      placeholder: _vm.$t(
                        "forms_flujo.form_garante.MX.fecha_ingreso"
                      )
                    },
                    model: {
                      value: _vm.formulario_garante.fecha_ingreso,
                      callback: function($$v) {
                        _vm.$set(_vm.formulario_garante, "fecha_ingreso", $$v)
                      },
                      expression: "formulario_garante.fecha_ingreso"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "ingreso_mensual",
                    label: _vm.$t(
                      "forms_flujo.form_garante.MX.ingreso_mensual"
                    ),
                    error: _vm.errors.get("ingreso_mensual")
                  }
                },
                [
                  _c("el-input", {
                    attrs: {
                      type: "text",
                      "auto-complete": "no",
                      placeholder: _vm.$t(
                        "forms_flujo.form_garante.MX.ingreso_mensual"
                      )
                    },
                    model: {
                      value: _vm.formulario_garante.ingreso_mensual,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.formulario_garante,
                          "ingreso_mensual",
                          _vm._n($$v)
                        )
                      },
                      expression: "formulario_garante.ingreso_mensual"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            {
              staticClass: "margin",
              attrs: {
                xl: { span: 12 },
                lg: { span: 12 },
                md: { span: 12 },
                sm: { span: 24 },
                xs: { span: 24 }
              }
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "nivel2",
                    label: _vm.$t("forms_flujo.form_garante.MX.state"),
                    error: _vm.errors.get("nivel2")
                  }
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        clearable: "",
                        placeholder: _vm.$t("forms_flujo.form_garante.MX.state")
                      },
                      on: {
                        change: function($event) {
                          return _vm.getMunicipio()
                        }
                      },
                      model: {
                        value: _vm.formulario_garante.nivel2,
                        callback: function($$v) {
                          _vm.$set(_vm.formulario_garante, "nivel2", $$v)
                        },
                        expression: "formulario_garante.nivel2"
                      }
                    },
                    _vm._l(_vm.provincias, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.departamento, value: item.id }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "nivel4",
                    label: _vm.$t("forms_flujo.form_garante.MX.level_4"),
                    error: _vm.errors.get("nivel4")
                  }
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        clearable: "",
                        placeholder: _vm.$t(
                          "forms_flujo.form_garante.MX.level_4"
                        )
                      },
                      model: {
                        value: _vm.formulario_garante.nivel4,
                        callback: function($$v) {
                          _vm.$set(_vm.formulario_garante, "nivel4", $$v)
                        },
                        expression: "formulario_garante.nivel4"
                      }
                    },
                    _vm._l(_vm.localidad, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.nombre, value: item.id }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "telefono",
                    label: _vm.$t("forms_flujo.form_garante.MX.phone"),
                    error: _vm.errors.get("telefono")
                  }
                },
                [
                  _c("el-input", {
                    attrs: {
                      type: "text",
                      "auto-complete": "no",
                      placeholder: _vm.$t("forms_flujo.form_garante.MX.phone")
                    },
                    model: {
                      value: _vm.formulario_garante.telefono,
                      callback: function($$v) {
                        _vm.$set(_vm.formulario_garante, "telefono", $$v)
                      },
                      expression: "formulario_garante.telefono"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "telefono_empresa",
                    label: _vm.$t("forms_flujo.form_garante.MX.company_phone"),
                    error: _vm.errors.get("telefono_empresa")
                  }
                },
                [
                  _c("el-input", {
                    attrs: {
                      type: "text",
                      "auto-complete": "no",
                      placeholder: _vm.$t(
                        "forms_flujo.form_garante.MX.company_phone"
                      )
                    },
                    model: {
                      value: _vm.formulario_garante.telefono_empresa,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.formulario_garante,
                          "telefono_empresa",
                          $$v
                        )
                      },
                      expression: "formulario_garante.telefono_empresa"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import baseProductsAll from "./baseProductsAll";
import PzFormFlujo from "@/components/Flujo/Templates/PzFormFlujoTemplate";
export default {
  name: 'PzProductsAllSelect',
  components: {
    PzFormFlujo: PzFormFlujo
  },
  mixins: [baseProductsAll],
  data: function data() {
    return {};
  },
  methods: {}
};
import "core-js/modules/es.function.name";
import _objectSpread from "C:/xampp/htdocs/simulador_new/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import baseFormFlujo from "@/components/Flujo/Templates/baseFormFlujo";
import PzFormFlujo from "@/components/Flujo/Templates/PzFormFlujoTemplate";
import PzUnnaxFaceIdIframe from "@/commons/forms/Fields/OpenBanking/PzUnnaxFaceIdIframe";
import { getUnnaxFaceIdUrl as _getUnnaxFaceIdUrl } from "@/routes/api/views/integracion";
import { isEmpty } from '@/commons/utils/functions';
import { mapGetters } from "vuex";
export default {
  name: 'PzPersonFormEs3',
  components: {
    PzUnnaxFaceIdIframe: PzUnnaxFaceIdIframe,
    PzFormFlujo: PzFormFlujo
  },
  mixins: [baseFormFlujo],
  data: function data() {
    var _this = this;

    var checkUnnaxFaceIdComplete = function checkUnnaxFaceIdComplete(rule, value, callback) {
      if (_this.formulario1.is_unnax_face_id_completed === '') {
        return callback(new Error('El proceso UNNAX no ha concluido'));
      }

      return callback();
    };

    return {
      reference: 'formulario1',
      valid: true,
      formulario1: {
        is_unnax_face_id_completed: ''
      },
      iframe_url: '',
      rules_formulario1: {
        is_unnax_face_id_completed: [{
          validator: checkUnnaxFaceIdComplete,
          trigger: 'blur'
        }]
      }
    };
  },
  computed: _objectSpread({}, mapGetters(['form_product'])),
  mounted: function mounted() {
    var _this2 = this;

    this.getUnnaxFaceIdUrl();
    window.addEventListener("message", function (evt) {
      console.log("event", evt.data);

      if (evt.data === 'unnax:fullid:widget_success:done') {
        _this2.formulario1.is_unnax_face_id_completed = 'completed';
      }

      if (evt.data === 'unnax:fullid:start_widget:done') {
        _this2.$store.dispatch('loader/down', {
          trigger: _this2.$options.name
        });
      }
    });
  },
  methods: {
    getUnnaxFaceIdUrl: function getUnnaxFaceIdUrl() {
      var _this3 = this;

      console.log('options name', this.$options.name);
      this.$store.dispatch('loader/up', {
        trigger: this.$options.name
      });
      this.formulario1.is_unnax_face_id_completed = '';

      _getUnnaxFaceIdUrl({
        unnax_timestamp: Date.now(),
        loan: this.form_product.loan
      }).then(function (res) {
        if (res.data.valid && !isEmpty(res.data.ruta)) {
          // console.log('response', res.data)
          var ruta = res.data.ruta;
          _this3.iframe_url = ruta;
          if (ruta === true) _this3.formulario1.is_unnax_face_id_completed = 'completed';
        } else {
          var message = isEmpty(res.data.ruta) && res.data.valid ? _this3.$t('views.documentacion.vale.already_signed') : _this3.$t('views.documentacion.vale.not_route_obtain');

          _this3.$store.dispatch('app/setMessage', message);
        }
      }).catch(function (error) {
        _this3.$store.dispatch('app/catchErrors', {
          _this: _this3,
          error: error
        });
      });
    }
  }
};
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import 'element-ui/lib/theme-chalk/display.css';
import baseFormDescuentosTerceros from "./baseFormDescuentosTerceros";
import { Cedula, required, Rut } from "@/commons/utils/Rules";
import PzFormFlujo from "@/components/Flujo/Templates/PzFormFlujoTemplate";

var variables = require("@/assets/sass/variables_clients/".concat(process.env.VUE_APP_CLIENT, "/variables.json"));

export default {
  name: 'PzChequeDescuentoTerceros',
  components: {
    PzFormFlujo: PzFormFlujo
  },
  mixins: [baseFormDescuentosTerceros],
  data: function data() {
    return {
      descuentoTerceros: {
        documento_librador: '',
        serie: '',
        numero: '',
        numero_cuenta: '',
        banco_id: '',
        apellido_librador: '',
        nombre_librador: '',
        telefono_contacto: '',
        emision: ''
      },
      filds: {
        apellidos: {
          label: this.$t('forms_flujo.descuento_terceros_cheque.company_name')
        }
      },
      rules_descuentoTerceros: {
        apellido_librador: [required('')],
        nombre_librador: [required('')]
      }
    };
  },
  computed: {
    primary: function primary() {
      return variables['--color-primary'];
    },
    secondary: function secondary() {
      return variables['--color-secondary'];
    }
  },
  created: function created() {},
  mounted: function mounted() {
    this.handleLibradorType();
  },
  methods: {
    Cedula: Cedula,
    Rut: Rut,
    handleLibradorType: function handleLibradorType() {
      this.filds.apellidos.label = this.$t('forms_flujo.descuento_terceros_cheque.librador_surename');
    }
  }
};
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "pz-form-flujo",
    {
      attrs: {
        "available-captcha": _vm.extraData.withCaptcha,
        forward: _vm.handleSave,
        backwards: _vm.showBack ? _vm.handleBack : null
      },
      model: {
        value: _vm.formulario1["captcha"],
        callback: function($$v) {
          _vm.$set(_vm.formulario1, "captcha", $$v)
        },
        expression: "formulario1['captcha']"
      }
    },
    [
      _c("span", { attrs: { slot: "form_title" }, slot: "form_title" }, [
        _vm._v(" " + _vm._s(_vm.$t("forms_flujo.form1_persona.MX.title")) + " ")
      ]),
      _c(
        "el-form",
        {
          ref: _vm.reference,
          attrs: {
            slot: "formulario",
            "label-position": "top",
            model: _vm.formulario1,
            "status-icon": "",
            rules: _vm.rules_formulario1
          },
          slot: "formulario"
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                {
                  attrs: {
                    xl: { span: 12 },
                    lg: { span: 12 },
                    md: { span: 12 },
                    sm: { span: 24 },
                    xs: { span: 24 }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "identity.number",
                        label: _vm.$t(
                          "forms_flujo.form1_persona.MX.identity_doc"
                        ),
                        error: _vm.errors.get("numero_documento")
                      }
                    },
                    [
                      _c("el-input", {
                        ref: "identity.number",
                        attrs: {
                          type: "text",
                          placeholder: _vm.$t(
                            "forms_flujo.form1_persona.MX.identity_doc"
                          )
                        },
                        model: {
                          value: _vm.formulario1.identity.number,
                          callback: function($$v) {
                            _vm.$set(_vm.formulario1.identity, "number", $$v)
                          },
                          expression: "formulario1.identity.number"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                {
                  attrs: {
                    xl: { span: 12 },
                    lg: { span: 12 },
                    md: { span: 12 },
                    sm: { span: 24 },
                    xs: { span: 24 }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "telefono",
                        label: _vm.$t("forms_flujo.form1_persona.MX.telefono"),
                        error: _vm.errors.get("telefono")
                      }
                    },
                    [
                      _c("el-input", {
                        ref: "telefono",
                        attrs: {
                          type: "text",
                          placeholder: _vm.$t(
                            "forms_flujo.form1_persona.MX.telefono"
                          )
                        },
                        model: {
                          value: _vm.formulario1.telefono,
                          callback: function($$v) {
                            _vm.$set(_vm.formulario1, "telefono", $$v)
                          },
                          expression: "formulario1.telefono"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                {
                  attrs: {
                    xl: { span: 12 },
                    lg: { span: 12 },
                    md: { span: 12 },
                    sm: { span: 24 },
                    xs: { span: 24 }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "estado_civil_id",
                        label: _vm.$t(
                          "forms_flujo.form1_persona.MX.civil_state"
                        ),
                        error: _vm.errors.get("estado_civil_id")
                      }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          ref: "estado_civil_id",
                          attrs: {
                            clearable: "",
                            placeholder: _vm.$t(
                              "forms_flujo.form1_persona.MX.civil_state"
                            )
                          },
                          model: {
                            value: _vm.formulario1.estado_civil_id,
                            callback: function($$v) {
                              _vm.$set(_vm.formulario1, "estado_civil_id", $$v)
                            },
                            expression: "formulario1.estado_civil_id"
                          }
                        },
                        _vm._l(_vm.marital_status, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: {
                              label: item.estado,
                              value: item.id,
                              placeholder: _vm.$t(
                                "forms_flujo.form1_persona.MX.civil_state"
                              )
                            }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                {
                  attrs: {
                    xl: { span: 12 },
                    lg: { span: 12 },
                    md: { span: 12 },
                    sm: { span: 24 },
                    xs: { span: 24 }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "cuota_maxima",
                        label: _vm.$t(
                          "forms_flujo.form1_persona.MX.cuota_maxima"
                        ),
                        error: _vm.errors.get("cuota_maxima")
                      }
                    },
                    [
                      _c("el-input", {
                        ref: "cuota_maxima",
                        attrs: {
                          type: "text",
                          placeholder: _vm.$t(
                            "forms_flujo.form1_persona.MX.cuota_maxima"
                          )
                        },
                        model: {
                          value: _vm.formulario1.cuota_maxima,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.formulario1,
                              "cuota_maxima",
                              _vm._n($$v)
                            )
                          },
                          expression: "formulario1.cuota_maxima"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                {
                  attrs: {
                    xl: { span: 12 },
                    lg: { span: 12 },
                    md: { span: 12 },
                    sm: { span: 24 },
                    xs: { span: 24 }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "fecha_ingreso",
                        label: _vm.$t(
                          "forms_flujo.form1_persona.MX.fecha_ingreso"
                        ),
                        error: _vm.errors.get("fecha_ingreso")
                      }
                    },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "date",
                          "picker-options": _vm.pickerOptions1,
                          disabled: !!_vm.formulario1.jubilado,
                          format: "dd/MM/yyyy",
                          "value-format": "dd/MM/yyyy",
                          placeholder: _vm.$t(
                            "forms_flujo.form1_persona.MX.fecha_ingreso"
                          )
                        },
                        model: {
                          value: _vm.formulario1.fecha_ingreso,
                          callback: function($$v) {
                            _vm.$set(_vm.formulario1, "fecha_ingreso", $$v)
                          },
                          expression: "formulario1.fecha_ingreso"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                {
                  attrs: {
                    xl: { span: 12 },
                    lg: { span: 12 },
                    md: { span: 12 },
                    sm: { span: 24 },
                    xs: { span: 24 }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "destino_prestamo",
                        label: _vm.$t(
                          "forms_flujo.form1_persona.MX.destino_prestamo"
                        ),
                        error: _vm.errors.get("destino_prestamo")
                      }
                    },
                    [
                      _c("el-input", {
                        ref: "destino_prestamo",
                        attrs: {
                          type: "text",
                          placeholder: _vm.$t(
                            "forms_flujo.form1_persona.MX.destino_prestamo"
                          )
                        },
                        model: {
                          value: _vm.formulario1.destino_prestamo,
                          callback: function($$v) {
                            _vm.$set(_vm.formulario1, "destino_prestamo", $$v)
                          },
                          expression: "formulario1.destino_prestamo"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                {
                  attrs: {
                    xl: { span: 12 },
                    lg: { span: 12 },
                    md: { span: 12 },
                    sm: { span: 24 },
                    xs: { span: 24 }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "ingreso_mensual",
                        label: _vm.$t(
                          "forms_flujo.form1_persona.MX.ingreso_mensual"
                        ),
                        error: _vm.errors.get("ingreso_mensual")
                      }
                    },
                    [
                      _c("el-input", {
                        ref: "ingreso_mensual",
                        attrs: {
                          type: "text",
                          placeholder: _vm.$t(
                            "forms_flujo.form1_persona.MX.ingreso_mensual"
                          )
                        },
                        model: {
                          value: _vm.formulario1.ingreso_mensual,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.formulario1,
                              "ingreso_mensual",
                              _vm._n($$v)
                            )
                          },
                          expression: "formulario1.ingreso_mensual"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [_c("el-col", { attrs: { span: 24 } }, [_c("pz-check-form")], 1)],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "pz-terms-and-conditions",
                    {
                      attrs: {
                        prop: "termYCond",
                        default: _vm.formulario1.termYCond
                      },
                      model: {
                        value: _vm.formulario1.termYCond,
                        callback: function($$v) {
                          _vm.$set(_vm.formulario1, "termYCond", $$v)
                        },
                        expression: "formulario1.termYCond"
                      }
                    },
                    [
                      _c("div", [
                        _vm._v(_vm._s(_vm.producto.termsAndConditions))
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
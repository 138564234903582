import _objectSpread from "C:/xampp/htdocs/simulador_new/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { required } from "@/commons/utils/Rules";
import baseform2 from '../baseForm2';
import PzFormFlujo from "@/components/Flujo/Templates/PzFormFlujoTemplate";
import PzDirection from "@/commons/forms/Fields/PzDirection";
import { mapGetters } from "vuex";
export default {
  name: 'PzPersonFormExtImpacta',
  components: {
    PzFormFlujo: PzFormFlujo,
    PzDirection: PzDirection
  },
  mixins: [baseform2],
  data: function data() {
    return {
      data_tipo_tienda: [{
        id: 'propia',
        name: this.$t('forms_flujo.form2_persona.SP.propia')
      }, {
        id: 'alquilada',
        name: this.$t('forms_flujo.form2_persona.SP.alquilada')
      }, {
        id: 'familiar',
        name: this.$t('forms_flujo.form2_persona.SP.familiar')
      }, {
        id: 'trabajador',
        name: this.$t('forms_flujo.form2_persona.SP.trabajador')
      }],
      data_tiempo_tienda: [{
        id: 'meses',
        name: this.$t('forms_flujo.form2_persona.SP.meses')
      }, {
        id: 'anio',
        name: this.$t('forms_flujo.form2_persona.SP.anio')
      }],
      data_producto: [{
        id: 'Si'
      }, {
        id: 'No'
      }],
      data_energia_electrica: [{
        id: 'EGGSA',
        name: this.$t('forms_flujo.form1_persona.UY.EGGSA')
      }, {
        id: 'ENERGUATE',
        name: this.$t('forms_flujo.form1_persona.UY.ENERGUATE')
      }, {
        id: 'Otros',
        name: this.$t('forms_flujo.form1_persona.UY.otros')
      }],
      formulario2: {
        info_extra: {
          ingreso_tienda: '',
          gasto_tienda: '',
          tipo_tienda: '',
          patente: '',
          nombre_tienda: '',
          producto_nestle: '',
          energia_electrica: '',
          contador: '',
          correlativo: ''
        },
        direccion: {},
        nro_tiempo_tienda: '',
        tiempo_tienda: ''
      },
      rules_formulario2: {
        info_extra: {
          ingreso_tienda: [required('')],
          gasto_tienda: [required('')],
          tipo_tienda: [required('')],
          producto_nestle: [required('')]
        }
      }
    };
  },
  computed: _objectSpread(_objectSpread({}, mapGetters(['applicant'])), {}, {
    isnestle: function isnestle() {
      return !(this.formulario2.info_extra.producto_nestle && this.formulario2.info_extra.producto_nestle == 'No');
    }
  }),
  created: function created() {},
  methods: {}
};
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import 'element-ui/lib/theme-chalk/display.css';
export default {
  name: 'PzCheckForm',
  components: {},
  props: {
    clearing: {
      type: Boolean,
      default: false
    }
  },
  inject: ['myForm'],
  data: function data() {
    return {
      centerDialogVisible: false
    };
  },
  created: function created() {},
  mounted: function mounted() {},
  methods: {}
};
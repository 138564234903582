var render, staticRenderFns
import script from "./baseForm1.vue?vue&type=script&lang=js&"
export * from "./baseForm1.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\xampp\\htdocs\\simulador_new\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('36ad0754')) {
      api.createRecord('36ad0754', component.options)
    } else {
      api.reload('36ad0754', component.options)
    }
    
  }
}
component.options.__file = "src/components/Flujo/Formularios/Formularios1/baseForm1.vue"
export default component.exports
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "pz-simulators-template",
    [
      _c(
        "el-form",
        {
          ref: _vm.reference,
          staticStyle: { "margin-top": "3%" },
          attrs: {
            slot: "simulador",
            "status-icon": "",
            model: _vm.form,
            rules: _vm.rules_formulario1,
            "label-position": "top"
          },
          slot: "simulador"
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                {
                  attrs: {
                    xl: { span: 12 },
                    lg: { span: 12 },
                    md: { span: 12 },
                    sm: { span: 24 },
                    xs: { span: 24 }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "valor_vehiculo",
                        error: _vm.errors.get("valor_vehiculo"),
                        label: _vm.$t(
                          "forms_flujo.SimulatorVehiculo.valor_vehiculo"
                        )
                      }
                    },
                    [
                      _c("money", {
                        directives: [
                          {
                            name: "money",
                            rawName: "v-money",
                            value: _vm.money,
                            expression: "money"
                          }
                        ],
                        ref: "valor_vehiculo",
                        staticClass: "el-input__inner",
                        attrs: {
                          placeholder: _vm.$t(
                            "forms_flujo.SimulatorVehiculo.valor_vehiculoGs"
                          )
                        },
                        nativeOn: {
                          blur: function($event) {
                            return _vm.$nextTick(_vm.emitChange)
                          }
                        },
                        model: {
                          value: _vm.form.valor_vehiculo,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "valor_vehiculo", $$v)
                          },
                          expression: "form.valor_vehiculo"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                {
                  attrs: {
                    xl: { span: 12 },
                    lg: { span: 12 },
                    md: { span: 12 },
                    sm: { span: 24 },
                    xs: { span: 24 }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "entrega_inicial",
                        error: _vm.errors.get("entrega_inicial"),
                        label: _vm.$t(
                          "forms_flujo.SimulatorVehiculo.entrega_inicial"
                        )
                      }
                    },
                    [
                      _c("money", {
                        directives: [
                          {
                            name: "money",
                            rawName: "v-money",
                            value: _vm.money,
                            expression: "money"
                          }
                        ],
                        ref: "entrega_inicial",
                        staticClass: "el-input__inner",
                        attrs: {
                          placeholder: _vm.$t(
                            "forms_flujo.SimulatorVehiculo.entrega_inicialGs"
                          )
                        },
                        nativeOn: {
                          blur: function($event) {
                            return _vm.$nextTick(_vm.emitChange)
                          }
                        },
                        model: {
                          value: _vm.form.entrega_inicial,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "entrega_inicial", $$v)
                          },
                          expression: "form.entrega_inicial"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                {
                  attrs: {
                    xl: { span: 12 },
                    lg: { span: 12 },
                    md: { span: 12 },
                    sm: { span: 24 },
                    xs: { span: 24 }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "valor_seguro",
                        error: _vm.errors.get("valor_seguro"),
                        label: _vm.$t(
                          "forms_flujo.SimulatorVehiculo.valor_seguro"
                        )
                      }
                    },
                    [
                      _c("money", {
                        directives: [
                          {
                            name: "money",
                            rawName: "v-money",
                            value: _vm.money,
                            expression: "money"
                          }
                        ],
                        ref: "valor_seguro",
                        staticClass: "el-input__inner",
                        attrs: {
                          placeholder: _vm.$t(
                            "forms_flujo.SimulatorVehiculo.valor_seguroGs"
                          )
                        },
                        model: {
                          value: _vm.form.valor_seguro,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "valor_seguro", $$v)
                          },
                          expression: "form.valor_seguro"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                {
                  attrs: {
                    xl: { span: 12 },
                    lg: { span: 12 },
                    md: { span: 12 },
                    sm: { span: 24 },
                    xs: { span: 24 }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "valor_transferencia",
                        error: _vm.errors.get("valor_transferencia"),
                        label: _vm.$t(
                          "forms_flujo.SimulatorVehiculo.valor_transferencia"
                        )
                      }
                    },
                    [
                      _c("money", {
                        directives: [
                          {
                            name: "money",
                            rawName: "v-money",
                            value: _vm.money,
                            expression: "money"
                          }
                        ],
                        ref: "valor_transferencia",
                        staticClass: "el-input__inner",
                        attrs: {
                          placeholder: _vm.$t(
                            "forms_flujo.SimulatorVehiculo.valor_transferenciaGs"
                          )
                        },
                        model: {
                          value: _vm.form.valor_transferencia,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "valor_transferencia", $$v)
                          },
                          expression: "form.valor_transferencia"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                {
                  attrs: {
                    xl: { span: 12 },
                    lg: { span: 12 },
                    md: { span: 12 },
                    sm: { span: 24 },
                    xs: { span: 24 }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "valor_prestamo",
                        error: _vm.errors.get("valor_prestamo"),
                        label: _vm.$t(
                          "forms_flujo.SimulatorVehiculo.valor_prestamo"
                        )
                      }
                    },
                    [
                      _c("el-input", {
                        ref: "valor_prestamo",
                        attrs: {
                          type: "text",
                          placeholder: _vm.$t(
                            "forms_flujo.SimulatorVehiculo.valor_prestamo"
                          )
                        },
                        model: {
                          value: _vm.valor_prestamo,
                          callback: function($$v) {
                            _vm.valor_prestamo = _vm._n($$v)
                          },
                          expression: "valor_prestamo"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                {
                  attrs: {
                    xl: { span: 12 },
                    lg: { span: 12 },
                    md: { span: 12 },
                    sm: { span: 24 },
                    xs: { span: 24 }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "linea_otorgar",
                        error: _vm.errors.get("linea_otorgar"),
                        label: _vm.$t(
                          "forms_flujo.SimulatorVehiculo.linea_otorgar"
                        )
                      }
                    },
                    [
                      _c("el-input", {
                        ref: "valor_linea_otorgar",
                        attrs: {
                          type: "text",
                          placeholder: _vm.$t(
                            "forms_flujo.SimulatorVehiculo.linea_otorgar"
                          )
                        },
                        model: {
                          value: _vm.valor_linea_otorgar,
                          callback: function($$v) {
                            _vm.valor_linea_otorgar = _vm._n($$v)
                          },
                          expression: "valor_linea_otorgar"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                {
                  attrs: {
                    xl: { span: 12 },
                    lg: { span: 12 },
                    md: { span: 12 },
                    sm: { span: 24 },
                    xs: { span: 24 }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "plazo",
                        error: _vm.errors.get("plazo"),
                        label: _vm.$t("forms_flujo.SimulatorVehiculo.plazo")
                      }
                    },
                    [
                      _c("el-input-number", {
                        attrs: {
                          step: _vm.producto.step_cuota,
                          min: _vm.producto.min_cuota,
                          max: _vm.producto.max_cuota,
                          "step-strictly": ""
                        },
                        on: {
                          change: function($event) {
                            return _vm.$nextTick(_vm.emitChange)
                          },
                          input: _vm.handleInput
                        },
                        model: {
                          value: _vm.form.plazo,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "plazo", $$v)
                          },
                          expression: "form.plazo"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("pz-oferta-" + _vm.elOfertaView, { tag: "comment" })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import "core-js/modules/es.array.for-each";
import "core-js/modules/es.function.name";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.string.iterator";
import "core-js/modules/web.dom-collections.for-each";
import "core-js/modules/web.dom-collections.iterator";
import "core-js/modules/web.url";
import _objectSpread from "C:/xampp/htdocs/simulador_new/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import iFrameResize from 'iframe-resizer/js/iframeResizer';
import { mapGetters } from "vuex";
export default {
  name: "PzYousign",
  props: {
    route: {
      require: true,
      default: null,
      type: [String, URL]
    },
    redirect: {
      require: true,
      default: 'Prestamos',
      type: [String]
    }
  },
  data: function data() {
    return {
      frame_route: this.route,
      height: '600px'
    };
  },
  computed: _objectSpread({}, mapGetters(['alias'])),
  watch: {
    route: function route(value) {
      this.frame_route = value;
    },
    frame_route: function frame_route(value) {
      console.log('change form route');
      console.log(value);
    }
  },
  created: function created() {
    this.$store.dispatch('loader/up', {
      trigger: this.$options.name
    });
  },
  methods: {
    onMessage: function onMessage(messageData) {
      var _this = this;

      var message = JSON.parse(messageData.message);
      this.$store.dispatch('loader/up', {
        trigger: this.$options.name + '.onMessage'
      });
      this.parseMessages(message).finally(function () {
        _this.$store.dispatch('loader/down', {
          trigger: _this.$options.name + '.onMessage'
        });
      });
    },
    parseMessages: function parseMessages(message) {
      var _this2 = this;

      return new Promise(function (resolve) {
        switch (message.t) {
          case 'ready':
            console.log('ready');
            resolve();
            break;

          case 'close':
            _this2.$router.push({
              name: _this2.redirect
            });

            resolve();
            break;

          default:
            console.warn('[PZ Warn][yousing]Event "' + message.t + '" is not define.');
            resolve();
            break;
        }
      });
    },
    onResize: function onResize(messageData) {
      this.height = messageData.height + 'px';
    },
    change: function change(mutations) {
      mutations.forEach(function (mutation) {
        console.log(mutation);
        console.log(mutation.type);

        if (mutation.type === 'src') {
          console.log('src');
        }
      });
    },
    iframeLoaded: function iframeLoaded() {
      iFrameResize({
        log: false,
        checkOrigin: false,
        scrolling: 'no',
        messageCallback: this.onMessage,
        resizedCallback: this.onResize
      }, '#yousing');
      this.$store.dispatch('loader/down', {
        trigger: this.$options.name
      });
    }
  }
};
import "core-js/modules/es.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import PzTitle from "@/commons/titles/PzTitle";
import { isEmpty } from "@/commons/utils/functions";
export default {
  name: "PzFormFlujo",
  components: {
    PzTitle: PzTitle
  },
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    availableCaptcha: {
      type: Boolean,
      default: false
    },
    showBackward: {
      type: Boolean,
      default: false
    },
    backwards: {
      type: Function,
      default: null
    },
    forward: {
      type: Function,
      required: true
    },
    nameforward: {
      type: String,
      default: null
    }
  },
  data: function data() {
    return {
      captchaToken: ''
    };
  },
  computed: {
    showBack: function showBack() {
      return !isEmpty(this.backwards) || this.showBackward;
    },
    nameButton: function nameButton() {
      return isEmpty(this.nameforward) ? '' : this.nameforward;
    }
  },
  watch: {
    captchaToken: function captchaToken(value) {
      if (this.availableCaptcha) this.$emit('input', value);
    },
    value: function value(_value) {
      if (_value === this.captchaToken) return;
      this.captchaToken = _value;
    }
  },
  methods: {
    handleBack: function handleBack() {
      if (!isEmpty(this.backwards)) return this.backwards();
      if (this.showBackward) return this.$router.go(-1);
    },
    handleForward: function handleForward() {
      return this.forward();
    }
  }
};
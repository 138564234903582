import "core-js/modules/es.function.name";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.string.iterator";
import "core-js/modules/web.dom-collections.iterator";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { required, EspacioBlanco, type } from "@/commons/utils/Rules";
import { getEstadoCivil } from "@/routes/api/resources";
import 'element-ui/lib/theme-chalk/display.css';
import PzBaseForm1 from "@/components/Flujo/Formularios/Formularios1/baseForm1";
import PzFormFlujo from "@/components/Flujo/Templates/PzFormFlujoTemplate";
import PzIdentityDocument from "@/commons/forms/Fields/PzIdentityDocument";
export default {
  name: 'PzPersonForm1Impacta',
  components: {
    PzFormFlujo: PzFormFlujo,
    PzIdentityDocument: PzIdentityDocument
  },
  mixins: [PzBaseForm1],
  data: function data() {
    return {
      genero: [{
        id: 'f',
        name: this.$t('forms_flujo.form1_persona.SP.femenino')
      }, {
        id: 'm',
        name: this.$t('forms_flujo.form1_persona.SP.masculino')
      }, {
        id: 'o',
        name: this.$t('forms_flujo.form1_persona.SP.otro')
      }],
      data_datos_facturacion: [{
        id: 'f',
        name: this.$t('forms_flujo.form1_persona.SP.indentificacion_tributaria')
      }, {
        id: 'm',
        name: this.$t('forms_flujo.form1_persona.SP.consumidor_final')
      }],
      marital_status: [],
      formulario1: {
        nombres: '',
        apellidos: '',
        estado_civil_id: '',
        fecha_nacimiento: '',
        genero: '',
        telefono: '',
        info_extra: {
          datos_facturacion: '',
          tienes_cuentab: null,
          nro_indentificacion_tributaria: ''
        },
        familiar: {
          nombre_completo: '',
          telefono: ''
        },
        email: '',
        identity: {
          document: 1
        }
      },
      view_sucursal: false,
      rules_formulario1: {
        'identity.document': [required('')],
        'identity.number': [required(''), EspacioBlanco()],
        nombres: [required(''), EspacioBlanco()],
        apellidos: [required(''), EspacioBlanco()],
        estado_civil_id: [required('')],
        fecha_nacimiento: [required('')],
        info_extra: {
          tienes_cuentab: [required('')],
          datos_facturacion: [required('')]
        },
        genero: [required('')],
        cuota_maxima: [required('')],
        email: [required(''), type({
          field: '',
          type: 'email'
        })],
        telefono: [required('')],
        familiar: {
          nombre_completo: [required('')],
          telefono: [required('')]
        },
        'familiar.nombre_completo': [required('')],
        'familiar.telefono': [required('')]
      },
      pickerOptions1: {
        disabledDate: function disabledDate(time) {
          return Date.now() < time.getTime();
        }
      }
    };
  },
  created: function created() {
    this.fetchData();
  },
  methods: {
    fetchData: function fetchData() {
      var _this = this;

      this.$store.dispatch('loader/up', {
        trigger: this.$options.name
      });
      var estado = getEstadoCivil().then(function (res) {
        _this.marital_status = res.data;
      }).catch(function () {
        _this.marital_status = [];
      });
      return Promise.all([estado]).then(function () {}).finally(function () {
        _this.$store.dispatch('loader/down', {
          trigger: _this.$options.name
        });
      });
    }
  }
};
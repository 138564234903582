import "core-js/modules/es.function.name";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.regexp.constructor";
import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.regexp.to-string";
import "core-js/modules/es.string.iterator";
import "core-js/modules/web.dom-collections.iterator";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { required, EspacioBlanco } from "@/commons/utils/Rules";
import 'element-ui/lib/theme-chalk/display.css';
import PzBaseForm1 from "@/components/Flujo/Formularios/Formularios1/baseForm1";
import PzFormFlujo from "@/components/Flujo/Templates/PzFormFlujoTemplate";
import PzIdentityDocument from "@/commons/forms/Fields/PzIdentityDocument";
import { getRelacionesLaborales } from "@/routes/api/resources";
import { validateContact, validateNames } from "@/routes/api/resources";
export default {
  name: 'PzPersonForm1Bnf',
  components: {
    PzFormFlujo: PzFormFlujo,
    PzIdentityDocument: PzIdentityDocument
  },
  mixins: [PzBaseForm1],
  data: function data() {
    var _this = this;

    var check_Fecha = function check_Fecha(rule, value, callback) {
      localStorage.setItem('isjubilado', true);
      if (_this.formulario1.relacion_laboral_id != 4) localStorage.setItem('isjubilado', false);

      if (_this.formulario1.relacion_laboral_id != 4 && !value) {
        return callback(new Error(_this.$t('rules.incomplete')));
      }

      return callback();
    };

    var check_telefono = function check_telefono(rule, value, callback) {
      var regla = "^[+]?[0-9]{10}$";
      var pattern = new RegExp(regla.toString());

      if (!pattern.test(value)) {
        return callback(new Error(_this.$t('rules.phone_rule')));
      } else {
        validateContact(null, _this.formulario1.telefono, _this.formulario1.identity.number).then(function (res) {
          if (res.data.phone_exists) {
            return callback(new Error(_this.$t('rules.phone_exists')));
          } else {
            return callback();
          }
        }).catch(function () {
          return callback(new Error(_this.$t('rules.phone_exists')));
        });
      }
    };

    var check_name = function check_name(rule, value, callback) {
      if (!value) {
        return callback(new Error(_this.$t('rules.name_required')));
      } else {
        validateNames(1, _this.formulario1.nombres, _this.formulario1.identity.number).then(function (res) {
          if (!res.data.correct) {
            return callback(new Error(_this.$t('rules.name_rule')));
          } else {
            return callback();
          }
        }).catch(function () {
          return callback(new Error(_this.$t('rules.name_rule')));
        });
      }
    };

    var check_lastname = function check_lastname(rule, value, callback) {
      if (!value) {
        return callback(new Error(_this.$t('rules.lastname_required')));
      } else {
        validateNames(2, _this.formulario1.apellidos, _this.formulario1.identity.number).then(function (res) {
          if (!res.data.correct) {
            return callback(new Error(_this.$t('rules.lastname_rule')));
          } else {
            return callback();
          }
        }).catch(function () {
          return callback(new Error(_this.$t('rules.lastname_rule')));
        });
      }
    };

    return {
      dialogVisible: false,
      reference: 'formulario1',
      marital_status: [],
      sucursales: [],
      relacion_laboral: [],
      tipo_prestamo: [{
        id: 'si',
        name: 'Si'
      }, {
        id: 'no',
        name: 'No'
      }],
      formulario1: {
        nombres: '',
        apellidos: '',
        telefono: '',
        fecha_nacimiento: '',
        identity: {
          document: 1
        },
        type_prestamo: '',
        consulta_clearing: 0,
        consulta_bcu: 0,
        fecha_ingreso: '',
        relacion_laboral_id: '',
        cancelar_prestamos: ''
      },
      view_sucursal: false,
      rules_formulario1: {
        'identity.document': [required('')],
        'identity.number': [required(''), EspacioBlanco()],
        nombres: [{
          validator: check_name,
          trigger: 'blur'
        }],
        apellidos: [{
          validator: check_lastname,
          trigger: 'blur'
        }],
        telefono: [{
          validator: check_telefono,
          trigger: 'blur'
        }],
        fecha_nacimiento: [required('')],
        fecha_ingreso: [{
          validator: check_Fecha,
          trigger: 'blur'
        }],
        cancelar_prestamos: [required('')],
        relacion_laboral_id: [required('')]
      },
      pickerOptions1: {
        disabledDate: function disabledDate(time) {
          return Date.now() < time.getTime();
        }
      }
    };
  },
  computed: {
    isjubilado: function isjubilado() {
      return this.formulario1.relacion_laboral_id && this.formulario1.relacion_laboral_id == '4';
    },
    condocumento: function condocumento() {
      var docmun = localStorage.getItem('docnum');

      if (docmun) {
        return true;
      }

      return false;
    }
  },
  created: function created() {
    var _this2 = this;

    this.$store.dispatch('loader/up', {
      trigger: this.$options.name
    });
    var docmun = localStorage.getItem('docnum');
    if (docmun) this.formulario1.identity.number = docmun;
    var re = getRelacionesLaborales().then(function (res) {
      _this2.relacion_laboral = res.data;
    }).catch(function () {
      _this2.relacion_laboral = [];
    });
    Promise.all([re]).then(function () {}).finally(function () {
      _this2.$store.dispatch('loader/down', {
        trigger: _this2.$options.name
      });
    });
  },
  methods: {
    modelDialog: function modelDialog(value) {
      if (value === 'si') this.dialogVisible = true;
    },
    closeDialog: function closeDialog() {
      this.dialogVisible = false;
    }
  }
};
var profesion = [{
  id: '10',
  name: 'AGRIMENSOR     '
}, {
  id: '11',
  name: 'AGRONOMO       '
}, {
  id: '17',
  name: 'ANALISTA DE SIS'
}, {
  id: '18',
  name: 'ANALISTA INDUST'
}, {
  id: '20',
  name: 'ANTROPOLOGO    '
}, {
  id: '22',
  name: 'APICULTOR      '
}, {
  id: '24',
  name: 'ARCHIVISTA O AR'
}, {
  id: '26',
  name: 'ARQUEOLOGO     '
}, {
  id: '27',
  name: 'ARQUITECTO     '
}, {
  id: '32',
  name: 'ARTISTA        '
}, {
  id: '38',
  name: 'ASTRONOMO      '
}, {
  id: '40',
  name: 'AUDIO PROTESIST'
}, {
  id: '45',
  name: 'PILOTO CIVIL   '
}, {
  id: '46',
  name: 'PILOTO MILITAR '
}, {
  id: '47',
  name: 'AVICULTOR      '
}, {
  id: '52',
  name: 'AZAFATA        '
}, {
  id: '54',
  name: 'ARTES PLASTICAS'
}, {
  id: '56',
  name: 'ACTRIZ         '
}, {
  id: '59',
  name: 'ASTILLERO      '
}, {
  id: '61',
  name: 'ANALISTA QUIMIC'
}, {
  id: '65',
  name: 'BACTERIOLOGO   '
}, {
  id: '70',
  name: 'BAILARIN       '
}, {
  id: '74',
  name: 'BIBLIOTECARIO  '
}, {
  id: '75',
  name: 'BIOLOGO        '
}, {
  id: '76',
  name: 'BIOQUIMICO     '
}, {
  id: '82',
  name: 'BOTANICO       '
}, {
  id: '98',
  name: 'CALIGRAFO      '
}, {
  id: '116',
  name: 'CARTOGRAFO     '
}, {
  id: '117',
  name: 'CATEDRATICO UNI'
}, {
  id: '127',
  name: 'CIRUJANO       '
}, {
  id: '128',
  name: 'CLINICO        '
}, {
  id: '132',
  name: 'COMENTARISTA   '
}, {
  id: '135',
  name: 'COMPONEDOR     '
}, {
  id: '136',
  name: 'COMPOSITOR     '
}, {
  id: '143',
  name: 'CONTADOR PUBLIC'
}, {
  id: '159',
  name: 'CAMAROGRAFO    '
}, {
  id: '160',
  name: 'CRUPIER        '
}, {
  id: '166',
  name: 'DECLAMADOR     '
}, {
  id: '167',
  name: 'DECORADOR      '
}, {
  id: '168',
  name: 'DENTISTA       '
}, {
  id: '172',
  name: 'DIAGRAMADOR    '
}, {
  id: '180',
  name: 'DOCENTE        '
}, {
  id: '182',
  name: 'DR. EN ANTROPOL'
}, {
  id: '183',
  name: 'DR. CIENCIAS DE'
}, {
  id: '184',
  name: 'DR. EN CIENCIAS'
}, {
  id: '185',
  name: 'DR. EN CIENCIAS'
}, {
  id: '186',
  name: 'DR. EN CIENCIAS'
}, {
  id: '187',
  name: 'DR. EN CONTABIL'
}, {
  id: '188',
  name: 'DR. EN DERECHO '
}, {
  id: '189',
  name: 'DR. EN CIENCIAS'
}, {
  id: '190',
  name: 'DR. EN FILOSOFI'
}, {
  id: '191',
  name: 'DR. EN HISTORIA'
}, {
  id: '192',
  name: 'DR. EN LETRAS  '
}, {
  id: '193',
  name: 'DR. EN MATEMATI'
}, {
  id: '194',
  name: 'DR. EN PEDAGOGI'
}, {
  id: '195',
  name: 'DR. EN PSICOLOG'
}, {
  id: '196',
  name: 'DR. EN QUIMICA '
}, {
  id: '197',
  name: 'DR. EN SOCIOLOG'
}, {
  id: '200',
  name: 'ACUICULTOR     '
}, {
  id: '205',
  name: 'BIBLIOTECOLOGO '
}, {
  id: '208',
  name: 'GEOGRAFO       '
}, {
  id: '211',
  name: 'EBANISTA       '
}, {
  id: '212',
  name: 'ECONOMISTA     '
}, {
  id: '213',
  name: 'EDITOR         '
}, {
  id: '214',
  name: 'EDUCADOR       '
}, {
  id: '215',
  name: 'EDUCADOR AUDIOV'
}, {
  id: '221',
  name: 'ELECTROTERAPEUT'
}, {
  id: '222',
  name: 'EMBALSAMADOR O '
}, {
  id: '229',
  name: 'EMPRESARIO-EJEC'
}, {
  id: '233',
  name: 'ENFERMERO      '
}, {
  id: '237',
  name: 'ESCRIBANO PUBLI'
}, {
  id: '238',
  name: 'ESCRITOR       '
}, {
  id: '239',
  name: 'ESCULTOR       '
}, {
  id: '240',
  name: 'ESTADISTICO O E'
}, {
  id: '242',
  name: 'ESTIBADOR      '
}, {
  id: '244',
  name: 'EVALUADOR EDUCA'
}, {
  id: '245',
  name: 'ESTILISTA      '
}, {
  id: '249',
  name: 'PETROQUIMICO   '
}, {
  id: '251',
  name: 'REDACTOR       '
}, {
  id: '257',
  name: 'FARMACOLOGO    '
}, {
  id: '260',
  name: 'FILOLOGO       '
}, {
  id: '261',
  name: 'FILOSOFO       '
}, {
  id: '262',
  name: 'FISICO         '
}, {
  id: '263',
  name: 'FISIOTERAPEUTAS'
}, {
  id: '264',
  name: 'FITOPATOLOGO   '
}, {
  id: '265',
  name: 'FLORICULTOR    '
}, {
  id: '267',
  name: 'FOGONERO       '
}, {
  id: '270',
  name: 'FRUTICULTOR    '
}, {
  id: '283',
  name: 'GASOGENISTA    '
}, {
  id: '284',
  name: 'GEOLOGO        '
}, {
  id: '285',
  name: 'GINECOLOGO     '
}, {
  id: '287',
  name: 'GRABOVERIFICADO'
}, {
  id: '288',
  name: 'GRAFICO        '
}, {
  id: '294',
  name: 'GASTRONOMICO   '
}, {
  id: '295',
  name: 'GUIA TURISTICO '
}, {
  id: '304',
  name: 'HISTORIADOR    '
}, {
  id: '306',
  name: 'HOMEOPATA      '
}, {
  id: '307',
  name: 'HORMERO        '
}, {
  id: '309',
  name: 'HORTICULTOR    '
}, {
  id: '316',
  name: 'IDONEO DENTISTA'
}, {
  id: '317',
  name: 'IDONEO FARMACEU'
}, {
  id: '318',
  name: 'IDONEO EN OBSTE'
}, {
  id: '319',
  name: 'IDONEO VETERINA'
}, {
  id: '322',
  name: 'INGENIERO      '
}, {
  id: '323',
  name: 'INGENIERO AGRON'
}, {
  id: '324',
  name: 'INGENIERO CIVIL'
}, {
  id: '325',
  name: 'INGENIERO DE MI'
}, {
  id: '326',
  name: 'INGENIERO DE PR'
}, {
  id: '327',
  name: 'INGENIERO ELECT'
}, {
  id: '328',
  name: 'INGENIERO ELECT'
}, {
  id: '329',
  name: 'INGENIERO FORES'
}, {
  id: '330',
  name: 'INGENIERO GEOLO'
}, {
  id: '331',
  name: 'INGENIERO INDUS'
}, {
  id: '332',
  name: 'INGENIERO MECAN'
}, {
  id: '333',
  name: 'INGENIERO METAL'
}, {
  id: '334',
  name: 'INGENIERO QUIMI'
}, {
  id: '335',
  name: 'INSEMINADOR    '
}, {
  id: '336',
  name: 'INGENIERO NAVAL'
}, {
  id: '337',
  name: 'INGENIERO ELECT'
}, {
  id: '338',
  name: 'INGENIERO HIDRA'
}, {
  id: '339',
  name: 'IQUEBANISTA    '
}, {
  id: '340',
  name: 'INGENIERO EN TE'
}, {
  id: '341',
  name: 'INGENIERO BIOME'
}, {
  id: '356',
  name: 'KINESIOLOGO    '
}, {
  id: '361',
  name: 'LABORATORISTA  '
}, {
  id: '365',
  name: 'LIC. EN ADMINIS'
}, {
  id: '366',
  name: 'LIC. EN ANALISI'
}, {
  id: '367',
  name: 'LIC. EN BIBLIOT'
}, {
  id: '368',
  name: 'LIC. EN CIENCIA'
}, {
  id: '369',
  name: 'LIC. EN CIENCIA'
}, {
  id: '370',
  name: 'LIC. EN C.NATUR'
}, {
  id: '371',
  name: 'LIC. EN CIENCIA'
}, {
  id: '372',
  name: 'LIC. EN CONTABI'
}, {
  id: '373',
  name: 'LIC. EN DIPLOMA'
}, {
  id: '374',
  name: 'LIC. EN ENFERME'
}, {
  id: '375',
  name: 'LIC. EN FILOSOF'
}, {
  id: '376',
  name: 'LIC. EN GEOLOGI'
}, {
  id: '377',
  name: 'LIC. EN HISTORI'
}, {
  id: '378',
  name: 'LIC. EN LENGUA '
}, {
  id: '379',
  name: 'LIC. EN LENGUA '
}, {
  id: '380',
  name: 'LIC. EN LETRAS '
}, {
  id: '381',
  name: 'LIC. EN MATEMAT'
}, {
  id: '382',
  name: 'LIC. EN OBSTETR'
}, {
  id: '383',
  name: 'LIC. EN PEDAGOG'
}, {
  id: '384',
  name: 'LIC. EN PERIODI'
}, {
  id: '385',
  name: 'LIC. EN PSICOLO'
}, {
  id: '386',
  name: 'LIC. EN RELACIO'
}, {
  id: '387',
  name: 'LIC. EN SERVICI'
}, {
  id: '388',
  name: 'LIC. EN SOCIOLO'
}, {
  id: '389',
  name: 'LIC. EN CONTABI'
}, {
  id: '390',
  name: 'LINOTIPISTA    '
}, {
  id: '393',
  name: 'LITOGRAFO      '
}, {
  id: '396',
  name: 'LIC. EN HUMANID'
}, {
  id: '397',
  name: 'LIC. EN TECNOLO'
}, {
  id: '398',
  name: 'LIC. EN CIENCIA'
}, {
  id: '404',
  name: 'MAESTRO NORMAL '
}, {
  id: '409',
  name: 'MATEMATICO     '
}, {
  id: '416',
  name: 'MEDICO         '
}, {
  id: '417',
  name: 'MEDICO CIRUJANO'
}, {
  id: '418',
  name: 'MEDICO VETERINA'
}, {
  id: '419',
  name: 'METALURGICO    '
}, {
  id: '420',
  name: 'METEOROLOGO    '
}, {
  id: '421',
  name: 'MILITAR        '
}, {
  id: '423',
  name: 'MINERVISTA     '
}, {
  id: '428',
  name: 'MOTORMAN       '
}, {
  id: '442',
  name: 'NATURALISTA    '
}, {
  id: '443',
  name: 'NUTRIOLOGO O NU'
}, {
  id: '453',
  name: 'OBSTETRA       '
}, {
  id: '454',
  name: 'OCULISTA       '
}, {
  id: '455',
  name: 'ODONTOLOGO     '
}, {
  id: '466',
  name: 'OPERADOR DE PAL'
}, {
  id: '467',
  name: 'OPTICO         '
}, {
  id: '468',
  name: 'OPTOMETRO      '
}, {
  id: '471',
  name: 'ORTOPEDICO     '
}, {
  id: '472',
  name: 'OTORRINOLARINGO'
}, {
  id: '486',
  name: 'PASTOR         '
}, {
  id: '487',
  name: 'PEDAGOGO       '
}, {
  id: '493',
  name: 'PERIODISTA     '
}, {
  id: '508',
  name: 'POLICIA        '
}, {
  id: '509',
  name: 'POLIETILERO    '
}, {
  id: '516',
  name: 'PRODUCTOR DE CI'
}, {
  id: '517',
  name: 'PRODUCTOR DE RA'
}, {
  id: '518',
  name: 'PRODUCTOR DE TE'
}, {
  id: '519',
  name: 'PRODUCTOR DE TE'
}, {
  id: '520',
  name: 'PROFESOR       '
}, {
  id: '521',
  name: 'PROFESOR DE AJE'
}, {
  id: '522',
  name: 'PROF.DE ALFABET'
}, {
  id: '523',
  name: 'PROFESOR DE ART'
}, {
  id: '524',
  name: 'PROFESOR DE ART'
}, {
  id: '525',
  name: 'PROFESOR DE CAN'
}, {
  id: '526',
  name: 'PROFESOR DE DAN'
}, {
  id: '527',
  name: 'PROF. DE ED.FIS'
}, {
  id: '528',
  name: 'PROFESOR DE EDU'
}, {
  id: '529',
  name: 'PROF. DE ENS. E'
}, {
  id: '530',
  name: 'PROFESOR DE ENS'
}, {
  id: '531',
  name: 'PROF. DE LENGUA'
}, {
  id: '532',
  name: 'PROFESOR DE MUS'
}, {
  id: '533',
  name: 'PROFESOR DE NAT'
}, {
  id: '534',
  name: 'PROFESOR DE TEN'
}, {
  id: '535',
  name: 'PROFESOR NORMAL'
}, {
  id: '536',
  name: 'PROF. DE CORTE '
}, {
  id: '537',
  name: 'PROFESORA DE LA'
}, {
  id: '538',
  name: 'PROGRAMADOR DE '
}, {
  id: '540',
  name: 'PROYECTISTA    '
}, {
  id: '541',
  name: 'PSICOLOGO      '
}, {
  id: '546',
  name: 'PROF. DE MATEMA'
}, {
  id: '548',
  name: 'PIROGRABADOR   '
}, {
  id: '550',
  name: 'PISICULTOR     '
}, {
  id: '551',
  name: 'QUEHACERES DOME'
}, {
  id: '552',
  name: 'QUIMICO        '
}, {
  id: '553',
  name: 'QUIMICO FARMACE'
}, {
  id: '554',
  name: 'QUINTERO       '
}, {
  id: '555',
  name: 'QUIMICO INDUSTR'
}, {
  id: '556',
  name: 'PARASICOLOGO   '
}, {
  id: '557',
  name: 'PSIQUIATRA     '
}, {
  id: '558',
  name: 'PROF. DE EDUC. '
}, {
  id: '561',
  name: 'RADIO OPERADOR '
}, {
  id: '562',
  name: 'RADIOLOGO      '
}, {
  id: '574',
  name: 'RELIGIOSO/A    '
}, {
  id: '581',
  name: 'SACERDOTE      '
}, {
  id: '586',
  name: 'SILVICULTOR    '
}, {
  id: '589',
  name: 'SERIGRAFO      '
}, {
  id: '590',
  name: 'SILERO         '
}, {
  id: '591',
  name: 'SOCIOLOGO      '
}, {
  id: '605',
  name: 'TAQUIGRAFO     '
}, {
  id: '629',
  name: 'TECNOLOGO      '
}, {
  id: '633',
  name: 'TELETIPISTA    '
}, {
  id: '634',
  name: 'TENEDOR DE LIBR'
}, {
  id: '635',
  name: 'TEOLOGO        '
}, {
  id: '637',
  name: 'TIMONEL        '
}, {
  id: '638',
  name: 'TINTORERO      '
}, {
  id: '639',
  name: 'TIPOGRAFO      '
}, {
  id: '640',
  name: 'TONELERO       '
}, {
  id: '641',
  name: 'TOPOGRAFO      '
}, {
  id: '642',
  name: 'TORERO         '
}, {
  id: '643',
  name: 'TORNERO        '
}, {
  id: '648',
  name: 'TRAUMATOLOGO   '
}, {
  id: '650',
  name: 'TECNICO AGROMEC'
}, {
  id: '651',
  name: 'TECNICO        '
}, {
  id: '652',
  name: 'TECNICO ELECTRO'
}, {
  id: '662',
  name: 'VETERINARIO    '
}, {
  id: '665',
  name: 'VISITADOR SOCIA'
}, {
  id: '681',
  name: 'ZOOTECNICO     '
}, {
  id: '777',
  name: 'NO ESPECIFICADO'
}, {
  id: '690',
  name: 'EDAFOLOGO      '
}, {
  id: '691',
  name: 'ENOLOGO        '
}, {
  id: '693',
  name: 'INFORMATICO    '
}, {
  id: '694',
  name: 'LIC. EN CAPACIT'
}, {
  id: '437',
  name: 'MESEOLOGO      '
}, {
  id: '695',
  name: 'OCEANOGRAFO    '
}, {
  id: '696',
  name: 'POLITICO       '
}, {
  id: '438',
  name: 'AERONAUTICO    '
}, {
  id: '439',
  name: 'DESEUDADOR INDU'
}, {
  id: '440',
  name: 'HISTOLOGO      '
}, {
  id: '698',
  name: 'MARINO MERCANTE'
}, {
  id: '700',
  name: 'TURISMO        '
}, {
  id: '311',
  name: 'VIALIDAD       '
}, {
  id: '707',
  name: 'CINEMATOGRAFIA '
}, {
  id: '708',
  name: 'ELECTRONICA    '
}, {
  id: '709',
  name: 'FARMACEUTICA   '
}, {
  id: '710',
  name: 'FONOGRAFICA    '
}, {
  id: '711',
  name: 'FOTOGRAFICA    '
}, {
  id: '723',
  name: 'INGENIERO BIOEL'
}, {
  id: '724',
  name: 'INGENIERO MILIT'
}, {
  id: '725',
  name: 'INGENIERO DE SI'
}, {
  id: '726',
  name: 'LIC.ADM.AGROPEC'
}, {
  id: '727',
  name: 'MEDICO CARDIOLO'
}, {
  id: '728',
  name: 'MEDICO MILITAR '
}, {
  id: '729',
  name: 'MEDICO PEDIATRA'
}, {
  id: '999',
  name: 'ESTUDIANTE     '
}, {
  id: '1',
  name: 'ACEITERO       '
}, {
  id: '799',
  name: "PEP'S          "
}, {
  id: '2',
  name: 'ACTOR          '
}, {
  id: '5',
  name: 'ADIESTRADOR DE '
}, {
  id: '7',
  name: 'AFILADOR       '
}, {
  id: '15',
  name: 'AGRICULTOR     '
}, {
  id: '19',
  name: 'ALAMBRADOR     '
}, {
  id: '21',
  name: 'ALBAÑIL        '
}, {
  id: '23',
  name: 'ALFARERO       '
}, {
  id: '25',
  name: 'ALGODONERO     '
}, {
  id: '28',
  name: 'AMA DE CASA    '
}, {
  id: '29',
  name: 'AMA DE LLAVES  '
}, {
  id: '30',
  name: 'ANESTESISTA    '
}, {
  id: '39',
  name: 'ARTE ESCENICO  '
}, {
  id: '42',
  name: 'ASCENSORISTA   '
}, {
  id: '57',
  name: 'AUXILIAR AGRONO'
}, {
  id: '66',
  name: 'AYUDANTE ALBAÑI'
}, {
  id: '68',
  name: 'AYUDANTE MECANI'
}, {
  id: '69',
  name: 'AYUDANTE VETERI'
}, {
  id: '72',
  name: 'AZULEJISTA     '
}, {
  id: '73',
  name: 'BALDOSERO      '
}, {
  id: '81',
  name: 'BOBINADOR      '
}, {
  id: '83',
  name: 'BOLETERO       '
}, {
  id: '84',
  name: 'BOMBERO        '
}, {
  id: '85',
  name: 'BORDADOR       '
}, {
  id: '88',
  name: 'CABAÑERO       '
}, {
  id: '91',
  name: 'CALAFATEADOR   '
}, {
  id: '92',
  name: 'CALANDRADOR    '
}, {
  id: '94',
  name: 'CALDERERO      '
}, {
  id: '95',
  name: 'CALERO         '
}, {
  id: '96',
  name: 'CAMARERO       '
}, {
  id: '99',
  name: 'CAMILLERO      '
}, {
  id: '101',
  name: 'CANILLITA      '
}, {
  id: '102',
  name: 'CANOERO        '
}, {
  id: '104',
  name: 'CANTOR         '
}, {
  id: '106',
  name: 'CAPATAZ        '
}, {
  id: '109',
  name: 'CARICATURISTA  '
}, {
  id: '110',
  name: 'CARNICERO      '
}, {
  id: '113',
  name: 'CARRERO        '
}, {
  id: '115',
  name: 'CARTERO        '
}, {
  id: '120',
  name: 'CEMENTISTA     '
}, {
  id: '121',
  name: 'CERAMICO       '
}, {
  id: '122',
  name: 'CERAMISTA      '
}, {
  id: '123',
  name: 'CERRAJERO      '
}, {
  id: '124',
  name: 'CERVECERO      '
}, {
  id: '126',
  name: 'CHIPERO        '
}, {
  id: '129',
  name: 'CHOFER         '
}, {
  id: '130',
  name: 'CHOFER MECANICO'
}, {
  id: '133',
  name: 'CINEMATOGRAFO  '
}, {
  id: '134',
  name: 'CITOLOGO       '
}, {
  id: '139',
  name: 'COCINERO       '
}, {
  id: '140',
  name: 'COLCHONERO     '
}, {
  id: '147',
  name: 'CONDUCTOR      '
}, {
  id: '148',
  name: 'CONFECCIONISTA '
}, {
  id: '149',
  name: 'CONFITERO      '
}, {
  id: '155',
  name: 'CONTRATISTA    '
}, {
  id: '156',
  name: 'CONTROLADOR    '
}, {
  id: '162',
  name: 'CORTADOR       '
}, {
  id: '163',
  name: 'COSMETOLOGO    '
}, {
  id: '164',
  name: 'COSTURERO      '
}, {
  id: '165',
  name: 'CUADRILLERO    '
}, {
  id: '169',
  name: 'CURTIDOR       '
}, {
  id: '170',
  name: 'DACTILOGRAFO   '
}, {
  id: '175',
  name: 'DESOLLADOR     '
}, {
  id: '178',
  name: 'DESTILADOR     '
}, {
  id: '198',
  name: 'DIBUJANTE      '
}, {
  id: '199',
  name: 'DIBUJANTE TECNI'
}, {
  id: '201',
  name: 'DIETISTA       '
}, {
  id: '202',
  name: 'DIGITADOR      '
}, {
  id: '209',
  name: 'DISEÑADOR/A    '
}, {
  id: '216',
  name: 'DOMADOR/A      '
}, {
  id: '224',
  name: 'ELASTIQUERO    '
}, {
  id: '225',
  name: 'ELECTRICISTA   '
}, {
  id: '226',
  name: 'ELECTRICISTA DE'
}, {
  id: '227',
  name: 'ELECTROMECANICO'
}, {
  id: '228',
  name: 'ELECTROTECNICO '
}, {
  id: '230',
  name: 'EMBARCADIZO    '
}, {
  id: '231',
  name: 'EMPAPELADOR    '
}, {
  id: '241',
  name: 'ENCOFRADOR/A   '
}, {
  id: '243',
  name: 'ENCUADERNADOR/A'
}, {
  id: '246',
  name: 'ENCUESTADOR    '
}, {
  id: '247',
  name: 'ENGRASADOR     '
}, {
  id: '252',
  name: 'ESCENOGRAFO    '
}, {
  id: '253',
  name: 'ESTANCIERO     '
}, {
  id: '268',
  name: 'FIDEERO        '
}, {
  id: '274',
  name: 'FOTOGRAFO      '
}, {
  id: '275',
  name: 'FRENTISTA      '
}, {
  id: '276',
  name: 'FUMIGADOR      '
}, {
  id: '277',
  name: 'FUNDIDOR       '
}, {
  id: '279',
  name: 'GALVANIZADOR   '
}, {
  id: '289',
  name: 'GOMERO         '
}, {
  id: '290',
  name: 'GRABADOR       '
}, {
  id: '292',
  name: 'GRANITERO      '
}, {
  id: '296',
  name: 'GUARDA         '
}, {
  id: '297',
  name: 'GUARDIA        '
}, {
  id: '298',
  name: 'GUINCHERO      '
}, {
  id: '299',
  name: 'HACHERO        '
}, {
  id: '300',
  name: 'HERRERO        '
}, {
  id: '301',
  name: 'HIDROGRAFO     '
}, {
  id: '302',
  name: 'HILANDERO      '
}, {
  id: '305',
  name: 'HOJALATERO     '
}, {
  id: '308',
  name: 'HORMIGONERO    '
}, {
  id: '314',
  name: 'IMPRESOR       '
}, {
  id: '342',
  name: 'INSPECTOR      '
}, {
  id: '343',
  name: 'INSTRUCTOR     '
}, {
  id: '345',
  name: 'JABONERO       '
}, {
  id: '346',
  name: 'JARDINERO (INJE'
}, {
  id: '347',
  name: 'JOCKEY         '
}, {
  id: '348',
  name: 'JORNALERO      '
}, {
  id: '350',
  name: 'JUBILADO       '
}, {
  id: '353',
  name: 'LAMINADOR      '
}, {
  id: '354',
  name: 'LANCHERO       '
}, {
  id: '355',
  name: 'LAVANDERO      '
}, {
  id: '358',
  name: 'LETRISTA       '
}, {
  id: '360',
  name: 'LIMPIADOR/A    '
}, {
  id: '363',
  name: 'LOCUTOR        '
}, {
  id: '364',
  name: 'LUSTRADOR DE MU'
}, {
  id: '392',
  name: 'MAESTRO COCINER'
}, {
  id: '394',
  name: 'MAESTRO MAYOR D'
}, {
  id: '399',
  name: 'MANICURA       '
}, {
  id: '400',
  name: 'MANUALIDADES   '
}, {
  id: '401',
  name: 'MAQUINISTA     '
}, {
  id: '403',
  name: 'MASAJISTA      '
}, {
  id: '406',
  name: 'MAYORDOMO      '
}, {
  id: '407',
  name: 'MECANICO AUTOMO'
}, {
  id: '410',
  name: 'MECANICO DENTAL'
}, {
  id: '411',
  name: 'MECANICO INDUST'
}, {
  id: '412',
  name: 'MECANOGRAFO    '
}, {
  id: '426',
  name: 'MIMBRERO       '
}, {
  id: '427',
  name: 'MIMEOGRAFISTA  '
}, {
  id: '431',
  name: 'MODELISTA      '
}, {
  id: '432',
  name: 'MODISTA        '
}, {
  id: '433',
  name: 'MOLINERO       '
}, {
  id: '434',
  name: 'MONTADOR       '
}, {
  id: '435',
  name: 'MOSAISTA       '
}, {
  id: '436',
  name: 'MOTORISTA      '
}, {
  id: '441',
  name: 'MUEBLERO       '
}, {
  id: '444',
  name: 'MUSICO         '
}, {
  id: '447',
  name: 'OBRAJERO       '
}, {
  id: '448',
  name: 'OBRERO         '
}, {
  id: '450',
  name: 'OFICIAL ALBAÑIL'
}, {
  id: '451',
  name: 'OFICIAL ARMADOR'
}, {
  id: '452',
  name: 'OFICIAL CARPINT'
}, {
  id: '457',
  name: 'OLERO          '
}, {
  id: '458',
  name: 'OPERADOR DE CIN'
}, {
  id: '461',
  name: 'OPERADOR DE MAQ'
}, {
  id: '464',
  name: 'OPERADOR DE TEL'
}, {
  id: '465',
  name: 'ORDENANZA      '
}, {
  id: '470',
  name: 'ORIENTADOR DE E'
}, {
  id: '473',
  name: 'ORIENTADOR VACA'
}, {
  id: '474',
  name: 'OXIGENISTA     '
}, {
  id: '476',
  name: 'PANADERO       '
}, {
  id: '477',
  name: 'PANTALONERO    '
}, {
  id: '478',
  name: 'PARAMEDICO     '
}, {
  id: '480',
  name: 'PARRILLERO     '
}, {
  id: '481',
  name: 'PARTERA        '
}, {
  id: '484',
  name: 'PEINADORA      '
}, {
  id: '485',
  name: 'PELETERO       '
}, {
  id: '488',
  name: 'PELUQUERO      '
}, {
  id: '489',
  name: 'PERFORADOR IBM '
}, {
  id: '496',
  name: 'PESCADOR       '
}, {
  id: '497',
  name: 'PIANISTA       '
}, {
  id: '501',
  name: 'PINTOR         '
}, {
  id: '502',
  name: 'PINTOR DE AUTOM'
}, {
  id: '506',
  name: 'PISERO         '
}, {
  id: '510',
  name: 'PLANCHADORAS   '
}, {
  id: '511',
  name: 'PLOMERO        '
}, {
  id: '512',
  name: 'POCERO         '
}, {
  id: '513',
  name: 'PODOLOGO O PEDI'
}, {
  id: '514',
  name: 'POETA          '
}, {
  id: '539',
  name: 'PORTERO        '
}, {
  id: '543',
  name: 'PRACTICO DE RIO'
}, {
  id: '544',
  name: 'PRACTICO OSTEOP'
}, {
  id: '545',
  name: 'PRACTICO RURAL '
}, {
  id: '593',
  name: 'PUBLICISTA     '
}, {
  id: '594',
  name: 'PULIDOR        '
}, {
  id: '596',
  name: 'RADIOTECNICO   '
}, {
  id: '597',
  name: 'RADIOTELEGRAFIS'
}, {
  id: '598',
  name: 'RECEPCIONISTA  '
}, {
  id: '599',
  name: 'RECICLADOR/A   '
}, {
  id: '602',
  name: 'REMACHADOR     '
}, {
  id: '604',
  name: 'REPARTIDOR     '
}, {
  id: '606',
  name: 'REPICERO       '
}, {
  id: '608',
  name: 'REPOSTERO      '
}, {
  id: '609',
  name: 'REVELADOR      '
}, {
  id: '614',
  name: 'SEMILLERO      '
}, {
  id: '615',
  name: 'SEMINARISTA    '
}, {
  id: '616',
  name: 'SERENO         '
}, {
  id: '619',
  name: 'SOLDADOR       '
}, {
  id: '620',
  name: 'SOMBRERERO     '
}, {
  id: '623',
  name: 'TALADOR        '
}, {
  id: '624',
  name: 'TAMBERO        '
}, {
  id: '625',
  name: 'TAPICERO       '
}, {
  id: '626',
  name: 'TAXISTA        '
}, {
  id: '627',
  name: 'TECHADOR       '
}, {
  id: '630',
  name: 'TEJEDOR        '
}, {
  id: '631',
  name: 'TELEFONISTA    '
}, {
  id: '632',
  name: 'TELEGRAFISTA   '
}, {
  id: '646',
  name: 'TEXTIL         '
}, {
  id: '655',
  name: 'TORNERO MECANIC'
}, {
  id: '656',
  name: 'TRACTORISTA    '
}, {
  id: '661',
  name: 'TROPERO        '
}, {
  id: '667',
  name: 'VIDRIERO       '
}, {
  id: '671',
  name: 'VULCANIZADOR   '
}, {
  id: '673',
  name: 'YESERO         '
}, {
  id: '730',
  name: 'MOTOSIERRISTA  '
}, {
  id: '731',
  name: 'MOZO           '
}, {
  id: '732',
  name: 'MUCAMA         '
}, {
  id: '9',
  name: 'AGENTE DE PUBLI'
}, {
  id: '16',
  name: 'AJEDRECISTA    '
}, {
  id: '31',
  name: 'APARADOR/ZAPATE'
}, {
  id: '37',
  name: 'ARTE CULINARIO '
}, {
  id: '41',
  name: 'ARTESANO       '
}, {
  id: '43',
  name: 'ASERRERO       '
}, {
  id: '44',
  name: 'ASISTENTE DE RE'
}, {
  id: '48',
  name: 'ASISTENTE EJECU'
}, {
  id: '49',
  name: 'ASISTENTE SOCIA'
}, {
  id: '50',
  name: 'ATLETA         '
}, {
  id: '58',
  name: 'AUXILIAR DE CON'
}, {
  id: '60',
  name: 'AUXILIAR DE ENF'
}, {
  id: '67',
  name: 'AYUDANTE LABORA'
}, {
  id: '71',
  name: 'AZUCARERO      '
}, {
  id: '78',
  name: 'BARMAN         '
}, {
  id: '79',
  name: 'BASQUEBOLISTA  '
}, {
  id: '86',
  name: 'BOXEADOR       '
}, {
  id: '87',
  name: 'BUZO           '
}, {
  id: '89',
  name: 'CAFETERO       '
}, {
  id: '93',
  name: 'CALCULISTA DE P'
}, {
  id: '100',
  name: 'CAMISERO       '
}, {
  id: '103',
  name: 'CANTINERO      '
}, {
  id: '105',
  name: 'CAÑERO         '
}, {
  id: '107',
  name: 'CAPITAN DE CABO'
}, {
  id: '108',
  name: 'CARBONERO      '
}, {
  id: '111',
  name: 'CARPINTERO     '
}, {
  id: '112',
  name: 'CARPINTERO ARMA'
}, {
  id: '114',
  name: 'CARROCERO      '
}, {
  id: '118',
  name: 'CAUCHERO       '
}, {
  id: '125',
  name: 'CHAPISTA       '
}, {
  id: '131',
  name: 'CIGARRERO      '
}, {
  id: '179',
  name: 'DETECTIVE      '
}, {
  id: '232',
  name: 'EMPLEADO/A DOME'
}, {
  id: '272',
  name: 'FLORISTA       '
}, {
  id: '273',
  name: 'FONOGRAFICO    '
}, {
  id: '456',
  name: 'OFICIAL DE JUST'
}, {
  id: '459',
  name: 'OPERADOR DE COM'
}, {
  id: '460',
  name: 'OPERADOR DE EQU'
}, {
  id: '463',
  name: 'OPERADOR DE TEL'
}, {
  id: '469',
  name: 'ORFEBRE        '
}, {
  id: '491',
  name: 'PERITO AGRONOMO'
}, {
  id: '492',
  name: 'PERITO CALIGRAF'
}, {
  id: '494',
  name: 'PERITO CONTADOR'
}, {
  id: '495',
  name: 'PERITO MERCANTI'
}, {
  id: '504',
  name: 'PINTOR PUBLICIT'
}, {
  id: '542',
  name: 'PRACTICO AGROPE'
}, {
  id: '549',
  name: 'PRODUCTOR AGROP'
}, {
  id: '572',
  name: 'PROFESIONAL IND'
}, {
  id: '588',
  name: 'PROMOTOR DE VEN'
}, {
  id: '601',
  name: 'RELOJERO       '
}, {
  id: '607',
  name: 'REPORTERO      '
}, {
  id: '610',
  name: 'REVENDEDOR/A   '
}, {
  id: '611',
  name: 'SECRETARIO     '
}, {
  id: '612',
  name: 'SECRETARIO COME'
}, {
  id: '613',
  name: 'SECRETARIO EJEC'
}, {
  id: '645',
  name: 'TENISTA        '
}, {
  id: '657',
  name: 'TRADUCTOR      '
}, {
  id: '659',
  name: 'TRANSPORTISTA  '
}, {
  id: '663',
  name: 'UJIER          '
}, {
  id: '664',
  name: 'VENDEDOR       '
}, {
  id: '666',
  name: 'VENDEDOR AMBULA'
}, {
  id: '3',
  name: 'ACTUARIO       '
}, {
  id: '6',
  name: 'ADMINISTRADOR  '
}, {
  id: '8',
  name: 'AGENTE DE COMPR'
}, {
  id: '12',
  name: 'AGENTE DE SEGUR'
}, {
  id: '13',
  name: 'AGENTE DE VIAJE'
}, {
  id: '14',
  name: 'AGENTE MARITIMO'
}, {
  id: '33',
  name: 'ARBITRO        '
}, {
  id: '34',
  name: 'ARMERO         '
}, {
  id: '35',
  name: 'ARRENDATARIO   '
}, {
  id: '36',
  name: 'ARROCERO       '
}, {
  id: '51',
  name: 'AUDITOR        '
}, {
  id: '53',
  name: 'AUTOMOVILISTA  '
}, {
  id: '55',
  name: 'AUTOR LITERARIO'
}, {
  id: '62',
  name: 'AUXILIAR DESPAC'
}, {
  id: '64',
  name: 'AYUDANTE       '
}, {
  id: '77',
  name: 'BANCARIO       '
}, {
  id: '90',
  name: 'CAJERO         '
}, {
  id: '97',
  name: 'CAMBISTA       '
}, {
  id: '119',
  name: 'CAZADOR        '
}, {
  id: '138',
  name: 'COBRADOR       '
}, {
  id: '141',
  name: 'COMERCIANTE    '
}, {
  id: '142',
  name: 'COMERCIANTE INT'
}, {
  id: '144',
  name: 'COMERCIANTE MAY'
}, {
  id: '145',
  name: 'COMERCIANTE MIN'
}, {
  id: '146',
  name: 'COMISIONISTA   '
}, {
  id: '150',
  name: 'CONSTRUCTOR    '
}, {
  id: '151',
  name: 'CONSTRUCTOR DE '
}, {
  id: '152',
  name: 'CONSTRUCTOR NAV'
}, {
  id: '153',
  name: 'CONSUL         '
}, {
  id: '154',
  name: 'CONSULTOR DE RE'
}, {
  id: '157',
  name: 'COPILOTO       '
}, {
  id: '158',
  name: 'CORREDOR       '
}, {
  id: '161',
  name: 'CORRESPONSAL   '
}, {
  id: '176',
  name: 'DESPACHANTE DE '
}, {
  id: '177',
  name: 'DESPACHANTE DE '
}, {
  id: '203',
  name: 'DIPLOMATICO    '
}, {
  id: '204',
  name: 'DIRECTOR       '
}, {
  id: '206',
  name: 'DIRECTOR ARTIST'
}, {
  id: '207',
  name: 'DIRECTOR DE EMP'
}, {
  id: '234',
  name: 'EMPLEADO/A MILI'
}, {
  id: '235',
  name: 'EMPLEADO/A POLI'
}, {
  id: '236',
  name: 'ENCARGADO ADMIN'
}, {
  id: '250',
  name: 'ENTRENADOR     '
}, {
  id: '255',
  name: 'FABRICANTE DE M'
}, {
  id: '256',
  name: 'FAENADOR       '
}, {
  id: '258',
  name: 'FARMACEUTICO   '
}, {
  id: '266',
  name: 'FERROVIARIO    '
}, {
  id: '278',
  name: 'FUTBOLISTA     '
}, {
  id: '280',
  name: 'GANADERO       '
}, {
  id: '282',
  name: 'GESTOR         '
}, {
  id: '286',
  name: 'GOLFISTA       '
}, {
  id: '293',
  name: 'GRANJERO       '
}, {
  id: '310',
  name: 'HOTELERO       '
}, {
  id: '315',
  name: 'INDUSTRIAL     '
}, {
  id: '349',
  name: 'JOYERO         '
}, {
  id: '351',
  name: 'JUEZ           '
}, {
  id: '357',
  name: 'LEÑADOR        '
}, {
  id: '359',
  name: 'LICORERO       '
}, {
  id: '391',
  name: 'MADERERO       '
}, {
  id: '408',
  name: 'MECANICO AVIADO'
}, {
  id: '429',
  name: 'MINERO         '
}, {
  id: '446',
  name: 'NAVEGANTE      '
}, {
  id: '475',
  name: 'PAGADOR        '
}, {
  id: '498',
  name: 'PILOTO COMERCIA'
}, {
  id: '499',
  name: 'PILOTO DE CABOT'
}, {
  id: '500',
  name: 'PILOTO NAVAL   '
}, {
  id: '503',
  name: 'PINTOR DE OBRA '
}, {
  id: '547',
  name: 'PROCURADOR     '
}, {
  id: '603',
  name: 'REMATADOR O MAR'
}, {
  id: '621',
  name: 'TABACALERO     '
}, {
  id: '622',
  name: 'TALABARTERO    '
}, {
  id: '658',
  name: 'TRANSFUSIONISTA'
}, {
  id: '668',
  name: 'VINICULTOR     '
}, {
  id: '669',
  name: 'VISITADOR MEDIC'
}, {
  id: '672',
  name: 'YERBATERO      '
}, {
  id: '674',
  name: 'ZAPATERO       '
}, {
  id: '733',
  name: 'EMPLEADO PUBLIC'
}];
export default profesion;
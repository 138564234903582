var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "pz-form-flujo",
    {
      attrs: {
        "available-captcha": _vm.extraData.withCaptcha,
        forward: _vm.handleSave,
        backwards: _vm.showBack ? _vm.handleBack : null
      },
      model: {
        value: _vm.formulario1["captcha"],
        callback: function($$v) {
          _vm.$set(_vm.formulario1, "captcha", $$v)
        },
        expression: "formulario1['captcha']"
      }
    },
    [
      _c("span", { attrs: { slot: "form_title" }, slot: "form_title" }, [
        _vm._v(" " + _vm._s(_vm.$t("forms_flujo.form1_persona.UY.title")) + " ")
      ]),
      _c(
        "el-form",
        {
          ref: _vm.reference,
          attrs: {
            slot: "formulario",
            "label-position": "top",
            model: _vm.formulario1,
            "status-icon": "",
            rules: _vm.rules_formulario1
          },
          slot: "formulario"
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                {
                  attrs: {
                    xl: { span: 12 },
                    lg: { span: 12 },
                    md: { span: 12 },
                    sm: { span: 24 },
                    xs: { span: 24 }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "email",
                        label: _vm.$t("forms_flujo.form2_persona.UY.email"),
                        error: _vm.errors.get("email")
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "text",
                          "auto-complete": "no",
                          placeholder: _vm.$t(
                            "forms_flujo.form2_persona.UY.email"
                          ),
                          disabled: true
                        },
                        model: {
                          value: _vm.formulario1.email,
                          callback: function($$v) {
                            _vm.$set(_vm.formulario1, "email", $$v)
                          },
                          expression: "formulario1.email"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                {
                  attrs: {
                    xl: { span: 12 },
                    lg: { span: 12 },
                    md: { span: 12 },
                    sm: { span: 24 },
                    xs: { span: 24 }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "telefono",
                        label: _vm.$t("forms_flujo.form1_persona.UY.telefono"),
                        error: _vm.errors.get("telefono")
                      }
                    },
                    [
                      _c("el-input", {
                        ref: "telefono",
                        attrs: {
                          type: "text",
                          placeholder: _vm.$t(
                            "forms_flujo.form1_persona.UY.telefono"
                          ),
                          disabled: true
                        },
                        model: {
                          value: _vm.formulario1.telefono,
                          callback: function($$v) {
                            _vm.$set(_vm.formulario1, "telefono", $$v)
                          },
                          expression: "formulario1.telefono"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import "core-js/modules/es.function.name";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'PzOfertaUpSolicitudUy',
  inject: ['myForm'],
  mounted: function mounted() {
    // this.$store.dispatch('loader/down', {trigger: this.$options.name})
    this.$store.dispatch('loader/up', {
      trigger: this.$options.name
    });
  }
};
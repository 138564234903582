var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-row",
    { staticClass: "text-center" },
    [
      _c("el-col", { attrs: { span: 20, offset: 2 } }, [
        _c("label", { staticClass: "pz-offer-color-primary pz-font-size-lg" }, [
          _c("b", [
            _vm._v(
              " " + _vm._s(_vm.$t("forms_flujo.PzSimulator.recived")) + " "
            )
          ]),
          _c(
            "label",
            { staticClass: "pz-offer-color-secondary pz-font-size-xl" },
            [_vm._v(" " + _vm._s(_vm.getMontoCuota) + " ")]
          ),
          _c("b", [
            _vm._v(" " + _vm._s(_vm.$t("forms_flujo.PzSimulator.tasas")) + " ")
          ]),
          _vm.myForm.form.tasa
            ? _c(
                "label",
                { staticClass: "pz-offer-color-secondary pz-font-size-xl" },
                [_vm._v(" " + _vm._s(_vm.myForm.form.tasa) + " % ")]
              )
            : _c(
                "label",
                { staticClass: "pz-offer-color-secondary pz-font-size-xl" },
                [_vm._v(" ... % ")]
              )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
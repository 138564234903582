import "core-js/modules/es.function.name";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.string.iterator";
import "core-js/modules/web.dom-collections.iterator";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { required, type } from "@/commons/utils/Rules";
import { getDepartamentos, getEstudios, getFormasDeEnterarse, getRelacionesLaborales, getTipoViviendas } from "@/routes/api/resources";
import baseform2 from '../baseForm2';
import PzFormFlujo from "@/components/Flujo/Templates/PzFormFlujoTemplate";
import PzDirection from "@/commons/forms/Fields/PzDirection";
export default {
  name: 'PzPersonFormExtUy',
  components: {
    PzFormFlujo: PzFormFlujo,
    PzDirection: PzDirection
  },
  mixins: [baseform2],
  data: function data() {
    var _this = this;

    var check_Fecha = function check_Fecha(rule, value, callback) {
      if (!_this.jubilado && !value) {
        return callback(new Error(_this.$t('forms_flujo.form2_persona.UY.mandatory_field')));
      } else {
        return callback();
      }
    };

    return {
      properties: [],
      provincias: [],
      employment_relations: [],
      studies: [],
      formas_de_enterarse: [],
      formulario2: {
        tipo_vivienda: '',
        direccion: {},
        provincia: '',
        telefono_fijo: '',
        email: '',
        telefono_empresa: '',
        estudio: '',
        relacion_laboral: '',
        persona_contacto: '',
        telefono_persona_contacto: '',
        comoseentero: '',
        url_trackeo: '',
        ref_empresa: process.env.COMPANY_KEY,
        tipo_documento: process.env.IDE_DEFAULT
      },
      disable: true,
      rules_formulario2: {
        tipo_vivienda: [required('')],
        direccion: [required('')],
        provincia: [required('')],
        telefono_fijo: [required('')],
        email: [type({
          field: '',
          type: 'email'
        })],
        telefono_empresa: [{
          validator: check_Fecha,
          trigger: 'blur'
        }],
        estudio: [required('')],
        relacion_laboral: [{
          validator: check_Fecha,
          trigger: 'blur'
        }],
        persona_contacto: [required('')],
        telefono_persona_contacto: [required('')],
        comoseentero: [required('')]
      },
      pickerOptions1: {
        disabledDate: function disabledDate(time) {
          return time.getTime() > Date.now();
        }
      }
    };
  },
  created: function created() {},
  beforeMount: function beforeMount() {
    var _this2 = this;

    var viv = getTipoViviendas().then(function (res) {
      _this2.properties = res.data;
    }).catch(function () {
      _this2.properties = [];
    });
    var dep = getDepartamentos().then(function (res) {
      _this2.provincias = res.data;
    }).catch(function () {
      _this2.provincias = [];
    });
    getEstudios().then(function (res) {
      _this2.studies = res.data;
    }).catch(function () {
      _this2.studies = [];
    });
    var re = getRelacionesLaborales().then(function (res) {
      _this2.employment_relations = res.data;
    }).catch(function () {
      _this2.employment_relations = [];
    });
    var form = getFormasDeEnterarse().then(function (res) {
      _this2.formas_de_enterarse = res.data;
    }).catch(function () {
      _this2.formas_de_enterarse = [];
    });
    Promise.all([viv, dep, re, form]).then(function () {}).finally(function () {
      _this2.$store.dispatch('loader/down', {
        trigger: _this2.$options.name
      });
    });
  },
  methods: {}
};
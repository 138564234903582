//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import baseSimulator from "./baseSimulator";
import PzSimulators from "@/components/Flujo/Templates/PzSimulatorsTemplate";
import PzSimulatorsTemplate from "@/components/Flujo/Templates/PzSimulatorsTemplate";
import currency from "@/commons/filters/other/currency";
export default {
  name: 'PzSliderSimulator',
  components: {
    PzSimulatorsTemplate: PzSimulatorsTemplate,
    PzSimulators: PzSimulators
  },
  mixins: [baseSimulator],
  methods: {
    formatTooltip: function formatTooltip(val) {
      if (val != null) return this.producto.moneda_solicitud.symbol + currency(val, '', 0);
    }
  }
};
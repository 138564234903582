//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import PzOfertaSolicitudBase from "@/components/Flujo/Simuladores/Ofertas/baseOferta";
export default {
  name: "PzOfertaSolicitudBase",
  mixins: [PzOfertaSolicitudBase]
};
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import baseFormDocumentacion from "./baseFormDocumentacion";
import PzFormFlujo from "@/components/Flujo/Templates/PzFormFlujoTemplate";
import { required } from "@/commons/utils/Rules";
export default {
  name: 'PzPersonDocumentacionUy',
  components: {
    PzFormFlujo: PzFormFlujo
  },
  mixins: [baseFormDocumentacion],
  data: function data() {
    return {
      nameboton: this.$t('forms_flujo.form_documentacion_persona.name_button')
    };
  },
  created: function created() {
    this.getDocs('person');
  },
  methods: {
    required: required
  }
};
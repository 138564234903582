import _objectSpread from "C:/xampp/htdocs/simulador_new/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import PzLanguage from "@/lang/PzLanguage";
import { mapGetters } from "vuex";
import PzBanner from "@/commons/titles/PzTitleBanner";
export default {
  name: 'App',
  components: {
    PzBanner: PzBanner
  },
  mixins: [],
  data: function data() {
    return {
      windowHeight: 0,
      ambientetest: process.env.VUE_APP_AMBIENTE_TEST
    };
  },
  computed: _objectSpread(_objectSpread({}, mapGetters(['loader', 'location'])), {}, {
    key: function key() {
      return this.$route.path;
    }
  }),
  inject: ['app'],
  provide: function provide() {
    return {
      app: this.app
    };
  },
  created: function created() {},
  methods: {}
};
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "pz-simulators-template",
    [
      _c(
        "el-form",
        {
          attrs: {
            slot: "simulador",
            "status-icon": "",
            model: _vm.form,
            rules: _vm.validation,
            "label-position": "top"
          },
          slot: "simulador"
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                {
                  attrs: {
                    xl: { span: 12 },
                    lg: { span: 12 },
                    md: { span: 12 },
                    sm: { span: 24 },
                    xs: { span: 24 }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: { prop: "monto", error: _vm.errors.get("monto") }
                    },
                    [
                      _c("span", [_vm._v(_vm._s(_vm.monto_label) + " ")]),
                      _c("pz-input-coint", {
                        attrs: {
                          type: "text",
                          step: _vm.producto.stepMonto,
                          coint: _vm.producto.moneda_solicitud.symbol,
                          min: _vm.producto.min_monto,
                          max: _vm.producto.max_monto
                        },
                        on: {
                          change: function($event) {
                            return _vm.$nextTick(_vm.emitChange)
                          },
                          input: function($event) {
                            return _vm.$nextTick(_vm.handleInputNumber)
                          }
                        },
                        model: {
                          value: _vm.form.monto,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "monto", _vm._n($$v))
                          },
                          expression: "form.monto"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                {
                  attrs: {
                    xl: { span: 12 },
                    lg: { span: 12 },
                    md: { span: 12 },
                    sm: { span: 24 },
                    xs: { span: 24 }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: { prop: "plazo", error: _vm.errors.get("plazo") }
                    },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t("forms_flujo.PzSimulator.how_many_fee")
                          ) + " "
                        )
                      ]),
                      _c("el-input-number", {
                        attrs: {
                          step: _vm.producto.stepCuota,
                          min: _vm.producto.min_cuota,
                          max: _vm.producto.max_cuota
                        },
                        on: {
                          change: function($event) {
                            return _vm.$nextTick(_vm.emitChange)
                          },
                          input: _vm.handleInput
                        },
                        model: {
                          value: _vm.form.plazo,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "plazo", $$v)
                          },
                          expression: "form.plazo"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _vm.producto.needsSelds
                ? _c(
                    "el-col",
                    {
                      attrs: {
                        xl: { span: 12 },
                        lg: { span: 12 },
                        md: { span: 12 },
                        sm: { span: 24 },
                        xs: { span: 24 }
                      }
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "trabajadores",
                            error: _vm.errors.get("trabajadores")
                          }
                        },
                        [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.$t("forms_flujo.PzSimulator.annual_sells")
                              )
                            )
                          ]),
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "Ventas anuales" },
                              on: {
                                change: function($event) {
                                  return _vm.$nextTick(_vm.emitChange)
                                },
                                input: _vm.handleInput
                              },
                              model: {
                                value: _vm.form.trabajadores,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "trabajadores", $$v)
                                },
                                expression: "form.trabajadores"
                              }
                            },
                            _vm._l(_vm.workers, function(worker) {
                              return _c("el-option", {
                                key: worker.id,
                                attrs: {
                                  label: worker.workwers_range,
                                  value: worker.id
                                }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.producto.needsFirstDate
                ? _c(
                    "el-col",
                    {
                      attrs: {
                        xl: { span: 6 },
                        lg: { span: 6 },
                        md: { span: 6 },
                        sm: { span: 12 },
                        xs: { span: 24 }
                      }
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "bordered",
                          attrs: {
                            prop: "fecha_vencimiento",
                            error: _vm.errors.get("fecha_vencimiento")
                          }
                        },
                        [
                          _c("span", [
                            _vm._v(" " + _vm._s(_vm.fechapago_label) + " ")
                          ]),
                          _c("el-date-picker", {
                            attrs: {
                              type: "date",
                              span: 2,
                              placeholder: "Seleccione Fecha",
                              "picker-options": _vm.pickerOptions,
                              format: "dd-MM-yyyy",
                              "value-format": "dd-MM-yyyy"
                            },
                            on: {
                              change: function($event) {
                                return _vm.$nextTick(_vm.emitChange)
                              },
                              input: function($event) {
                                return _vm.$nextTick(_vm.handleInput)
                              }
                            },
                            model: {
                              value: _vm.form.fecha_vencimiento,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "fecha_vencimiento", $$v)
                              },
                              expression: "form.fecha_vencimiento"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.producto.needsFirstDate
                ? _c(
                    "el-col",
                    {
                      attrs: {
                        xl: { span: 6 },
                        lg: { span: 6 },
                        md: { span: 6 },
                        sm: { span: 12 },
                        xs: { span: 24 }
                      }
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "emision",
                            error: _vm.errors.get("emision")
                          }
                        },
                        [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.$t("forms_flujo.PzSimulator.emission_date")
                              )
                            )
                          ]),
                          _c("el-date-picker", {
                            attrs: {
                              type: "date",
                              span: 2,
                              placeholder: "Seleccione Fecha",
                              format: "dd-MM-yyyy",
                              "picker-options": _vm.pickerOptionsEmision,
                              "value-format": "dd-MM-yyyy"
                            },
                            on: {
                              change: function($event) {
                                return _vm.$nextTick(_vm.emitChange)
                              },
                              input: function($event) {
                                return _vm.$nextTick(_vm.handleInput)
                              }
                            },
                            model: {
                              value: _vm.form.emision,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "emision", $$v)
                              },
                              expression: "form.emision"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c("pz-oferta-" + _vm.elOfertaView, { tag: "comment" })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
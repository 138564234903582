var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "pz-form-flujo",
    {
      attrs: {
        "available-captcha": _vm.extraData.withCaptcha,
        forward: _vm.handleSave,
        backwards: _vm.showBack ? _vm.handleBack : null,
        nameforward: _vm.nameboton
      },
      model: {
        value: _vm.formulariodocumentacion["captcha"],
        callback: function($$v) {
          _vm.$set(_vm.formulariodocumentacion, "captcha", $$v)
        },
        expression: "formulariodocumentacion['captcha']"
      }
    },
    [
      _c("span", { attrs: { slot: "form_title" }, slot: "form_title" }, [
        _vm._v(
          " " +
            _vm._s(_vm.$t("forms_flujo.form_documentacion_persona.title")) +
            " "
        )
      ]),
      _c("span", { attrs: { slot: "form_subtitle" }, slot: "form_subtitle" }, [
        _vm._v(
          " " +
            _vm._s(_vm.$t("forms_flujo.form_documentacion_persona.sub_title")) +
            " "
        )
      ]),
      _c(
        "el-form",
        {
          ref: _vm.reference,
          attrs: {
            slot: "formulario",
            "label-position": "top",
            model: _vm.formulariodocumentacion,
            "status-icon": "",
            rules: _vm.rules_formulariodocumentacion
          },
          slot: "formulario"
        },
        [
          _c(
            "el-row",
            { staticClass: "center-block text-center" },
            _vm._l(_vm.formulariodocumentacion, function(value, name) {
              return _c(
                "el-col",
                {
                  key: name,
                  attrs: {
                    xl: { span: 24 },
                    lg: { span: 24 },
                    md: { span: 24 },
                    sm: { span: 24 },
                    xs: { span: 24 }
                  }
                },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 22, offset: 1 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: name,
                            error: _vm.errors.get(name),
                            label: _vm.settings[name].label_form,
                            rules: _vm.settings[name].required
                              ? [_vm.required("")]
                              : []
                          }
                        },
                        [
                          _c("pz-upload", {
                            attrs: {
                              tip: _vm.settings[name].hint,
                              template: _vm.settings[name].plantilla
                            },
                            model: {
                              value: _vm.formulariodocumentacion[name],
                              callback: function($$v) {
                                _vm.$set(_vm.formulariodocumentacion, name, $$v)
                              },
                              expression: "formulariodocumentacion[name]"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            }),
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
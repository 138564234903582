var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.url !== true
    ? _c("iframe", {
        staticClass: "pz-large-margin",
        attrs: {
          id: "unnax",
          src: _vm.url,
          name: "unnax",
          frameborder: "0",
          width: _vm.width,
          height: _vm.height
        },
        on: { load: _vm.iframeLoaded }
      })
    : _c("div", { staticClass: "pic-404" }, [
        _c("img", {
          staticClass: "pic-404__parent",
          attrs: { src: require("@/assets/images/exitoso.png"), alt: "success" }
        })
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
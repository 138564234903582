//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { required, type } from "@/commons/utils/Rules";
import { getEstadoCivil } from "@/routes/api/resources";
import 'element-ui/lib/theme-chalk/display.css';
import PzBaseForm1 from "@/components/Flujo/Formularios/Formularios1/baseForm1";
import PzFormFlujo from "@/components/Flujo/Templates/PzFormFlujoTemplate";
import PzCheckForm from "@/components/Flujo/Formularios/Formularios1/CheckFields/ChecksForm";
import PzTermsAndConditions from "@/commons/forms/Fields/PzTermsAndConditions";
export default {
  name: 'PzPersonForm1Mx',
  components: {
    PzTermsAndConditions: PzTermsAndConditions,
    PzCheckForm: PzCheckForm,
    PzFormFlujo: PzFormFlujo
  },
  mixins: [PzBaseForm1],
  data: function data() {
    return {
      marital_status: [],
      formulario1: {
        telefono: '',
        estado_civil_id: '',
        fecha_ingreso: '',
        cuota_maxima: '',
        ingreso_mensual: '',
        destino_prestamo: '',
        clearing: '',
        identity: {
          document: 1,
          number: ''
        },
        comprobante_domicilio: null,
        comprobante_ingresos: null
      },
      rules_formulario1: {
        'identity.number': [required('')],
        telefono: [required('')],
        estado_civil_id: [required('')],
        fecha_ingreso: [required('')],
        cuota_maxima: [required(''), type({
          field: '',
          type: 'number'
        })],
        ingreso_mensual: [required(''), type({
          field: '',
          type: 'number'
        })],
        destino_prestamo: [required('')]
      },
      pickerOptions1: {
        disabledDate: function disabledDate(time) {
          return Date.now() < time.getTime();
        }
      }
    };
  },
  created: function created() {
    var _this = this;

    getEstadoCivil().then(function (res) {
      _this.marital_status = res.data;
    }).catch(function () {
      _this.marital_status = [];
    });
  },
  mounted: function mounted() {},
  methods: {}
};